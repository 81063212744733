import { PaginatedRequestParams } from "api";

export enum ItemsSizeTypeFilterFormField {
    Size = 'Desc',
    Status = 'statusId',
}

export type ItemsSizeTypeFilterFormValues = {
    [ItemsSizeTypeFilterFormField.Size]: string | undefined;
    [ItemsSizeTypeFilterFormField.Status]: string | undefined;

};

export type ItemsSizeTypeFilterFormPaginated = ItemsSizeTypeFilterFormValues &
    PaginatedRequestParams;