
export enum EditCustomMasterFormField {
  Id = "Id",
  Flag = "Flag",
  Title = "Title",
  TitleAr = "TitleAr"
}

export type EditCustomMasterFormValues = {
  [EditCustomMasterFormField.Id]: number;
  [EditCustomMasterFormField.Flag]: string;
  [EditCustomMasterFormField.Title]: string;
  [EditCustomMasterFormField.TitleAr]: string;

};

