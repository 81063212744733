import { Grid } from 'antd';
import { ContentFilters } from 'components';
import { useState } from 'react';

import { ItemsSizeFilterForm } from '../filter-form/items-size-filter/items-size-list-filter';
import { ItemsSizeFilterFormValues } from '../filter-form/items-size-filter/types';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsSizeFilterFormValues;
    handleFilter: (values: ItemsSizeFilterFormValues) => void;
};

export const ItemsSizeListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'size-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>

            {/* <div className='head_btn'>
                <Button
                    type='primary'
                // onClick={handleExcelDownload}
                >
                    Add Size Type
                </Button>
            </div> */}


            <ContentFilters formId={formId}>
                <ItemsSizeFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
