type RoutesType = typeof ROUTES;
type RouteKeys = keyof RoutesType;
export type RoutePaths = RoutesType[RouteKeys];

export const ROUTES = {
  DASHBOARD: '/HomeRoot/Dashboard',

  USER_MANAGEMENT: '/AdminRoot/UsersList',
  ROLE_PERMISSION_MANAGEMENT: '/Users/RoleManagement',
  USER_DELIVERY_DAYS: '/AdminRoot/DelieveryDays',
  USER_PAYMENT_METHOD: '/AdminRoot/PaymentMethod',
  USER_CMS: '/AdminRoot/CMS',
  USER_CMS_TYPE: '/AdminRoot/CMSType',
  USER_CUSTOM_SIZE_MASTER: '/AdminRoot/CustomSizeList',

  ORDER_LIST: '/OrderRoot/OrderList',
  ORDER_RETURN: '/OrderRoot/OrderReturnList',
  ORDER_DETAILS: '/Order/OrderList/:id/detail',
  ORDER_RETURN_DETAILS: '/Order/OrderReturnDetail/:id/detail',


  ITEMS_LIST: '/ItemsRoot/ItemsList',
  ITEMS_LIST_DETAILS: '/Order/ItemMaster/:id/detail',

  ITEMS_REVIEWS: '/ItemsRoot/ItemReview',
  ITEMS_STOCK_LIST: '/ItemsRoot/ItemsStockList',
  ITEMS_CATEGORY: '/ItemsRoot/CategoryGroups',
  ITEMS_SUB_CATEGORY: '/ItemsRoot/Category',
  ITEMS_SEASONS: '/ItemsRoot/SeasonList',
  ITEMS_SIZE_TYPE: '/ItemsRoot/SizeType',

  ITEMS_PURCHASED: '/ItemsRoot/PurchasedItem',
  ITEMS_NOTIFY: '/ItemsRoot/Notify',
  ITEMS_SIZE: '/ItemsRoot/Size',
  ITEMS_UPLOAD_ITEMS: '/ItemsRoot/UploadItems',


  CUSTOMER_LIST: '/CustomerRoot/CustomersList',
  CUSTOMER_DETAILS_ID: '/CustomerRoot/CustomersDetails/:id/detail',

  PROMOTION_LIST: '/MarketingRoot/PromotionList',
  PROMOTION_TYPE_LIST: '/MarketingRoot/PromotionType',
  NOTIFICATIONS_LIST: '/MarketingRoot/Notifications',
  BANNER_LIST: '/MarketingRoot/Banners',
  CONTACT_LIST: '/MarketingRoot/ContactList',
  CONTACT_TYPE_LIST: '/MarketingRoot/ContactType',
  SUBSCRIBER_LIST: '/MarketingRoot/Subscribers',
  NEWS_LIST: '/MarketingRoot/News',
  FAQ_LIST: '/MarketingRoot/FAQ',
  BENEFITS_LIST: '/MarketingRoot/Benefits',
  SOCIAL_MEDIA_LIST: '/MarketingRoot/SocialLink',
  OFFER_TYPE_LIST: '/MarketingRoot/OfferType',

  //-------------- Aryan Work-----------------------------

  BRANDS: '/DesignerRoot/VendorList',
  BRANDS_DETAILS: '/DesignerRoot/VendorList/:id/detail',
  SYSTEM_SETTING: "/SettingRoot/SystemSetting",
  SYSTEM_UITILES: '/UtilityRoot/SyncWebsitedata',
  DELIVERY_CHARGE: "/AdminRoot/DeliveryCharge",



  HOME: '/',
  BOOKINGS: '/bookings',
  BOOKINGS_CREATE: `/bookings/create`,
  BOOKINGS_EDIT: `/bookings/:id/edit`,
  FIXED_BOOKINGS: '/fixed-bookings',
  FIXED_BOOKING_DETAILS: '/fixed-bookings/:id/bookings',
  FIXED_BOOKING_DETAILS_COMMENTS: '/fixed-bookings/:id/comments',
  FIXED_BOOKING_DETAILS_INVOICE: '/fixed-bookings/:id/invoice',
  FIXED_BOOKINGS_CREATE: '/fixed-bookings/create',
  FIXED_BOOKINGS_CREATE_INVOICE: '/fixed-bookings/create-invoice',
  FIXED_BOOKINGS_EDIT: '/fixed-bookings/:id/bookings/:booking_id/edit',
  DAY_VIEW: '/day-view',
  WEEK_VIEW: '/week-view',
  USERS: '/users',
  USERS_APP: '/users/app',
  USERS_APP_EDIT: `/users/app/:id/edit`,
  USERS_ADMIN: '/users/admin',
  USERS_ROLE: '/users/role',
  USERS_AGENT: '/users/agent',
  USERS_AGENT_EDIT: `/users/agent/:id/edit`,
  USERS_AGENT_DETAIL: `/users/agent/:id/detail`,
  USERS_ADMIN_DETAIL: `/users/admin/:id/detail`,





  USERS_ADMIN_EDIT: `/users/admin/:id/edit`,
  COMPANIES_ALL: '/companies-all', // for unique menu key purposes - not a real route
  COMPANIES: '/companies',
  COMPANIES_CREATE: '/companies/create',
  COMPANIES_EDIT: '/companies/:id/edit',
  FACILITIES: '/facilities',
  FACILITIES_CREATE: '/facilities/create',
  FACILITIES_EDIT: '/facilities/:id/edit',
  FIELDS: '/fields',
  FIELDS_CREATE: '/fields/create',
  FIELDS_EDIT: '/fields/:id/edit',







  EVENTS_ALL: '/events-all',
  EVENTS: '/events',
  EVENTS_CREATE: '/events/create',
  EVENTS_EDIT: '/events/:id/edit',
  EVENTS_DETAILS: '/events/:id/details',
  EVENTS_DETAILS_REGISTRATION_FORM: '/events/:id/details/registration-form',
  EVENTS_REGISTRATION_FORM: '/events/registration/form',
  EVENTS_REQUESTED_EVENT: '/events/requested-event',

  TRANSACTION: '/transactions',
  TRANSACTION_INVOICE_LIST: '/transactions/invoice-list',
  TRANSACTION_WALLET_LIST: '/transactions/wallet-list',
  TRANSACTION_INVOICE_LIST_EDIT: '/transactions/invoice-list/:id/details',
  TRANSACTION__PARTICIPATE_LIST: '/transactions/participate-List',
  TRANSACTION__BULK_UPLOAD_LIST: '/transactions/bulk-upload-List',
  TRANSACTION_PARTICIPATE_DETAIL: "/transactions/detail/:id/edit/",
  TRANSACTION__ITEM_SALES: '/transactions/item-sales',
  TRANSACTION__ITEM_SALES_DETAILS: "/transactions/detail/:id",
  TRANSACTION__BULK_UPLOAD_LIST_DETAILS: "/transactions/bulk-upload-List/:id",
  TRANSACTION__WAITING_LIST: '/transactions/waiting-list',
  TRANSACTION__EXPO_BOOTH_LIST: '/transactions/expo-booth-list',
  TRANSACTION_WAITING_LIST_DETAILS: '/transactions/waiting-list/:id/details',
  TRANSACTION__REPORTS: '/transactions/reports',


  CMS: '/cms',
  CMS_DISCOVER_BANNER: '/cms/discover-banner',
  CMS_COUPON_MANANGEMENT: '/cms/Coupon-Management',
  CMS_SPORTS_CATEGORY: '/cms/sports-category',
  CMS_MANAGE_PARTNERS: '/cms/manage-Partners',
  CMS_GLOBAL_DISCLAIMER: '/cms/global-disclaimer',
  CMS_CURRENCY: '/cms/currency',
  CMS_CURRENCY_CONVERSION: '/cms/currency-conversion',
  CMS_MANAGE_COUNTRIES: '/cms/manage-countries',
  CMS_MANAGE_AREA: '/cms/manage-area',
  CMS_MANAGE_T_SHIRT_SIZE: '/cms/manage/t-shirt/size',
  CMS_EVENT_MASTER: '/cms/event-master',
  CMS_RACE_KIT: '/cms/race-kit',



  SHOP_ITEM: "/shop-item",
  SHOP_ITEM_COLOR_MANAGEMENT: '/shop-item/color',
  SHOP_ITEM_SIZE: '/shop-item/size',
  SHOP_ITEM_FAQ: '/shop-item/faq',
  SHOP_ITEM_CATEGORY: '/shop-item/category',
  SHOP_ITEM_ITEM_LIST_EDIT: "/shop-item/item-list/:id/edit",
  SHOP_ITEM_ITEM_LIST: "/shop-item/item-list",
  SHOP_ITEM_DELIVERY_CHARGES: "/shop-item/delivery-charge",
  COUPON_ITEM: "/shop-item/coupon-management",
  // USER_MANAGEMENT: "/user-management",
  USER_MANAGEMENT_ADMIN: '/user-management/admins',
  USER_MANAGEMENT_AGENTS: '/user-management/agents',
  USER_MANAGEMENT_ROLES: '/user-management/roles',
  USER_MANAGEMENT_LOGS: '/user/logs',


  // LOGS: "/logs",
  // LOGS_ACTIVITY_LOG: '/logs/activity-log',

  // CUSTOMER_LIST: '/customer-list',
  NOTIFICATIONS: '/notifications',

  VOLUNTEER_LIST: '/users/volunteer',

  VENDING_MACHINE: "/vending-machine",
  VENDING_MACHINE_REPORT: '/vending-machine/reportg',
  VENDING_MACHINE_EVENT: '/vending-machine/event',

  NOTIFICATION: "/notification",
  NOTIFICATION_LIST: "/notification/list",
  NOTIFICATION_TYPE: "/notification/type",

  SPORTS: '/sports',
  AMENITIES: '/amenities',
  WALLET: '/wallet',
  WALLET_TRANSACTIONS: '/wallet/:id/transactions',
  WALLET_TRANSACTION_DETAILS: '/wallet/:id/transactions/:transaction_id',
  LOGIN: '/login',
  ACCOUNT_SETTINGS: '/account-settings',
  COMPANY_INFO: '/company-info',
  CONTACT: '/company-info/contact', // for unique menu key purposes - not a real route
  CONTACT_US: '/company-info/contact-us',
  PRIVACY_POLICY: '/company-info/privacy-policy',
  REPORTS: '/reports',
  NOT_FOUND: '*',
  // REGISTRATION_FORM: '/event-registraion-form',
} as const;
