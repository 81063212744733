import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { AllItemsReviewsFilterFormPaginated, AllItemsReviewsFilterFormValues } from '../components/filter-form/all-items-reviews-list-filter/types';
import { ItemsReviewTable } from '../components/items-review-table/items-review-table';
import { AllItemsReviewListTopBar } from '../components/top-bar/item-review-topbar';
import { useGetItemsReviewsLists } from '../use-cases/get-item-reviews';

const getInitialFilterValues: AllItemsReviewsFilterFormValues = {
    CustomerName: undefined,
    rating: undefined,
    reviewStatus_Fk_Id: undefined,
    itemName: undefined,
};


export const ItemsReviewListView = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<AllItemsReviewsFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, isFetching, refetch } = useGetItemsReviewsLists((params), {
        retry: false,
    });


    const handleFiltersChange = (values: AllItemsReviewsFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch()
    };

    return (
        <>
            <AllItemsReviewListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
                tableParams={params}
            />

            <ItemsReviewTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

