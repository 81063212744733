import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { OfferTypeListFilterForm } from '../filter-form/offer-type-list-filter/offer-type-list-filter-form';
import { OfferTypeFilterFormValues } from '../filter-form/offer-type-list-filter/types';
import { AddOfferTypeModal } from '../models/add-offer-type-model/add-offer-type-model';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: OfferTypeFilterFormValues;
    handleFilter: (values: OfferTypeFilterFormValues) => void;

};

export const OfferTypeListTopBar = ({
    getInitialFilterValues,
    handleFilter,

}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'offer-type-list-filters-form';

    const screens = useBreakpoint();
    const [isOpen, setIsOpen] = useState<boolean>(false);


    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddOfferTypeModal onClose={handleCloseModal} editData={null} />
            </div>


            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModel}
                >
                    Add Offer Type
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <OfferTypeListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
