import { PaginatedRequestParams } from "api";

export enum ContactFilterFormField {
    Name = 'name',
    Email = 'emailId',
    MobileNo = 'mobile',
    ContactType = 'contactTypeId'
}

export type ContactFilterFormValues = {
    [ContactFilterFormField.Name]: string | undefined;
    [ContactFilterFormField.Email]: string | undefined;
    [ContactFilterFormField.MobileNo]: number | undefined;
    [ContactFilterFormField.ContactType]: string | undefined;
};

export type ContactFilterFormPaginated = ContactFilterFormValues &
    PaginatedRequestParams;