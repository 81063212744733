import { usePaginationParams } from 'hooks';
import { Outlet, useLocation } from 'react-router-dom';

import { CMSTable } from '../components/cms-tabel/cms-table';
import { CMSFilterFormValues, CMSFilterFormValuesPaginated } from '../components/filter-form/cms-filter/types';
import { CMSTopBar } from '../components/top-bar/cms-topbar';
import { useGetCMSLists } from '../use-cases/get-cms-list';
// import { userPermissionPage } from 'utils/user-permission-page';



const getInitialFilterValues: CMSFilterFormValues = {
    CMSType: undefined,
};

export const CMSView = () => {

    // const location = useLocation();

    // const { canView, canEdit, canAdd } = userPermissionPage(location.pathname);

    // if (!canView) {
    //     return <h1>Access Denied</h1>;
    // }

    const { params, updateParams, resetPage } =
        usePaginationParams<CMSFilterFormValuesPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch } = useGetCMSLists((params), {
        retry: false,
    });


    const handleFiltersChange = (values: CMSFilterFormValuesPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <CMSTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />
            <CMSTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};




