import { Layout as AntLayout, Space } from 'antd';
import { Header, SideBar } from 'components';
import { ResultError } from 'components/errors';
import Footer from 'components/footer/footer';
import { useSideBar } from 'providers/side-bar-provider';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';

import { Breadcum } from './breadcum';
import { StyledLayout } from './layout.styles';




export const Layout = () => {
  const { collapsed } = useSideBar();
  const { Content } = AntLayout;
  const location = useLocation();


  return (
    <StyledLayout $collapsed={collapsed}>
      <Header />

      <AntLayout>
        <SideBar />

        <Content className='layout-content'>
          {!location.pathname.includes("detail") && <Breadcum />}
          <ErrorBoundary FallbackComponent={ResultError}>
            <Space className='layout-space'>
              <Outlet />
            </Space>
          </ErrorBoundary>
        </Content>
      </AntLayout>
      <Footer />
    </StyledLayout>
  );
};
