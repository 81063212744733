export enum AddIBannerTypeFormField {
  Id = "Id",
  Flag = "Flag",
  BannerType = "AppType",
  Title = 'Title_En',
  Title_Ar = 'Title_Ar',
  Image = 'File_En',
  Image_Ar = 'File_Ar',
  Link = 'LinkUrl',
}

export type AddIBannerTypeFormValues = {
  [AddIBannerTypeFormField.Id]: number | undefined;
  [AddIBannerTypeFormField.Flag]: string | undefined;
  [AddIBannerTypeFormField.BannerType]: number | undefined;
  [AddIBannerTypeFormField.Title]: number | undefined;
  [AddIBannerTypeFormField.Title_Ar]: string | undefined;
  [AddIBannerTypeFormField.Image]: any | undefined;
  [AddIBannerTypeFormField.Image_Ar]: any | undefined;
  [AddIBannerTypeFormField.Link]: string | undefined;
};

