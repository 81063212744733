import { Button, Form } from 'antd';
import { useCreateRole } from 'features/users/use-cases/create-role';
import { useEffect, useState } from 'react';

import { RoleInfoFormItems } from './add-role-form-items';
import { AddRoleFormField, AddRoleFormValues } from './types';



export const AddRoleModal = ({ onClose, roleEditData, roleRefetch }: { onClose: () => void, roleEditData: any, roleRefetch: any }) => {
    const formName = 'add-Role-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddRoleFormValues>();

    const { isLoading, mutate } = useCreateRole();

    const handleClose = () => {
        onClose();
        form.resetFields();
        roleRefetch();
    }


    const getInitialValues = {
        [AddRoleFormField.Id]: roleEditData?.id,
        [AddRoleFormField.Flag]: roleEditData ? "update" : "insert",
        [AddRoleFormField.RoleName]: undefined,
        [AddRoleFormField.IsActive]: undefined,
    };


    useEffect(() => {
        if (roleEditData) {
            form.setFieldsValue({
                [AddRoleFormField.RoleName]: roleEditData.RoleName,
                [AddRoleFormField.IsActive]: roleEditData?.IsActive,
            });
        } else {
            form.resetFields();
        }

    }, [roleEditData, form]);

    const handleUpdate = (formData: AddRoleFormValues) => {

        roleEditData && (formData.Id = roleEditData.Id);
        formData.Flag = roleEditData ? "update" : "Insert";
        mutate(formData, { onSuccess: handleClose });
    };


    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{roleEditData ? 'Edit Role' : 'Add Role'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddRoleFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <RoleInfoFormItems />

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

