import { PaginatedRequestParams } from "api";

export enum ContactTypeFilterFormField {
    Type = 'title',
    Status = 'statusId',
}

export type ContactTypeFilterFormValues = {
    [ContactTypeFilterFormField.Type]: number | undefined;
    [ContactTypeFilterFormField.Status]: string | undefined;
};

export type ContactTypeFilterFormPaginated = ContactTypeFilterFormValues &
    PaginatedRequestParams;