import React from 'react';

import CustomerRegistration from '../components/customer-registration/customer-registration';
import CustomerTwoChart from '../components/customer-two-chart/customer-two-chart';
import DashboardTop from '../components/dashboard-top-bar/dashboard-top';
import SalesReport from '../components/sales-report/sales-report';
import ThreePieChart from '../components/three-pie-chart/three-pie-chart';
import { useGetDashboardTopData } from '../use-cases/get-dashboard-top-data';


const DashboardView: React.FC = () => {
  const { data, isLoading } = useGetDashboardTopData(({}), {
    retry: false,
  });


  return (
    <>

      <DashboardTop data={data} isLoading={isLoading} />
      <SalesReport />
      <ThreePieChart />
      <CustomerRegistration />
      <CustomerTwoChart data={data} />

    </>
  );
};

export default DashboardView;
