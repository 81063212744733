import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsNotifyFilterFormPaginated, ItemsNotifyFilterFormValues } from '../components/filter-form/items-notify-filter/types';
import { ItemsNotifyTable } from '../components/items-notify-tabel/items-notify-tabel';
import { ItemsNotifyListTopBar } from '../components/top-bar/items-notify-topbar';
import { useGetItemsNotifyLists } from '../use-cases/get-item-notify-list';


const getInitialFilterValues: ItemsNotifyFilterFormValues = {
    CustomerName: undefined,
    FromDate: undefined,
    ToDate: undefined,
    statusId: undefined,
};


export const ItemsNotifyViews = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsNotifyFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, refetch, isLoading } = useGetItemsNotifyLists((params));

    const handleFiltersChange = (values: ItemsNotifyFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsNotifyListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsNotifyTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};



