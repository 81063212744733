import { Grid } from 'antd';
import { ContentFilters } from 'components';
import { useState } from 'react';

import { ItemsStocksFilterForm } from '../filter-form/items-stocks-filter-form/items-stocks-filter-form';
import { ItemsStocksFilterFormValues } from '../filter-form/items-stocks-filter-form/types';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsStocksFilterFormValues;
    handleFilter: (values: ItemsStocksFilterFormValues) => void;
    tableParams: any
};

export const ItemsStocksListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {
    const formId = 'item-stocks-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>


            <ContentFilters formId={formId}>
                <ItemsStocksFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
