import { PaginatedRequestParams } from 'api';

export enum AddItemsSeasonFormField {
  Id = "Id",
  Flag = "Flag",
  Name = 'Title_En',
  Name_Ar = 'Title_Ar',
}

export type AddItemsSeasonFormValues = {
  [AddItemsSeasonFormField.Id]: number;
  [AddItemsSeasonFormField.Flag]: string;
  [AddItemsSeasonFormField.Name]: string;
  [AddItemsSeasonFormField.Name_Ar]: string;
};

