import logo from "../../../assets/log.png";

const WelcomeView = () => {
    return (
        <div style={{ display: "flex", height: "88vh", justifyContent: "center", alignItems: "center" }}>
            <img src={logo} width={"400px"} />
        </div>
    )
}

export default WelcomeView
