import { RolePermissionManagement } from "../components/role-permission-managemnet-details/role-permission-management";

export const RoleManagementView = () => {

    return (
        <>

            <RolePermissionManagement />

        </>
    );
};


