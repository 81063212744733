export enum AddOfferTypeFormField {
  Flag = 'Flag',
  Id = 'Id',
  TypeEn = 'OfferType_En',
  TypeAr = 'OfferType_Ar',
}

export type AddOfferTypeFormValues = {
  [AddOfferTypeFormField.Flag]: string;
  [AddOfferTypeFormField.Id]: number;
  [AddOfferTypeFormField.TypeEn]: string;
  [AddOfferTypeFormField.TypeAr]: string;
};

