import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Modal } from 'antd'
import { Loader } from 'components';
import { useCreateCancelOrder } from 'features/order-mannagemnet/use-cases/create-';
import { useGetOrderListDetails } from 'features/order-mannagemnet/use-cases/get-order-details';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { formatValue } from 'utils/formate-value';

import noImg from '../../../../assets/No_Image_Available.jpg';
import OrderStatusTimeline from './OrderStatusTimeline';


const OrderListDetailsPage = ({ id }: { id: string }) => {

    const { data, isLoading, refetch } = useGetOrderListDetails(id);

    const details = data?.getOrderDetails || {};
    const customerDetails = details?.CustomerDetailsList?.[0] || {};
    const customerAddress = details?.CustomerAddressList?.[0] || {};
    const ItemStatusList = details?.ItemdataList || [];
    const ItemStatusHistoryList = details?.ItemStatusHistoryList || {};

    const isCanceled = ItemStatusList && ItemStatusList[0]?.Status === "Canceled";


    const { isLoading: cancelLoading, mutate } = useCreateCancelOrder();


    const getOrderStatusBtn = (orderStatus: string, isPaid: boolean) => {
        // Check the order status and return the appropriate label
        if (orderStatus === "1") {
            return "Prepared";
        } else if (orderStatus === "3") {
            return "Picked Up";
        } else if (orderStatus === "4" && isPaid) {
            return "Delivered";
        } else if (orderStatus === "4" && !isPaid) {
            return "Pay Now";
        }

        return "Unknown Status"; // Fallback for undefined statuses
    };

    const cancelOrder = () => {

        const payload = {
            Id: +id
        }
        mutate(payload, { onSuccess: () => refetch() });
    }

    const { confirm } = Modal;


    const showCancelConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to Cancel this Order?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                cancelOrder();
            },
        });
    };


    if (isLoading) return <Loader />;

    return (

        <>

            <div>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={ROUTES.ORDER_LIST} >Order Management</Link>
                        </li>
                        <li className="breadcrumb-item " aria-current="page">
                            <Link to={ROUTES.ORDER_LIST} >Order List</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            Order Detail/{id}
                        </li>
                    </ul>
                </nav>

                <Card className='prfle_crd ordr_dtl_crd' title="Customer Info"
                    extra={
                        <>

                            {!isCanceled && <Button
                                type="primary"
                                onClick={showCancelConfirm}
                                loading={cancelLoading}
                            >
                                Cancel Order
                            </Button>
                            }

                        </>
                    }
                >
                    <div className='order_dtl'>

                        {/* Order Information */}
                        <div className='dtl_bx'>
                            <p>Order Id : <span>{details.OrderId || "N/A"}</span></p>
                            <p>Order Date : <span>{details.OrderDate || "N/A"}</span></p>
                            <p>Is Customer Self Pickup : <span>{details.IsPickupCustomer ? "Yes" : "No"}</span></p>
                            <p>Estimated Delivery Days : <span>{details.EstDeliveryDays || "N/A"}</span></p>
                        </div>

                        {/* Customer Information */}
                        <div className='dtl_bx'>
                            <p>Name : <span>{customerDetails.CustomerName || "N/A"}</span></p>
                            <p>Mobile : <span>{customerDetails.MobileNo || "N/A"}</span></p>
                            <p>Email : <span>{customerDetails.EmailId || "N/A"}</span></p>
                            <p>Address : <span>{customerAddress.Address || "N/A"}</span></p>
                        </div>

                        <div className='dtl_bx'>
                            <p>Transaction Id: <span>{details.TransactionId || "N/A"}</span></p>
                            <p>Payment Type: <span>{details.PaymentType || "N/A"}</span></p>
                            <p>Sub Total: <span>{formatValue(details.TotalSalesPrice)}</span></p>
                            <p>Delivery Charge: <span>{formatValue(details.DeliveryCharge)}</span></p>
                            <p>Grant Total: <span>{formatValue(details.TotalSalesPrice + details.DeliveryCharge)}</span></p>
                        </div>

                    </div>


                    {ItemStatusList?.map((item: any, i: number) => {
                        return (
                            <div className='order_track' key={i}>
                                <div className='profile_data' >
                                    <div className='prf_img'>
                                        <div className='prf_img_bx'>
                                            <img src={item?.ProductImage || ""} alt=""
                                                onError={(e) => e.currentTarget.src = noImg}
                                            />
                                        </div>

                                    </div>
                                    <div className='ordership_dtl'>
                                        <div className='ordr_shp_head'>
                                            <h2>{item?.ItemName_En}</h2>
                                            <span>Sales Price : <span>{formatValue(item?.SalesPrice)}</span></span>
                                        </div>

                                        <div className='shp_d'>
                                            <span>Brand : <span> {item?.BrandName_Ar}</span></span>
                                            <span>Size : <span>{item?.Size}</span></span>
                                            <span>Qty : <span>{item?.Qty}</span></span>
                                        </div>

                                        <Button className='ordr_sts'>{item.Status}</Button>



                                        <div className='order_steps'>
                                            <OrderStatusTimeline item={item} />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        )
                    })}

                </Card>
            </div>

            <Card className='prfle_crd' title="Order History"  >
                <table className='order_dtl_table'>
                    <thead>
                        <tr style={{ fontWeight: "700" }}>
                            <th>Item Name</th>
                            <th>Status</th>
                            <th>Updated Date</th>
                            <th>Updated By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ItemStatusHistoryList?.map((row: any, index: number) => (
                            <tr key={index}>
                                <td>{row.ItemName}</td>
                                <td>{row.Status_En}</td>
                                <td>{moment(row.CreatedDate).format("DD/MM/YY HH:mm A")}</td>
                                <td>{row.CreatedBy === null ? "Admin" : row.CreatedBy}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
        </>
    )
}

export default OrderListDetailsPage
