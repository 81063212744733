import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import {
  AuthApiService,
  LoginDTO,
  setAccessToken,
  setRefreshToken,
} from 'features/auth';

import { handleError } from '../../../api';


const { login } = AuthApiService();

type UseLoginPayload = LoginDTO & { persistSession: boolean };

export const useLogin = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UseLoginPayload) => {
      const { MemEmailId, MemPassword } = payload;

      return login({ MemEmailId, MemPassword });
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        console.log(data, login);
        if (data?.Item2 === 401) {
          message.error(data?.Item3)
        } else {
          message.success(data?.Item3)
          setAccessToken(data?.Item1?.access_token, variables.persistSession);
          setRefreshToken(data?.Item1?.access_token, variables.persistSession);
        }

      },
    },
  );
};
