import { Button, Form, Image,Input, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useUpdateNews } from 'features/marketing/use-cases/update-news';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewsFormField, AddNewsFormValues } from './types';




export const AddNewsModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-news-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddNewsFormValues>();

    const { t } = useTranslation();

    const { isLoading, mutate } = useUpdateNews();

    const [imageAr, setImageAr] = useState<File | null>(null);
    const [imageEn, setImageEn] = useState<any>(null);
    const [fileEnName, setFileEnName] = useState<string>("");
    const [fileArName, setFileArName] = useState<string>("");

    const handleClose = () => {
        onClose();
        form.resetFields();
        setImageEn(null);
        setImageAr(null)
        setFileEnName("");
        setFileArName("")
    }


    const getInitialValues = {
        [AddNewsFormField.Id]: undefined,
        [AddNewsFormField.Flag]: undefined,
        [AddNewsFormField.Title]: undefined,
        [AddNewsFormField.Title_Ar]: undefined,
        [AddNewsFormField.Image]: undefined,
        [AddNewsFormField.Image_Ar]: undefined,
        [AddNewsFormField.Description]: undefined,
        [AddNewsFormField.DescriptionAr]: undefined,

    };


    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formValues: AddNewsFormValues) => {
        const formData = new FormData();

        // Append other form values
        Object.keys(formValues).forEach((key) => {
            if (key !== 'File_En' && key !== 'File_Ar') {
                formData.append(key, formValues[key as keyof AddNewsFormValues] as string);
            }
        });

        formData.append('Flag', "insert");

        // Append the file (image)
        if (imageAr) {
            formData.append('File_Ar', imageAr);
        }
        if (imageEn) {
            formData.append('File_En', imageEn);
        }

        if (editData) {
            formData.append('Id', editData.Id);
        }

        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        handleClose();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageEn(selectedFile);
                setFileEnName(selectedFile.name);

            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageEn(null);
                setFileEnName("");
            }
        }
    };

    const handleFileArChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageAr(selectedFile);
                setFileArName(selectedFile.name);
            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageAr(null);
                setFileArName("");
            }
        }
    };



    useEffect(() => {
        if (editData?.NewsImage) {
            const extractedFileName = editData?.NewsImage.split('/').pop();
            setFileEnName(extractedFileName || 'ImageEn.jpg');
        }
        if (editData?.NewsImage_Ar) {
            const extractedFileName = editData?.NewsImage_Ar.split('/').pop();
            setFileArName(extractedFileName || 'ImagAr.jpg');
        }
    }, [editData]);

    const inputRef = useRef<any>();
    const inputArRef = useRef<any>();

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? "Edit News" : "Add News"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddNewsFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >

                            <Form.Item<AddNewsFormField>
                                label={"Title"}
                                name={AddNewsFormField.Title}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >

                                <Input
                                    placeholder={"Title"}
                                />
                            </Form.Item>

                            <Form.Item<AddNewsFormField>
                                label={"Title (Arabic)"}
                                name={AddNewsFormField.Title_Ar}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Title (Arabic)"}
                                />
                            </Form.Item>


                            <Form.Item<AddNewsFormField>
                                label={"Image"}
                                name={AddNewsFormField.Image}
                            // help={"Please select a .jpg, .jpeg, or .png file."}
                            >
                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => inputRef.current.click()}>Choose File</Button>}
                                    value={fileEnName}
                                    readOnly
                                />
                                <input
                                    type='file'
                                    // id='fileInput'
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileChange}
                                />
                                <span>
                                    Recommended size-
                                    Width : 3000 px,
                                    Height : 1050 px
                                </span>

                            </Form.Item>
                            <>
                                <div className='edit_img_show'>
                                    {imageEn ? (
                                        <Image
                                            src={URL.createObjectURL(imageEn)}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Preview"
                                        />
                                    ) : (

                                        editData?.NewsImage && (
                                            <Image
                                                src={editData.NewsImage}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Brand Logo"
                                            />
                                        )
                                    )}
                                </div>
                            </>

                            <Form.Item<AddNewsFormField>
                                label={"Image (Arabic)"}
                                name={AddNewsFormField.Image_Ar}
                                help={"Please select a .jpg, .jpeg, or .png file."}
                            >
                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => inputArRef.current.click()}>Choose File</Button>}
                                    value={fileArName}
                                    readOnly
                                />
                                <input
                                    ref={inputArRef}
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileArChange}
                                />
                                <span>
                                    Recommended size-
                                    Width : 3000 px,
                                    Height : 1050 px
                                </span>
                            </Form.Item>

                            <>
                                <div className='edit_img_show'>
                                    {imageAr ? (
                                        <Image
                                            src={URL.createObjectURL(imageAr)}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Preview"
                                        />
                                    ) : (

                                        editData?.NewsImage_Ar && (
                                            <Image
                                                src={editData.NewsImage}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Brand Logo"
                                            />
                                        )
                                    )}
                                </div>
                            </>

                            <Form.Item<AddNewsFormField>
                                label={"Description"}
                                name={AddNewsFormField.Description}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={"Description"}
                                />
                            </Form.Item>

                            <Form.Item<AddNewsFormField>
                                label={"Description (Arabic)"}
                                name={AddNewsFormField.DescriptionAr}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={"Description (Arabic)"}
                                />
                            </Form.Item>



                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button loading={isLoading} type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

