import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { BannerFilterFormField, BannerFilterFormPaginated, BannerFilterFormValues } from '../components/filter-form/banner-list-filter/types';
import SocialMediaListTable from '../components/social-media-list-table/social-media-list-table';
import { useGetSocialMediaLists } from '../use-cases';


const getInitialFilterValues: BannerFilterFormValues = {
  [BannerFilterFormField.BannerType]: undefined,
  [BannerFilterFormField.Name]: undefined,
  [BannerFilterFormField.IsActive]: undefined,
};


export const SocialMediaListView = () => {

  const { params } =
    usePaginationParams<BannerFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data } = useGetSocialMediaLists((params), {
    retry: false,
  });


  // const handleFiltersChange = (values: BannerFilterFormPaginated) => {
  //   updateParams(values);
  //   resetPage();
  //   refetch();
  // };

  const mainData = data?.Items?.length > 0 ? data?.Items[0] : {}

  return (
    <>
      {/* <BannerListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      /> */}

      <SocialMediaListTable
        // isLoading={isLoading || isFetching}
        // itemList={data}
        // itemsCount={data?.TotalRecords || 0}
        data={mainData}
      />
      <Outlet />
    </>
  );
};

