import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";

import { updateUserMenuPermissionDTO, UserApiService } from "../api/user-api.service";
import { userQueryKeys } from "./query-keys";

export const useUpdateUserMenuPermission = () => {
    const { message } = App.useApp();
    const queryClient = useQueryClient();

    const { updateUserMenuPermission } = UserApiService();

    return useMutation(
        (payload: updateUserMenuPermissionDTO) => {
            return updateUserMenuPermission(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {

                queryClient.invalidateQueries({
                    queryKey: userQueryKeys.lists(),
                });
                message.success(data.ResponseMessage);
                // if (data?.ResponseStatus === 2) {
                //     message.success(data.ResponseMessage);
                // } else {
                //     message.error(data.ResponseMessage);
                // }
            },
        },
    );
};