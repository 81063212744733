import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { DropdownCommonAPI } from 'components/dropdown-common-api/api/common-dropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { dropdownLists } from '../top-bar/delivery-topbar';
import { DeliveryChargeFilterFormField, DeliveryChargeFilterFormValues } from './types';
const { useBreakpoint } = Grid;
type DeliveryFilterFormProps = {
    formId: string;
    handleFilter: (values: DeliveryChargeFilterFormValues) => void;
    initialValues: DeliveryChargeFilterFormValues;
    closeModal?: () => void;
    dropdown: dropdownLists[];
};
export const DeliveryItemFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
    dropdown
}: DeliveryFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<DeliveryChargeFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();
    const [cityName, SetCityName] = useState([])
    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: DeliveryChargeFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };

    const statusData = [
        { label: 'All', value: '' },
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 }
    ];

    useEffect(() => {
        (async () => {
            try {
                const response = await DropdownCommonAPI().getCityLists({
                    Flag: 'GetCityList' // Pass the flag in the correct format
                });
                SetCityName(response?.dropdownLists)
            } catch (error) {
                console.error("Error fetching governorate data:", error);
            }
        })();
    }, []);
    
return (
        <Form<DeliveryChargeFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>

                        <Form.Item<DeliveryChargeFilterFormValues>
                            label={'Governorate'}
                            name={DeliveryChargeFilterFormField.GovernorateFkId}
                        >
                            <Select
                                allowClear
                                placeholder={'Select Governorate'}
                                options={dropdown?.map((item: any) => ({
                                    value: item.Value,
                                    label: item.Text,
                                }))}
                            />
                        </Form.Item>

                        <Form.Item<DeliveryChargeFilterFormValues>
                            label={"City"}
                            name={DeliveryChargeFilterFormField.CityFkId}
                        >
                            <Select
                                allowClear
                                placeholder={'Select City'}
                                options={cityName?.map((item: any) => ({
                                    value: item.Value,
                                    label: item.Text,
                                })) || []}
                            />
                        </Form.Item>
                        <Form.Item<DeliveryChargeFilterFormValues>
                            label={"Status"}
                            name={DeliveryChargeFilterFormField.isActive}
                        >
                            <Select
                                allowClear
                                placeholder={'Select Status'}
                                options={statusData}
                            />
                        </Form.Item>
                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
