import { PaginatedRequestParams } from "api";

export enum CustomSizeFilterFormField {
    Name = 'Title',
    StatusId = 'StatusId',
}

export type CustomSizeFilterFormValues = {
    [CustomSizeFilterFormField.Name]: string | undefined;
    [CustomSizeFilterFormField.StatusId]: string | undefined;
};

export type CustomSizeFilterFormValuesPaginated = CustomSizeFilterFormValues &
    PaginatedRequestParams;