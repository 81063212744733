import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PromotionTypeFilterFormField, PromotionTypeFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: PromotionTypeFilterFormValues) => void;
    initialValues: PromotionTypeFilterFormValues;
    closeModal?: () => void;
};

export const PromotionTypeFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<PromotionTypeFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const { data: brandData, isLoading: brandLoading } = useGetDropdownLists({ Flag: "GetBrandList" });


    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: PromotionTypeFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };


    return (
        <Form<PromotionTypeFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>


                        <Form.Item<PromotionTypeFilterFormValues>
                            label={"Type"}
                            name={PromotionTypeFilterFormField.Type}
                        >
                            <Input
                                placeholder={"Type"}
                            />
                        </Form.Item>

                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
