import { PaginatedRequestParams } from "api";

export enum BannerFilterFormField {
    BannerType = 'AppType',
    Name = 'title',
    IsActive = 'statusId',
}

export type BannerFilterFormValues = {
    [BannerFilterFormField.BannerType]: number | undefined;
    [BannerFilterFormField.Name]: string | undefined;
    [BannerFilterFormField.IsActive]: string | undefined;
};

export type BannerFilterFormPaginated = BannerFilterFormValues &
    PaginatedRequestParams;