export enum AddBenefitsFormField {
  Id = "Id",
  Flag = "Flag",
  BenefitsType = "BenefitsType",
  Title = 'Title_En',
  Title_Ar = 'Title_Ar',
  Logo = 'Logo',
  Description_En = 'Description_En',
  Description_Ar = 'Description_Ar',
}

export type AddBenefitsFormValues = {
  [AddBenefitsFormField.Id]: number | undefined;
  [AddBenefitsFormField.Flag]: string | undefined;
  [AddBenefitsFormField.BenefitsType]: number | undefined;
  [AddBenefitsFormField.Title]: string | undefined;
  [AddBenefitsFormField.Title_Ar]: string | undefined;
  [AddBenefitsFormField.Logo]: any | undefined;
  [AddBenefitsFormField.Description_Ar]: string | undefined;
  [AddBenefitsFormField.Description_En]: string | undefined;
};

