import { Form, FormInstance, Input, Select } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditSizeFormField, EditSizeFormValues } from './types';



export const EditSizeForm = ({ form, editData }: { form: FormInstance<EditSizeFormValues>; editData: any }) => {

    const { t } = useTranslation();

    const [catId, setCatId] = useState<any>();

    const [subCatId, setSubCatId] = useState<any>();

    const params = {
        Flag: "GetSubCategoryListByParentId",
        parentId: catId
    }

    const sizeParams = {
        Flag: "GetSizeTypeListByParentId",
        parentId: subCatId
    }

    const { data: statusData, isLoading: statusLoad } = useGetDropdownLists({ Flag: "GetGroupList" });

    const { data: subCategoryData, isLoading: subCatLoad, refetch: subCatRefetch } = useGetDropdownLists(params, { enabled: false });

    const { data: sizeTypeData, isLoading: sizeCatLoad, refetch: sizeRefetch } = useGetDropdownLists(sizeParams, { enabled: false });

    useEffect(() => {
        if (editData) {
            setCatId(editData.Group_Fk_Id)
            setSubCatId(editData.Category_Fk_Id)
        }
    }, [editData])


    useEffect(() => {
        if (catId) {
            subCatRefetch()
        }

    }, [catId])

    useEffect(() => {
        if (subCatId) {
            sizeRefetch()
        }

    }, [subCatId])



    const handleCategoryChange = (value: number) => {
        form.resetFields(["SubCategory_Id,SizeType_Fk_Id"]);
        form.setFieldValue("Category_Id", value);
        setCatId(value);
    };


    const handleSubCategoryChange = (value: number) => {
        form.setFieldValue("SubCategory_Id", value);
        form.resetFields(["SizeType_Fk_Id"]);
        setSubCatId(value);
    };


    return (
        <FormSectionsSeparator title={""} >

            <Form.Item<EditSizeFormField>
                label={"Category"}
                name={EditSizeFormField.Category}
            // rules={[
            //     {
            //         required: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Select
                    loading={statusLoad}
                    showSearch
                    options={statusData?.map((item: { Text: string; Value: number }) => ({
                        label: item.Text,
                        value: item.Value,
                    }))}

                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }

                    placeholder={"Select Category Name"}
                    onChange={handleCategoryChange}
                />


            </Form.Item >

            <Form.Item<EditSizeFormField>
                label={"Sub Category"}
                name={EditSizeFormField.SubCategory}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Select
                    loading={subCatLoad}
                    showSearch
                    options={subCategoryData?.map((item: { Text: string; Value: number }) => ({
                        label: item.Text,
                        value: item.Value,
                    }))}
                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }

                    placeholder={"Select Sub Category Name"}
                    onChange={handleSubCategoryChange}
                />
            </Form.Item>


            <Form.Item<EditSizeFormField>
                label={"Size Type"}
                name={EditSizeFormField.SizeType}
            // rules={[
            //     {
            //         required: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Select
                    loading={subCatLoad}
                    showSearch
                    options={sizeTypeData?.map((item: { Text: string; Value: number }) => ({
                        label: item.Text,
                        value: item.Value,
                    }))}
                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }

                    placeholder={"Select Size Type"}
                />
            </Form.Item>

            <Form.Item<EditSizeFormField>
                label={"Size"}
                name={EditSizeFormField.Size}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Size"}
                />
            </Form.Item>

            <Form.Item<EditSizeFormField>
                // label={"Is Default"}
                name={EditSizeFormField.IsDefault}
                // rules={[
                //     {
                //         required: true,
                //         message: t('validateMessages.custom.nomsg'),
                //     },
                // ]}
                valuePropName="checked"
            >
                <Checkbox >Is Default</Checkbox>
            </Form.Item>



        </FormSectionsSeparator >
    );
};
