
export enum DeliveryChargeFormField {
    Id = 'Id',
    Flag = 'Flag',
    CityFkId = 'City_Fk_Id',
    GovernorateFkId = 'Governorate_Fk_Id',
    Charge = 'Charge',
    NoOfDays = 'NoOfDays',
    isActive = 'isActive',
}

export type DeliveryChargeFormValues = {
    [DeliveryChargeFormField.Id]: string | undefined;
    [DeliveryChargeFormField.Flag]: string | undefined;
    [DeliveryChargeFormField.CityFkId]: string | undefined;
    [DeliveryChargeFormField.GovernorateFkId]: string | undefined;
    [DeliveryChargeFormField.Charge]: string | undefined;
    [DeliveryChargeFormField.NoOfDays]: string | undefined;
    [DeliveryChargeFormField.isActive]: number | undefined;

};

