import { Button, Form, Input } from 'antd';
import { useUpdateOfferType } from 'features/marketing/use-cases/update-offer-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddOfferTypeFormField, AddOfferTypeFormValues } from './types';


export const AddOfferTypeModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-offer-type-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddOfferTypeFormValues>();

    const { t } = useTranslation();


    const { isLoading, mutate } = useUpdateOfferType();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [AddOfferTypeFormField.Flag]: undefined,
        [AddOfferTypeFormField.Id]: undefined,
        [AddOfferTypeFormField.TypeEn]: undefined,
        [AddOfferTypeFormField.TypeAr]: undefined,

    };

    // console.log(editData, "editData")
    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        } else {
            form.resetFields();
        }

    }, [editData, form]);



    const handleUpdate = (formData: AddOfferTypeFormValues) => {
        formData.Flag = "insert";
        formData.Id = editData?.Id;

        mutate(formData, { onSuccess: handleClose });
    };



    const onReset = () => {
        form.resetFields();
        onClose();
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Offer Type' : 'Add Offer Type'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddOfferTypeFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <Form.Item<AddOfferTypeFormValues>
                            label={"Offer Type"}
                            name={AddOfferTypeFormField.TypeEn}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Offer Type"}
                            />
                        </Form.Item>

                        <Form.Item<AddOfferTypeFormValues>
                            label={"Offer Type (Arabic)"}
                            name={AddOfferTypeFormField.TypeAr}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Offer Type (Arabic)"}
                            />
                        </Form.Item>

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }} loading={isLoading}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

