import { Form, FormInstance, Input, Select } from 'antd';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddISizeTypeFormField, AddISizeTypeFormValues } from './types';




export const AddSizeTypeForm = ({ editData, form }: { editData: any; form: FormInstance<AddISizeTypeFormValues> }) => {

    const { t } = useTranslation();
    const [subCatId, setSubCatId] = useState<any>();

    const params = {
        Flag: "GetSubCategoryListByParentId",
        parentId: subCatId
    }

    const { data: statusData, isLoading: statusLoad } = useGetDropdownLists({ Flag: "GetGroupList" });
    const { data: subCategoryData, isLoading: subCatLoad, refetch } = useGetDropdownLists(params, { enabled: false });

    useEffect(() => {
        if (editData) {
            setSubCatId(editData?.Group_Fk_Id)
        }

    }, [editData])

    useEffect(() => {
        if (subCatId) {
            refetch()
        }

    }, [subCatId])


    const handleCategoryChange = (value: number) => {
        setSubCatId(value);
        form.resetFields(["Category_Fk_Id"]);
        form.setFieldValue("Group_Fk_Id", value);
    };


    return (
        <FormSectionsSeparator title={""} >

            <Form.Item<AddISizeTypeFormField>
                label={"Category"}
                name={AddISizeTypeFormField.CatId}
            // rules={[
            //     {
            //         required: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Select
                    allowClear
                    loading={statusLoad}
                    showSearch
                    options={statusData?.map((item: { Text: string; Value: number }) => ({
                        label: item.Text,
                        value: item.Value,
                    }))}

                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }

                    placeholder={"Select Category Name"}
                    onChange={handleCategoryChange}
                />


            </Form.Item >

            <Form.Item<AddISizeTypeFormField>
                label={"Sub Category"}
                name={AddISizeTypeFormField.SubCatId}
            // rules={[
            //     {
            //         required: true,
            //         message: t('validateMessages.custom.nomsg'),
            //     },
            // ]}
            >
                <Select
                    allowClear
                    loading={subCatLoad}
                    showSearch
                    options={subCategoryData?.map((item: { Text: string; Value: number }) => ({
                        label: item.Text,
                        value: item.Value,
                    }))}
                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }

                    placeholder={"Select Sub Category Name"}
                />
            </Form.Item>

            <Form.Item<AddISizeTypeFormField>
                label={"Title"}
                name={AddISizeTypeFormField.Title}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Title"}
                />
            </Form.Item>

            <Form.Item<AddISizeTypeFormField>
                label={"Title (Arabic)"}
                name={AddISizeTypeFormField.Title_Ar}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Title (Arabic)"}
                />
            </Form.Item>


            <Form.Item<AddISizeTypeFormField>
                label={"Code"}
                name={AddISizeTypeFormField.Code}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Code"}
                />
            </Form.Item>



        </FormSectionsSeparator >
    );
};
