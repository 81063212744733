import { Button, Form } from 'antd';
import { useUpdateStatusDelivery } from 'features/delivery-charges/use-cases/update-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryItem } from './delivery-item';
import { DeliveryChargeFormField, DeliveryChargeFormValues } from './types';




export const DeliveryModal = ({ onClose, editData, dropdown }: { onClose: () => void, editData: any, dropdown: any }) => {
  const formName = ' Add-Delivery-Charge-form';
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<any>();

  const { t } = useTranslation();

  const { isLoading, mutate } = useUpdateStatusDelivery();

  const handleClose = () => {
    onClose();
    form.resetFields();
  }

  const getInitialValues = {
    [DeliveryChargeFormField.Id]: undefined,
    [DeliveryChargeFormField.Flag]: undefined,
    [DeliveryChargeFormField.CityFkId]: undefined,
    [DeliveryChargeFormField.GovernorateFkId]: undefined,
    [DeliveryChargeFormField.Charge]: undefined,
    [DeliveryChargeFormField.NoOfDays]: undefined,
    [DeliveryChargeFormField.isActive]: 1,

  };


  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        [DeliveryChargeFormField.isActive]: editData.IsActive,
      });
    } else {
      form.resetFields();
    }

  }, [editData, form]);

  const handleUpdate = (formData: any) => {

    formData.Id = editData?.Id;
    formData.Flag = "insert";
    mutate(formData, { onSuccess: handleClose });

  };

  const onReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <>

      <div className={'popup'}>
        <div className='modal_head'>
          <button onClick={handleClose} className='close-btn'>
            &times;
          </button>
          <h4>{editData ? " Edit Delivery Charge" : " Add Delivery Charge"}</h4>
        </div>
        <div className='modal_body'>
          <Form<DeliveryChargeFormValues>
            id={formName}
            name={formName}
            form={form}
            layout="vertical"
            initialValues={getInitialValues}
            onFinish={handleUpdate}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
          >
            <DeliveryItem form={form} dropdown={dropdown} />


            <div className='form-button'>
              <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }} loading={isLoading}>Save</Button>
              <Button onClick={onReset} danger type="primary"> Cancel</Button>
            </div>
          </Form>
        </div>
      </div>

    </>
  );
};

