import { PaginatedRequestParams } from "api";

export enum BrandsFilterFormField {
    Title = 'Title',
    IsActive = 'statusId',
    MobileNo = 'MobileNo',
    FromDate = 'FromDate',
    ToDate = 'ToDate',
    arabic = 'arabic',
}

export type BrandsFilterFormValues = {
    [BrandsFilterFormField.Title]: string | undefined;
    [BrandsFilterFormField.IsActive]: string | undefined;
    [BrandsFilterFormField.MobileNo]: string | undefined;
    [BrandsFilterFormField.FromDate]: string | undefined;
    [BrandsFilterFormField.ToDate]: string | undefined;
    [BrandsFilterFormField.arabic]: string | undefined;

};

export type BrandsFilterFormPaginated = BrandsFilterFormValues &
    PaginatedRequestParams;