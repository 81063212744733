export enum AddSocialMediaFormField {
  Flag = 'Flag',
  Id = 'Id',
  FacebookUrl = 'FacebookUrl',
  TwitterUrl = 'TwitterUrl',

  InstagramUrl = 'InstagramUrl',
  LinkedInUrl = 'LinkedInUrl',
  YoutubeUrl = 'YoutubeUrl',
  GooglePlusUrl = 'GooglePlusUrl',

  EmailUrl = 'EmailUrl',
  TeligramUrl = 'TeligramUrl',

}

export type AddSocialMediaFormValues = {
  [AddSocialMediaFormField.Flag]: string;
  [AddSocialMediaFormField.Id]: string;
  [AddSocialMediaFormField.FacebookUrl]: string;
  [AddSocialMediaFormField.TwitterUrl]: string;

  [AddSocialMediaFormField.InstagramUrl]: string;
  [AddSocialMediaFormField.LinkedInUrl]: string;
  [AddSocialMediaFormField.YoutubeUrl]: string;
  [AddSocialMediaFormField.GooglePlusUrl]: string;

  [AddSocialMediaFormField.EmailUrl]: string;
  [AddSocialMediaFormField.TeligramUrl]: string;

};

