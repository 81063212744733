import { Grid } from 'antd';
import { ContentFilters } from 'components';
import { useState } from 'react';

import { ItemsPurchasedFilterForm } from '../filter-form/items-purchased-filter/items-purchased-list-filter';
import { ItemsPurchasedFilterFormValues } from '../filter-form/items-purchased-filter/types';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsPurchasedFilterFormValues;
    handleFilter: (values: ItemsPurchasedFilterFormValues) => void;
};

export const ItemsPurchasedListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {


    const formId = 'purchased-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>

            {/* <div className='head_btn'>
                <Button
                    type='primary'
                // onClick={handleExcelDownload}
                >
                    Add Size Type
                </Button>
            </div> */}


            <ContentFilters formId={formId}>
                <ItemsPurchasedFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
