import { ContentFilters } from 'components';
import { useState } from 'react';

import { SubscribersListFilterForm } from '../filter-form/suscribers-list-filter/subscribers-list-filter-form';
import { SubscribersFilterFormValues } from '../filter-form/suscribers-list-filter/types';


type UsersTopBarProps = {
    getInitialFilterValues: SubscribersFilterFormValues;
    handleFilter: (values: SubscribersFilterFormValues) => void;
};

export const SubscribersListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'subscribers-list-filters-form';


    return (
        <>
            <ContentFilters formId={formId}>
                <SubscribersListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
