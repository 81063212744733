import { apiClient, ApiClientConfig } from 'api';

export type GetDayViewStatsParams = {
Flag?: any;
};

export const DropdownCommonAPI = () => {
    const getCommonLists = async (
        params: GetDayViewStatsParams,  // Your existing params
        callConfig?: Partial<ApiClientConfig>,  // Optional config for API calls
        flag?: boolean, // Additional flag parameter
      ) => {
        const response = await apiClient<any>({
          ...callConfig,
          endpoint: `/dropdown/getdropdownlist`,
          params: {
            ...params,   // Existing params
            flag,       // Add the flag to the params (or use it differently if needed)
          },
        });
    
        return response.data;
      };
      const getCityLists = async (
        params: GetDayViewStatsParams,  // Your existing params
        callConfig?: Partial<ApiClientConfig>,  // Optional config for API calls
        flag?: boolean, // Additional flag parameter
      ) => {
        const response = await apiClient<any>({
          ...callConfig,
          endpoint: `/dropdown/getdropdownlist`,
          params: {
            ...params,   // Existing params
            flag,       // Add the flag to the params (or use it differently if needed)
          },
        });
    
        return response.data;
      };
  
return {
    getCommonLists,
    getCityLists
  };
};
