import { Button, Card, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useGetItemInfoById } from 'features/items/use-cases/get-item-info';
import { useUpdateItemsInfo } from 'features/items/use-cases/update-item-info';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddItemsFormField, AddItemsFormValues } from './types';

const ItemsInfoTab = ({ id, tabs }: { id: string; tabs: string }) => {

    // const params = {
    //     Id: id,
    //     CurrentPage: 1,
    //     RecordsPerPage: 1
    // }

    const [categoryId, setCategoryId] = useState<any>(null);

    const { data: brands, isLoading: brandsLoading } = useGetDropdownLists({ Flag: "GetBrandList" });
    const { data: category, isLoading: catLoad } = useGetDropdownLists({ Flag: "GetGroupList" });
    const { data: subCategory, isLoading: subCatLoad, refetch } = useGetDropdownLists({ Flag: "GetSubCategoryListByParentId", parentId: categoryId }, { enabled: false });
    const { data: offerType, isLoading: offerLoading } = useGetDropdownLists({ Flag: "GetOfferTypeList" });

    const { data, refetch: getRefetch, isFetching } = useGetItemInfoById(id);


    const formName = 'edit-items-info-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddItemsFormValues>();

    const { isLoading, mutate } = useUpdateItemsInfo();

    const [edit, setEdit] = useState<boolean>(false);



    const { t } = useTranslation();

    const getInitialValues = {
        [AddItemsFormField.Flag]: undefined,
        [AddItemsFormField.Id]: undefined,
        [AddItemsFormField.ItemName_En]: undefined,
        [AddItemsFormField.ItemName_Ar]: undefined,
        [AddItemsFormField.ItemDescription_En]: undefined,
        [AddItemsFormField.ItemDescription_Ar]: undefined,
        [AddItemsFormField.OtherInformation_En]: undefined,
        [AddItemsFormField.OtherInformation_Ar]: undefined,
        [AddItemsFormField.Group_Fk_Id]: undefined,
        [AddItemsFormField.Category_Fk_Id]: undefined,
        [AddItemsFormField.Brand_Fk_Id]: undefined,
        [AddItemsFormField.Item_Code]: undefined,

        [AddItemsFormField.ItemPrice]: undefined,
        [AddItemsFormField.OfferType_Fk_Id]: undefined,
        [AddItemsFormField.Offer]: undefined,
        [AddItemsFormField.OfferPrice]: undefined,

        [AddItemsFormField.ItemCellableQty]: undefined,
        [AddItemsFormField.IsDiscount]: undefined,
    };


    useEffect(() => {

        if (data) {
            form.setFieldsValue({
                ...data?.getItemById,
                [AddItemsFormField.Item_Code]: data?.getItemById?.ItemCode,
                [AddItemsFormField.ItemCellableQty]: data?.getItemById?.MaxCellableQty,
            });
            setCategoryId(data?.getItemById?.Group_Fk_Id);

        } else {
            form.resetFields();
        }

    }, [data, form, isFetching]);
    console.log(isFetching, "isFetching")

    useEffect(() => {
        if (categoryId) {
            refetch();
        }
    }, [categoryId])


    const handleUpdate = (formData: AddItemsFormValues) => {
        // Clone formData to avoid modifying the original form object
        const payload = { ...formData, Flag: "Insert", Id: data?.getItemById?.Id || "" };

        // If IsDiscount is 0, remove price and itemPrice fields
        if (formData.IsDiscount === 0) {
            delete payload.OfferType_Fk_Id;
            delete payload.Offer;
            delete payload.OfferPrice;
        }

        // Delete unnecessary fields from the cloned object
        delete payload.IsDiscount;

        // Perform mutation and handle success
        mutate(payload, {
            onSuccess: handleCancelEdit,
            onError: (error) => {
                // Handle errors, possibly show a notification or alert
                console.error('Form update failed', error);
            }
        });
    };



    const discount = [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
    ];

    const handleEdit = () => {
        setEdit(true);
    }

    const handleCancelEdit = () => {
        getRefetch();
        setEdit(false);
    }

    const getSubCategory = (catId: number) => {
        setCategoryId(catId);
        form.resetFields([AddItemsFormField.Category_Fk_Id])
    }

    console.log(edit)
    return (


        <div>
            <Card className='prfle_crd' title={`Items Info /${data?.getItemById?.ItemName_En}`} extra={
                <>{edit ?
                    <>
                        <Button
                            type="primary"
                            htmlType='submit'
                            loading={isLoading}
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleCancelEdit}
                        >
                            Cancel
                        </Button>
                    </>
                    :
                    <Button
                        type="primary"
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                }

                </>

            } >


                <>
                    <Form<AddItemsFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        className='info_filter'
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading || !edit}
                    >
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Brand"}
                                    name={AddItemsFormField.Brand_Fk_Id}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <Select
                                        loading={brandsLoading}
                                        showSearch
                                        options={brands?.map((item: { Text: string; Value: number }) => ({
                                            label: item.Text,
                                            value: item.Value,
                                        }))}
                                        filterOption={(input, option) =>
                                            typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder={t('volunteer.field.assign_event.placeholder')}
                                    />
                                </Form.Item >
                            </Col>


                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Category"}
                                    name={AddItemsFormField.Group_Fk_Id}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <Select
                                        loading={catLoad}
                                        showSearch
                                        options={category?.map((item: { Text: string; Value: number }) => ({
                                            label: item.Text,
                                            value: item.Value,
                                        }))}
                                        filterOption={(input, option) =>
                                            typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder={"Category"}
                                        onChange={(value) => getSubCategory(value)}
                                    />
                                </Form.Item >
                            </Col>

                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Sub Category"}
                                    name={AddItemsFormField.Category_Fk_Id}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <Select
                                        loading={subCatLoad}
                                        showSearch
                                        options={subCategory?.map((item: { Text: string; Value: number }) => ({
                                            label: item.Text,
                                            value: item.Value,
                                        }))}
                                        filterOption={(input, option) =>
                                            typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder={"Sub Category"}
                                    />
                                </Form.Item>

                            </Col>

                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Code"}
                                    name={AddItemsFormField.Item_Code}

                                >
                                    <Input
                                        placeholder={"Last Name"}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>


                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Name"}
                                    name={AddItemsFormField.ItemName_En}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Item Name"}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Cellable Qty"}
                                    name={AddItemsFormField.ItemCellableQty}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        type='number'
                                        placeholder={"Item Cellable Qty"}
                                        style={{ width: "100%" }}
                                        className="input-number-hide"
                                    />
                                </Form.Item>
                            </Col>


                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Name(Arabic)"}
                                    name={AddItemsFormField.ItemName_Ar}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={"Item Name(Arabic)"}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Price"}
                                    name={AddItemsFormField.ItemPrice}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        type='number'
                                        placeholder={"Item Price"}
                                        style={{ width: "100%" }}
                                        className="input-number-hide"
                                    />
                                </Form.Item>
                            </Col>


                            <Col span={8}>
                                <Form.Item<AddItemsFormField>
                                    label={"Is Discount"}
                                    name={AddItemsFormField.IsDiscount}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <Select
                                        options={discount}
                                        placeholder={"Is Discount"}
                                    />
                                </Form.Item >

                            </Col>

                            {form.getFieldValue('IsDiscount') === 1 &&
                                <>

                                    <Col span={8}>
                                        <Form.Item<AddItemsFormField>
                                            label={"Offer Type"}
                                            name={AddItemsFormField.OfferType_Fk_Id}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <Select
                                                loading={offerLoading}
                                                showSearch
                                                options={offerType?.map((item: { Text: string; Value: number }) => ({
                                                    label: item.Text,
                                                    value: item.Value,
                                                }))}
                                                filterOption={(input, option) =>
                                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                                }
                                                placeholder={"Offer Type"}
                                            />
                                        </Form.Item >

                                    </Col>


                                    <Col span={8}>
                                        <Form.Item<AddItemsFormField>
                                            label={"Offer"}
                                            name={AddItemsFormField.Offer}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validateMessages.custom.nomsg'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={"Offer"}
                                            />
                                        </Form.Item >

                                    </Col>


                                    <Col span={8}>
                                        <Form.Item<AddItemsFormField>
                                            label={"Offer Price"}
                                            name={AddItemsFormField.OfferPrice}
                                        >
                                            <Input
                                                placeholder={"Offer Price"}
                                                disabled
                                            />
                                        </Form.Item >

                                    </Col>

                                </>
                            }
                            <Col span={12}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Description"}
                                    name={AddItemsFormField.ItemDescription_En}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder={"Item Description"}
                                    />
                                </Form.Item >

                            </Col>


                            <Col span={12}>
                                <Form.Item<AddItemsFormField>
                                    label={"Item Description (Arabic)"}
                                    name={AddItemsFormField.ItemDescription_Ar}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder={"Item Description (Arabic)"}
                                    />
                                </Form.Item >

                            </Col>

                            <Col span={12}>
                                <Form.Item<AddItemsFormField>
                                    label={"Other Information"}
                                    name={AddItemsFormField.OtherInformation_En}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder={"Other Information"}
                                    />
                                </Form.Item >

                            </Col>


                            <Col span={12}>
                                <Form.Item<AddItemsFormField>
                                    label={"Other Information (Arabic)"}
                                    name={AddItemsFormField.OtherInformation_Ar}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder={"Other Information (Arabic)"}
                                    />
                                </Form.Item >

                            </Col>

                        </Row>
                    </Form>
                </>
            </Card>
        </div >
    )
}

export default ItemsInfoTab

