import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { NotificationFilterFormField, NotificationFilterFormPaginated, NotificationFilterFormValues } from '../components/filter-form/notification-list-filter/types';
import { NotificationListTable } from '../components/notification-list-table/notification-list-table';
import { NotificationListTopBar } from '../components/tob-bar/notification-list-topbar';
import { useGetNotificationsLists } from '../use-cases';

const getInitialFilterValues: NotificationFilterFormValues = {
  [NotificationFilterFormField.Title]: undefined,
  [NotificationFilterFormField.AppType]: undefined,
  [NotificationFilterFormField.DeviceTypeId]: undefined,
  [NotificationFilterFormField.Notification]: undefined,
  [NotificationFilterFormField.IsActive]: undefined,
};


export const NotificationsListView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<NotificationFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetNotificationsLists((params));


  const handleFiltersChange = (values: NotificationFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <NotificationListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <NotificationListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}
        isFetching={isFetching}

      />
      <Outlet />
    </>
  );
};

