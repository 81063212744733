import { apiClient, ApiClientConfig } from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
import { getUpdatedQueryParams } from 'utils/updateQueryParamDownload';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};



export const OrdersApiService = () => {


  const getOrderLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/orderlist/getorderlist`,
      params,
    });

    return response.data;
  };


  // const getOrderReturnLists = async (
  //   params: GetDayViewStatsParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<any>({
  //     ...callConfig,
  //     endpoint: `/cutomer/getcustomerlist`,
  //     params,
  //   });

  //   return response.data;
  // };



  const getCustomerInfo = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerlist`,
      params,
    });

    return response.data;
  };


  const getCustomersOrder = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerorderlist`,
      params,
    });

    return response.data;
  };

  const getCustomersAddress = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomeraddresslist`,
      params,
    });

    return response.data;
  };

  const getOrderDetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `orderlist/getorderdetails?CurrentPage=1&RecordsPerPage=10&Id=${id}`,
    });

    return response.data;
  };

  const createCancelOrder = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/orderlist/cancelorder`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };




  // const getOrderExcelDownload = async (
  //   params: GetDayViewStatsParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<any>({
  //     ...callConfig,
  //     endpoint: `/orderlist/exporttoexcelorderlist`,
  //     params,
  //   });

  //   return response.data;
  // };




  const getOrderExcelDownload = async (tableParams: { [key: string]: any }) => {
    const queryParams = getUpdatedQueryParams(tableParams);


    const response = await axiosInstance.get(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/orderlist/exporttoexcelorderlist?${queryParams}`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };

  const getOrderPdfDownload = async (tableParams: { [key: string]: any }) => {

    const queryParams = getUpdatedQueryParams(tableParams);

    const response = await axiosInstance.get(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/orderlist/pdforderlist?${queryParams}`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };


  return {
    getOrderLists,
    // getOrderReturnLists,
    getOrderPdfDownload,
    getOrderExcelDownload,
    getCustomerInfo,
    getCustomersAddress,
    getCustomersOrder,
    getOrderDetails,
    createCancelOrder
  };
};
