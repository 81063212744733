import { Button, Form, Input, message, Select, Image } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useUpdateBanner } from 'features/marketing/use-cases/update-banner';
import { useUpdateBenefits } from 'features/marketing/use-cases/update-benefits';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddBenefitsFormField, AddBenefitsFormValues } from './types';




export const AddBenefitsModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-benefits-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddBenefitsFormValues>();

    const { t } = useTranslation();

    const { isLoading, mutate } = useUpdateBenefits();

    const [imageEn, setImageEn] = useState<any>(null);
    const [fileEnName, setFileEnName] = useState<string>("");

    const handleClose = () => {
        onClose();
        form.resetFields();
        setImageEn(null);
        setFileEnName("");
    }


    const getInitialValues = {
        [AddBenefitsFormField.Id]: undefined,
        [AddBenefitsFormField.Flag]: undefined,
        [AddBenefitsFormField.BenefitsType]: undefined,
        [AddBenefitsFormField.Title]: undefined,
        [AddBenefitsFormField.Title_Ar]: undefined,
        [AddBenefitsFormField.Logo]: undefined,
        [AddBenefitsFormField.Description_Ar]: undefined,
        [AddBenefitsFormField.Description_En]: undefined,

    };


    useEffect(() => {
        if (editData) {
            form.setFieldsValue(
                editData
                //     {
                //     [AddBenefitsFormField.Name]: editData?.Season_En,
                //     [AddBenefitsFormField.Name_Ar]: editData?.Season_Ar,
                // }
            );
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formValues: AddBenefitsFormValues) => {
        const formData = new FormData();

        // Append other form values
        Object.keys(formValues).forEach((key) => {
            if (key !== 'Logo') {
                formData.append(key, formValues[key as keyof AddBenefitsFormValues] as string);
            }
        });

        formData.append('Flag', "insert");

        if (imageEn) {
            formData.append('Logo', imageEn);
        }

        if (editData) {
            formData.append('Id', editData.Id);
        }

        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };

    const BenefitsData = [{ Text: "Customer", Value: 1 }, { Text: "Designer", Value: 2 }];


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImageEn(selectedFile);
                setFileEnName(selectedFile.name);

            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImageEn(null);
                setFileEnName("");
            }
        }
    };

    useEffect(() => {
        if (editData?.LogoIcon) {
            const extractedFileName = editData?.LogoIcon.split('/').pop();
            setFileEnName(extractedFileName || 'LogoIcon.jpg');
        }

    }, [editData]);

    const inputRef = useRef<any>();



    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? "Edit Benefits" : "Add Benefits"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddBenefitsFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >

                            <Form.Item<AddBenefitsFormValues>
                                label={"Benefits Type"}
                                name={AddBenefitsFormField.BenefitsType}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    options={BenefitsData?.map((item: { Text: string; Value: number }) => ({
                                        label: item.Text,
                                        value: item.Value,
                                    }))}

                                    filterOption={(input, option) =>
                                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                    }

                                    placeholder={"Select Benefits Type"}
                                />


                            </Form.Item >

                            <Form.Item<AddBenefitsFormValues>
                                label={"Title"}
                                name={AddBenefitsFormField.Title}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >

                                <Input
                                    placeholder={"Title"}
                                />
                            </Form.Item>

                            <Form.Item<AddBenefitsFormValues>
                                label={"Title (Arabic)"}
                                name={AddBenefitsFormField.Title_Ar}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Title (Arabic)"}
                                />
                            </Form.Item>


                            <Form.Item<AddBenefitsFormValues>
                                label={"Logo"}
                                name={AddBenefitsFormField.Logo}

                            >
                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => inputRef.current.click()}>Choose File</Button>}
                                    value={fileEnName}
                                    readOnly
                                />
                                <input
                                    type='file'
                                    // id='fileInput'
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileChange}
                                />

                            </Form.Item>
                            <>
                                <div className='edit_img_show'>
                                    {imageEn ? (
                                        <Image
                                            src={URL.createObjectURL(imageEn)}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Preview"
                                        />
                                    ) : (

                                        editData?.LogoIcon && (
                                            <Image
                                                src={editData.LogoIcon}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Brand Logo"
                                            />
                                        )
                                    )}
                                </div>
                            </>


                            <Form.Item<AddBenefitsFormValues>
                                label={"Description"}
                                name={AddBenefitsFormField.Description_En}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg')
                                    }
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={"Description"}
                                />
                            </Form.Item>

                            <Form.Item<AddBenefitsFormValues>
                                label={"Description (Arabic)"}
                                name={AddBenefitsFormField.Description_Ar}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={"Description (Arabic)"}
                                />
                            </Form.Item>



                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button loading={isLoading} type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

