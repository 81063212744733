import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useSettingDelete } from 'features/system-setting/use-cases/delete-setting';
import { useSettingStatus } from 'features/system-setting/use-cases/update-setting-status';
import { Dispatch, SetStateAction, useState } from 'react';
import { Volunteer } from 'types';

import { AddSystemModal } from '../modal/add-system';
import { SystemFilterFormPaginated } from '../system-list-filter/types';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: SystemFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};
export const SsytemListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
        });
    };

    const { confirm } = Modal;
    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this setting?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });
    };

    const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
        useSettingStatus();



    const { mutate: mutateDelete } =
        useSettingDelete();


    const handleDelete = async (id: number) => {
        mutateDelete({
            Id: id,
            Flag: "SystemSettingDelete",
        });
    };


    const menuItems = (record: any) => [
        {
            key: 'delete',
            label: <Button type="text" icon={<EditOutlined />} onClick={() => handleOpenModal(record)}>
                Edit
            </Button>,
        },
        {
            key: 'delete',
            label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
                Delete
            </Button>,
        },
    ]



    const [showModal, setShowModal] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setEditData(null);
        document.body.style.overflow = 'visible';
    };

    const handleOpenModal = (record: any) => {
        setEditData(record);
        setShowModal(true);
        document.body.style.overflow = 'hidden';
    };

    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${showModal && "show"}`}>
                <AddSystemModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<Volunteer>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList}
                >
                    <Column<any>
                        title={'Field'}
                        dataIndex={'Field'}
                        sorter={(a, b) => {
                            if (!a.Field) return 1; // Sort null or undefined values to the end
                            if (!b.Field) return -1;

                            return a.Field.localeCompare(b.Field);
                        }}
                    />
                    <Column<any>
                        title={'Value'}
                        dataIndex={'Value'}
                        sorter={(a, b) => Number(b.Value) - Number(a.Value)}
                    />
                    <Column<any>
                        title={'Status'}
                        dataIndex={'IsActive'}
                        render={(_, record: any) => (record?.IsActive ? 'Active' : "Inactive")}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}

                    />
                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip>
                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        loading={isLoadingUpdate}
                                        onChange={(IsActive) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "SystemSettingChangeStatus",
                                                Status: IsActive ? 1 : 0,
                                            });
                                            record.IsActive = IsActive;
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
