import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";

import { MarketingApiService } from "../api/marketing-api.service";
import { marketingQueryKeys } from "./query-keys";


export const useUpdateSocialMedia = () => {
    const { message } = App.useApp();
    const queryClient = useQueryClient();

    const { updateSocialMedia } = MarketingApiService();

    return useMutation(
        (payload: any) => {
            return updateSocialMedia(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {

                queryClient.invalidateQueries({
                    queryKey: marketingQueryKeys.lists(),
                });

                if (data?.ResponseStatus === 1) {
                    message.success(data.ResponseMessage);
                } else {
                    message.error(data.ResponseMessage);
                }
            },
        },
    );
};