import { PaginatedRequestParams } from "api";

export enum NotificationFilterFormField {
    AppType = 'AppType',
    Notification = 'notificationOfferTypeId',
    Title = 'title',
    DeviceTypeId = 'deviceTypeId',
    IsActive = 'statusId',
}

export type NotificationFilterFormValues = {
    [NotificationFilterFormField.Title]: number | undefined;
    [NotificationFilterFormField.AppType]: string | undefined;
    [NotificationFilterFormField.DeviceTypeId]: string | undefined;
    [NotificationFilterFormField.Notification]: string | undefined;
    [NotificationFilterFormField.IsActive]: string | undefined;
};

export type NotificationFilterFormPaginated = NotificationFilterFormValues &
    PaginatedRequestParams;