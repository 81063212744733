import { Empty, Skeleton } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetCustomersOrder } from 'features/customers/use-cases/get-order-list';
import { usePaginationParams } from 'hooks';
import moment from 'moment';
import { useEffect } from 'react';
import { Volunteer } from 'types';



const CustomerOrder = ({ id, tabs }: { id: number; tabs: string }) => {
    const getInitialFilterValues: any = {
        CustomerId: id,
    }

    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch } = useGetCustomersOrder((params), {
        retry: false,
    });

    useEffect(() => {
        if (tabs === "3") {
            refetch();
        }
    }, [tabs]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }

    return (
        <div>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.page,
                        defaultPageSize: params.size,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : data?.getCustomerOrderLists}


                >
                    <Column<any>
                        title={'TansactionId'}
                        dataIndex={'TransactionId'}

                    />


                    <Column<any>
                        title={'Order Date'}
                        dataIndex={'OrderDate'}
                        render={(value: any, record: any) => {
                            return (
                                <h1>{record?.OrderDate && moment(record.OrderDate).calendar("dd/mm/yyyy")} </h1>
                            )
                        }}
                    />

                    <Column<any>
                        title={'Selling Price'}
                        dataIndex={'TotalSalesPrice'}
                    />



                    <Column<any>
                        title={'Discount'}
                        dataIndex={'TotalDiscount'}
                    />

                    <Column<any>
                        title={'Delievery Charge'}
                        dataIndex={'DeliveryCharge'}
                    />


                    <Column<any>
                        title={'Amount'}
                        dataIndex={'AmountPaid'}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'StatusText'}
                    />

                    <Column<any>
                        title={'Payment Status'}
                        dataIndex={'PaymentStatus'}
                    />


                    {/* 
                    <Column<any>
                        title={'Status'}
                        render={(value: any, record: any) => {
                            return (
                                <h1>{record.Status === 1 ? "Active" : "Inactive"} </h1>
                            )
                        }}
                    /> */}

                    {/* <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Tooltip>
                                <Switch
                                    defaultChecked={record.Status}
                                    checked={record.Status}
                                // loading={loadingSwitchId == record.Id && isLoadingUpdate}
                                // onChange={(isChecked) => {
                                //     setLoadingSwitchId(record.Id);
                                //     mutateUpdateStatus({
                                //         Id: record.Id,
                                //         flag: "any_SET_STATUS",
                                //         status: isChecked,
                                //     }, { onSettled: resetRecorId });
                                //     record.Status = isChecked;
                                // }}
                                />
                            </Tooltip>

                        )}
                    /> */}
                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default CustomerOrder


