import { EditOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useState } from 'react';

import Envelope from '../../../../assets/icons/email.svg';
import Facebook from '../../../../assets/icons/facebook.svg';
import Googleplus from '../../../../assets/icons/google-plus.svg';
import Instagram from '../../../../assets/icons/instagram.svg';
import Linkdin from '../../../../assets/icons/linkedin.svg';
import Telegram from '../../../../assets/icons/telegram.svg';
import Twitter from '../../../../assets/icons/twitter.svg';
import Youtube from '../../../../assets/icons/youtube.svg';
import { AddSocialMediaModal } from '../models/add-social-media-model/add-social-media-model';

const SocialMediaListTable = ({ data }: { data: any }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleEdit = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddSocialMediaModal onClose={handleCloseModal} editData={data} isOpen={isOpen} />
            </div>


            <Card>

                <div className='edit_link'>
                    <Button icon={<EditOutlined />} onClick={handleEdit}>
                        Edit
                    </Button>
                </div>


                <div className="N_SocialMedia_box mt17 ng-scope">

                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Facebook} alt="" /></div>
                        <div className="social_name">Facebook</div>
                        <div className="social_link">{data?.FacebookUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Twitter} alt="" /> </div>
                        <div className="social_name">Twitter</div>
                        <div className="social_link">{data?.TwitterUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Instagram} alt="" /> </div>
                        <div className="social_name">Instagram</div>
                        <div className="social_link">{data?.InstagramUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Linkdin} alt="" /> </div>
                        <div className="social_name">Linkedin</div>
                        <div className="social_link">{data?.LinkedInUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Youtube} alt="" /> </div>
                        <div className="social_name">Youtube</div>
                        <div className="social_link">{data?.YoutubeUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Googleplus} alt="" /> </div>
                        <div className="social_name">Google Plus</div>
                        <div className="social_link">{data?.GooglePlusUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15"><img src={Envelope} alt="" /></div>
                        <div className="social_name">Envelope</div>
                        <div className="social_link">{data?.EmailUrl}</div>
                    </div>
                    <div className="social_head d-flex">
                        <div className="head mr15 social_img">
                            <img src={Telegram} alt="" />
                        </div>
                        <div className="social_name">Telegram</div>
                        <div className="social_link">{data?.TeligramUrl}</div>
                    </div>
                </div>

            </Card>
        </>
    )
}

export default SocialMediaListTable
