import { PaginatedRequestParams } from "api";

export enum SubscribersFilterFormField {
    Email = 'emailId',
}

export type SubscribersFilterFormValues = {
    [SubscribersFilterFormField.Email]: string | undefined;
};

export type SubscribersFilterFormPaginated = SubscribersFilterFormValues &
    PaginatedRequestParams;