import { PaginatedRequestParams } from "api";

export enum ReturnFilterFormField {
    FromDate = 'FromDate',
    ToDate = 'ToDate',
    Name = 'Name',
    EmailId = 'EmailId',
    MobileNo = 'MobileNo',
    TransactionId = 'TransactionId',
    Brand = 'brand_Fk_Id',
    IsCustomOrder = 'isCustomOrder',
}

export type ReturnFilterFormValues = {
    [ReturnFilterFormField.FromDate]: string | undefined;
    [ReturnFilterFormField.ToDate]: string | undefined;
    [ReturnFilterFormField.Name]: string | undefined;
    [ReturnFilterFormField.EmailId]: string | undefined;
    [ReturnFilterFormField.MobileNo]: string | undefined;
    [ReturnFilterFormField.TransactionId]: string | undefined;
    [ReturnFilterFormField.Brand]: string | undefined;
    [ReturnFilterFormField.IsCustomOrder]: boolean | undefined;

};

export type ReturnFilterFormValuesPaginated = ReturnFilterFormValues &
    PaginatedRequestParams;