import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import { useLogin } from 'features/auth/use-cases';
import { useUserContext } from 'features/users';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { phoneNumberPattern } from 'utils/validations';
import { LoginFormField, LoginFormValues } from './types';

export const LoginForm = () => {
  const { mutate, isLoading, isSuccess } = useLogin();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const { t } = useTranslation();
  const { status: userQueryStatus } = useUserContext();
  const [form] = Form.useForm<LoginFormValues>();
  const isLoadingUserData = isSuccess && userQueryStatus === 'loading';

  const handleLogin = ({
    MemEmailId,
    MemPassword,
    remember,
  }: LoginFormValues) => {
    mutate({ MemEmailId, MemPassword, persistSession: remember });
  };


  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={handleLogin}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      wrapperCol={{ span: 24 }}
      validateTrigger={validateTrigger}
    >
      <Form.Item
        label={t('login.formFields.email.label')}
        name={LoginFormField.Email}
        rules={[
          {
            required: true,
          }
        ]}
      >
        <Input
          type='tel'
          autoComplete='username'
          prefix={<UserOutlined className='input-prefix-icon' />}
          placeholder={t('login.formFields.email.placeholder')}
          size='large'
        />
      </Form.Item>

      <Form.Item
        label={t('login.formFields.password.label')}
        name={LoginFormField.Password}
        rules={[{ required: true, whitespace: true }]}
      >
        <Input.Password
          autoComplete='current-password'
          prefix={<LockOutlined className='input-prefix-icon' />}
          placeholder={t('login.formFields.password.placeholder')}
          size='large'
        />
      </Form.Item>

      <Form.Item
        name={LoginFormField.Remember}
        valuePropName='checked'
        initialValue={true}
      >
        <Checkbox>{t('login.formFields.rememberMe.label')}</Checkbox>
      </Form.Item>

      <Button className='btn'
        type='primary'
        htmlType='submit'
        block
        loading={isLoading || isLoadingUserData}
      >
        {t('login.submit')}
      </Button>


    </Form>
  );
};
