import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { UserFormField } from 'features/users/components/users-filter-form';
import { useTranslation } from 'react-i18next';

type PasswordFormItemsProps = {
  title?: string;
  form: any
};

export const PasswordFormItems = ({ title = '' }: PasswordFormItemsProps) => {
  const { t } = useTranslation();

  // const [passwordStrength, setPasswordStrength] = useState<number>(0);

  // const assessPasswordStrength = (password: string) => {
  //   let strength = 0;
  //   if (password.length >= 6) strength += 1; // Min length check
  //   if (/(?=.*[a-z])/.test(password)) strength += 1; // Lowercase letter
  //   if (/(?=.*[A-Z])/.test(password)) strength += 1; // Uppercase letter
  //   if (/(?=.*\d)/.test(password)) strength += 1; // Digit
  //   if (/(?=.*[@$!%*?&#])/.test(password)) strength += 1; // Special character
  //   return strength;
  // };

  // const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   const strength = assessPasswordStrength(value);
  //   setPasswordStrength(strength);
  // };

  // const getPasswordStrengthColor = (strength: number) => {
  //   switch (strength) {
  //     case 0:
  //     case 1:
  //       return 'red'; // Weak
  //     case 2:
  //     case 3:
  //       return 'orange'; // Medium
  //     case 4:
  //       return 'orange';
  //     case 5:
  //       return 'green'; // Strong
  //     default:
  //       return 'gray';
  //   }
  // };

  return (
    <FormSectionsSeparator title={title}>
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.password.label')}
        name={UserFormField.Password}
        rules={[
          {
            required: true,
            min: 6,
            message: "password must be at least 6 characters long"
          },
          {
            pattern: /(?=.*\d)/,
            message: t('validateMessages.custom.passwordDigit'),
          },
          {
            pattern: /(?=.*[a-z])/,
            message: t('validateMessages.custom.passwordLowercase'),
          },
          {
            pattern: /(?=.*[A-Z])/,
            message: t('validateMessages.custom.passwordUppercase'),
          },
          {
            pattern: /(?=.*[@$!%*?&#])/,
            message: "Passwords must have at least one special character", // Must contain at least one special character
          },
        ]}
      // rules={[
      //   {
      //     required: true,
      //     message: t('validateMessages.custom.nomsg'), // Required validation message
      //   },
      //   {
      //     min: 6,
      //     // message: t('validateMessages.custom.minLength', { min: 6 }), // Minimum length validation
      //   },
      //   {
      //     pattern: /(?=.*[a-z])/,
      //     message: t('validateMessages.custom.passwordLowercase'), // Must contain at least one lowercase letter
      //   },
      //   {
      //     pattern: /(?=.*[A-Z])/,
      //     message: t('validateMessages.custom.passwordUppercase'), // Must contain at least one uppercase letter
      //   },
      //   {
      //     pattern: /(?=.*\d)/,
      //     message: t('validateMessages.custom.passwordDigit'), // Must contain at least one digit
      //   },
      //   {
      //     pattern: /(?=.*[@$!%*?&#])/,
      //     // message: t('validateMessages.custom.passwordSpecialChar'), // Must contain at least one special character
      //   },
      // ]}
      >
        <Input.Password
          placeholder={t('adminUsers.formFields.password.placeholder')}
          prefix={<LockOutlined className='input-prefix-icon' />}
          autoComplete='new-password'
        // onChange={(e) => {
        //   handlePasswordChange(e); // Update strength meter
        //   form.setFieldsValue({ password: e.target.value }); // Update form value
        // }}
        />
        {/* <Progress
          percent={(passwordStrength / 5) * 100}
          showInfo={false}
          strokeColor={getPasswordStrengthColor(passwordStrength)}
        />
        <p>
          {passwordStrength <= 1 && "Weak"}
          {passwordStrength === 2 || passwordStrength === 3 ? "Medium" : ""}
          {passwordStrength >= 4 && "Strong"}
        </p> */}
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.confirmPassword.label')}
        name={UserFormField.ConfirmPassword}
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue(UserFormField.Password) === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                t('validateMessages.custom.confirmPassword'),
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={t('adminUsers.formFields.confirmPassword.placeholder')}
          prefix={<LockOutlined className='input-prefix-icon' />}
        />
      </Form.Item>
    </FormSectionsSeparator>
  );
};
