import { apiClient, ApiClientConfig } from 'api';
import { commonDeleteDTO, updateCommonStatusDTO } from 'features/admin';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};


export type delivery = {
  Field?: string;
  Value?: string;
  Flag?: string;
}

export const DeliveryApiService = () => {
  const baseURL = "/admin"
  const getDeliveryLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `${baseURL}/getdeliverychargeslist`,
      params,
    });

    return response.data;
  };

  const getGovernorateLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dropdown/getdropdownlist`,
      params,
    });

    return response.data;
  };


  const createDelivery = async (
    payload: delivery,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `admin/saveandupdatedeliverycharges`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateCommonStatus = async (
    payload: updateCommonStatusDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `common/commonupdatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const commonDelete = async (
    payload: commonDeleteDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `common/commondelete`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    getDeliveryLists,
    createDelivery,
    getGovernorateLists,
    updateCommonStatus,
    commonDelete
  };
};
