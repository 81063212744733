import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useAddItemsSeasons } from 'features/items/use-cases/add-seasons';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddItemsSeasonFormField, AddItemsSeasonFormValues } from './types';




export const AddItemsSeasonsModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-seasons-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddItemsSeasonFormValues>();

    const { t } = useTranslation();

    const { isLoading, mutate } = useAddItemsSeasons();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [AddItemsSeasonFormField.Id]: undefined,
        [AddItemsSeasonFormField.Name]: undefined,
        [AddItemsSeasonFormField.Name_Ar]: undefined,

    };


    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                [AddItemsSeasonFormField.Name]: editData?.Season_En,
                [AddItemsSeasonFormField.Name_Ar]: editData?.Season_Ar,
            });
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formData: AddItemsSeasonFormValues) => {

        formData.Id = editData?.Id;
        formData.Flag = "insert";
        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };


    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Season' : 'Add Season'}</h4>

                </div>
                <div className='modal_body'>
                    <Form<AddItemsSeasonFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >

                            <Form.Item<AddItemsSeasonFormField>
                                label={"Name"}
                                name={AddItemsSeasonFormField.Name}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >

                                <Input
                                    placeholder={"Category Name"}
                                    prefix={<UserOutlined className='input-prefix-icon' />}
                                />

                            </Form.Item >

                            <Form.Item<AddItemsSeasonFormField>
                                label={"Name (Arabic)"}
                                name={AddItemsSeasonFormField.Name_Ar}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Category Name"}
                                    prefix={<UserOutlined className='input-prefix-icon' />}
                                />
                            </Form.Item>
                        </FormSectionsSeparator>

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

