import { DeleteOutlined } from '@ant-design/icons';
import { Empty, Rate, Skeleton, Switch, Tooltip } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, ContentFilters } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetItemReviewsListById } from 'features/items/use-cases/get-reviews-list-by-id';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Volunteer } from 'types';

import { ItemsReviewsFilterForm } from './items-reviews-list-filter/items-reviews-list-filter-form';
import { ItemsReviewsFilterFormPaginated, ItemsReviewsFilterFormValues } from './items-reviews-list-filter/types';



const ItemReviewsTab = ({ id, tabs }: { id: string; tabs: string }) => {

    const getInitialFilterValues: ItemsReviewsFilterFormValues = {
        CustomerName: undefined,
        Rating: undefined,
        ReviewStatus: undefined,
    };


    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsReviewsFilterFormPaginated>({
            Item_Fk_Id: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });
    const { data, isLoading, refetch } = useGetItemReviewsListById((params));

    const handleFiltersChange = (values: any) => {
        updateParams(values);
        resetPage();
    };

    useEffect(() => {
        if (tabs === "6") {
            refetch();
        }
    }, [tabs]);

    const formId = "transaction-items-form"

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }


    return (
        <div>
            <ContentFilters formId={formId}>
                <ItemsReviewsFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total Item Reviews'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.CurrentPage,
                        defaultPageSize: params.size,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : data?.getItemReviews}
                    className='cstm_table'

                >

                    <Column<any>
                        title={'Customer Name'}
                        dataIndex={'CustomerName'}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    // render={(value: any, record: any) => {
                    //     return (
                    //         <span className='arow_tab' onClick={() => navigateDetails(record)}>
                    //             <RightOutlined />
                    //             <h1>{record.Name}</h1>
                    //         </span>

                    //     )
                    // }}
                    />


                    <Column<any>
                        title={'Rating'}
                        // dataIndex={'Rating'}
                        render={(_, record: any) => (
                            <Tooltip>
                                <Rate disabled defaultValue={record?.Rating} />
                            </Tooltip>

                        )}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'ReviewStatus'}
                    />

                    <Column<any>
                        title={'Action'}
                    // dataIndex={'PaymentStatus'}

                    />

                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default ItemReviewsTab

