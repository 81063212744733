import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { BannerListTable } from '../components/banner-list-table/banner-list-table';
import { BannerFilterFormField, BannerFilterFormPaginated, BannerFilterFormValues } from '../components/filter-form/banner-list-filter/types';
import { BannerListTopBar } from '../components/tob-bar/Banner-list-topbar';
import { useGetBannerLists } from '../use-cases';

const getInitialFilterValues: BannerFilterFormValues = {
  [BannerFilterFormField.BannerType]: undefined,
  [BannerFilterFormField.Name]: undefined,
  [BannerFilterFormField.IsActive]: undefined,
};


export const BannerListView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<BannerFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetBannerLists((params));


  const handleFiltersChange = (values: BannerFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <BannerListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <BannerListTable
        isLoading={isLoading}
        isFetching={isFetching}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

