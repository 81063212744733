import './module.css'

import { Card } from 'antd';
import { Loader } from 'components';
import { useGetDashboardSalesReport } from 'features/dashboard/use-cases/get-dashboard-sales-report';
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';



drilldown(Highcharts);

interface DrilldownData {
    name: string;
    id: string;
    data: Array<{ name: string; y: number; drilldown?: string }> | Array<[string, number]>;
}

interface ChartData {
    name: string;
    y: number;
    drilldown: string;
    jsonYearMonthwiseString: string;
}

const SalesReport = () => {

    const params = {
        Flag: "Sales"
    }


    const { data, isLoading } = useGetDashboardSalesReport((params), {
        retry: false,
    });

    const mainData = data?.Items[0];


    const [options, setOptions] = useState<Highcharts.Options>();

    const apiData: ChartData = {
        name: mainData?.name,
        y: mainData?.y,
        drilldown: mainData?.drilldown,
        jsonYearMonthwiseString: mainData?.jsonYearMonthwiseString,
    };

    useEffect(() => {
        if (apiData.jsonYearMonthwiseString) {


            const parsedData: DrilldownData[] = JSON.parse(apiData.jsonYearMonthwiseString);

            // Set the initial series and drilldown series data
            const mainSeries = {
                name: apiData.name,
                data: [{ name: apiData.name, y: apiData.y, drilldown: apiData.drilldown }],
                type: 'column' as const,
                color: 'rgb(124, 181, 236)',
            };

            const drilldownSeries = parsedData.map((item) => ({
                id: item.id,
                name: item.name,
                data: item.data,
                type: 'column' as const,
                fontSize: '20px',
            }));

            setOptions({
                chart: {
                    type: 'column',
                    zooming: { type: 'xy' },
                    // style: {
                    //     fontSize: '16px',
                    //     fontWeight: 'bold',
                    //     // color: '#333', 
                    // },

                },
                navigation: {
                    buttonOptions: {

                    }
                },

                credits: {
                    enabled: false
                },

                title: {
                    text: 'Yearly Sales with Drilldown', style: {
                        fontSize: '16px',
                    },
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        style: {
                            fontSize: '14px',  // Increase font size for y-axis labels
                            color: '#4a4a4a',  // Set y-axis label color
                        },
                    },
                },
                yAxis: {
                    title: { text: 'Total Sales' },
                    labels: {
                        style: {
                            fontSize: '14px',  // Increase font size for y-axis labels
                            color: '#4a4a4a',  // Set y-axis label color
                        },
                    },
                },
                tooltip: {
                    style: {
                        fontSize: '15px',  // Increase font size for y-axis labels
                        color: '#4a4a4a',  // Set y-axis label color
                    },
                },
                series: [mainSeries],
                drilldown: { series: drilldownSeries },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{y}', // Display the amount on top of each bar
                            style: {
                                fontSize: '16px', // Adjust font size
                                fontWeight: 'bold',
                                // color: '#333', // Set a color that contrasts with the background
                            },
                        },
                    },
                },
            });

        }
    }, [data]);

    if (isLoading) return <Loader />
    
return (
        <>
            <Card title={"Sales History"} style={{ marginBottom: '20px' }}>
                {options ? <HighchartsReact highcharts={Highcharts} options={options} /> : null}
            </Card>
        </>
    )
}

export default SalesReport
