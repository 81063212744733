import { PaginatedRequestParams } from "api";

export enum ItemsPurchasedFilterFormField {
    Customer_Name = "CustomerId",
    ItemName = 'ItemName',
    Price = 'Price',
    Qty = 'Qty',
}

export type ItemsPurchasedFilterFormValues = {
    [ItemsPurchasedFilterFormField.Customer_Name]: string | undefined;
    [ItemsPurchasedFilterFormField.ItemName]: string | undefined;
    [ItemsPurchasedFilterFormField.Price]: string | undefined;
    [ItemsPurchasedFilterFormField.Qty]: string | undefined;

};

export type ItemsPurchasedFilterFormPaginated = ItemsPurchasedFilterFormValues &
    PaginatedRequestParams;