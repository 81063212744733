import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { AllItemsReviewsFilterForm } from '../filter-form/all-items-reviews-list-filter/all-items-reviews-list-filter-form';
import { AllItemsReviewsFilterFormValues } from '../filter-form/all-items-reviews-list-filter/types';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: AllItemsReviewsFilterFormValues;
    handleFilter: (values: AllItemsReviewsFilterFormValues) => void;
    tableParams: any
};

export const AllItemsReviewListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {
    const formId = 'item-review-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>
            {/* 
            <ContentTopBar title={'Items Reviews List'} /> */}
            <ContentFilters formId={formId}>
                <AllItemsReviewsFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
