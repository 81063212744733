import { apiClient, ApiClientConfig } from 'api';
import { axiosInstance } from 'api/axios-instance';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};
export type UpdateBrandsDTO = {
  id?: string;
  BrandName_Ar?: string;
  BrandName_En?: string;
  File?: string;
  IsActive?: string;
  Flag?: string
};

export type addSystemDto = {
  Field?: string;
  Value?: string;
  Flag?: string;
}

export const SystemApiService = () => {
  const getSystemLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/setting/getsystemsettinglist`,
      params,
    });

    return response.data;
  };
  const getBrandsDetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      method: 'GET', // Ensure the method is POST
      endpoint: '/brand/getbrandbyid',
      params: { Id: id }, // Send the ID in the request body
    });

    return response.data;
  };
  const updateBrands = async (formData: FormData) => {
    const baseURL = process.env.REACT_APP_EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/brand/SaveAndUpadeBrand`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };
  const addSystem = async (
    payload: addSystemDto,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/setting/saveandupdatesystemsetting`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    getSystemLists,
    getBrandsDetails,
    updateBrands,
    addSystem
  };
};
