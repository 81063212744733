export enum AddNewsFormField {
  Id = "Id",
  Flag = "Flag",
  Title = 'NewsTitle',
  Title_Ar = 'NewsTitle_Ar',
  Image = 'File_En',
  Image_Ar = 'File_Ar',
  Description = 'NewsDescription',
  DescriptionAr = 'NewsDescription_Ar',
}

export type AddNewsFormValues = {
  [AddNewsFormField.Id]: number | undefined;
  [AddNewsFormField.Flag]: string | undefined;
  [AddNewsFormField.Title]: string | undefined;
  [AddNewsFormField.Title_Ar]: string | undefined;
  [AddNewsFormField.Image]: any | undefined;
  [AddNewsFormField.Image_Ar]: any | undefined;
  [AddNewsFormField.Description]: string | undefined;
  [AddNewsFormField.DescriptionAr]: string | undefined;
};

