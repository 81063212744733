export enum LoginFormField {
  Email = 'MemEmailId',
  //PhoneNumber = 'phone_number',
  Password = 'MemPassword',
  Remember = 'remember',
}

export type LoginFormValues = {
  [LoginFormField.Email]: string;
  [LoginFormField.Password]: string;
  [LoginFormField.Remember]: boolean;
};
