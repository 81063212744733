import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Grid, message } from 'antd';
import { Button, ContentFilters } from 'components';
import { OrdersApiService } from 'features/order-mannagemnet/api/order-api.service';
import { useState } from 'react';

import { OrderListFilterForm } from '../filter-form/order-list-filter/order-list-filter-form';
import { OrderFilterFormValues } from '../filter-form/order-list-filter/types';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: OrderFilterFormValues;
    handleFilter: (values: OrderFilterFormValues) => void;
    tableParams: any
};

export const OrderListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'order-list-filters-form';

    const screens = useBreakpoint();
    const [excelLoading, setExcelLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);


    const handleExport = async () => {
        setExcelLoading(true);
        try {
            const response = await OrdersApiService().getOrderExcelDownload(tableParams);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'order_list.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setExcelLoading(false);
        } catch (error: unknown) {
            const typedError = error as { message: string };
            message.error(typedError.message || 'An unexpected error occurred');
        }
    };


    const handlePdf = async () => {
        setPdfLoading(true);
        try {
            const response = await OrdersApiService().getOrderPdfDownload(tableParams);

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'OrderList.pdf'); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Cleanup the link element and blob URL
            document.body.removeChild(link);
            URL.revokeObjectURL(pdfUrl);
            setPdfLoading(false);
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };



    return (
        <>


            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                    type='primary'
                    onClick={handleExport}
                    loading={excelLoading}
                >
                    Export to Excel
                </Button>

                <Button
                    icon={!screens.xs ? <FilePdfOutlined /> : undefined}
                    type='primary'
                    onClick={handlePdf}
                    loading={pdfLoading}
                >
                    Print PDF
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <OrderListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
