import TabPane from 'antd/es/tabs/TabPane';
import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import DetailsTabPage from '../components/details-tab/details-tab-page';

export const CustomerDetailsView = () => {

  const { id } = useParams();
  if (!id) return null;

  return (
    <div>
      
      <DetailsTabPage id={id} />
      <Outlet />
    </div>
  )
}
