import { PaginatedRequestParams } from "api";

export enum ItemsSeasonsFilterFormField {
    Seasons = 'title',
    Status = 'statusId',
}


export type ItemsSeasonsFilterFormValues = {
    [ItemsSeasonsFilterFormField.Seasons]: string | undefined;
    [ItemsSeasonsFilterFormField.Status]: string | undefined;

};

export type ItemsSeasonsFilterFormPaginated = ItemsSeasonsFilterFormValues &
    PaginatedRequestParams;