import { Button, Card, Form } from 'antd';
import { useUpdateCMS } from 'features/admin/use-cases/update-cms';
import { useEffect, useState } from 'react';

import { AddCMSForm } from './cms-add-form';
import { AddCMSFormField, AddCMSFormValues } from './types';




export const AddCMSModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-cms-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddCMSFormValues>();

    const { isLoading, mutate } = useUpdateCMS();


    const handleClose = () => {
        // setEdit(true);
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [AddCMSFormField.Id]: undefined,
        [AddCMSFormField.Flag]: undefined,
        [AddCMSFormField.Title]: undefined,
        [AddCMSFormField.Title_Ar]: undefined,
        [AddCMSFormField.DescriptionEn]: undefined,
        [AddCMSFormField.DescriptionAr]: undefined,
        [AddCMSFormField.CMSTypeFkId]: undefined,
        [AddCMSFormField.AppType]: undefined,
    }

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(
                {
                    ...editData,
                    // [AddCMSFormField.Title]: editData?.Type_En,
                    // [AddCMSFormField.DescriptionAr]: editData?.Description_Ar,
                }
            );
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formData: AddCMSFormValues) => {

        formData.Id = editData?.Id;
        formData.AppType = 1;
        formData.Flag = "Insert";

        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };


    // const handleEdit = () => {
    //     setEdit(true);
    // };


    // const [edit, setEdit] = useState<boolean>(false);

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    {/* <h4>{editData ? "Edit CMS" : "Add CMS"}</h4> */}
                </div>
                <div className='modal_body'>

                    <Card className='prfle_crd' title={editData ? "Edit CMS" : "Add CMS"}
                    // extra={
                    //     <>{!edit &&

                    //         <Button
                    //             type="primary"
                    //             onClick={handleEdit}
                    //         >
                    //             Edit
                    //         </Button>
                    //     }

                    //     </>
                    // }
                    >

                        <Form<AddCMSFormValues>
                            id={formName}
                            name={formName}
                            form={form}
                            layout='vertical'
                            initialValues={getInitialValues}
                            onFinish={handleUpdate}
                            className='info_filter'
                            // requiredMark={'optional'}
                            onFinishFailed={() => {
                                setValidateTrigger(['onChange']);
                            }}
                            validateTrigger={validateTrigger}
                            disabled={isLoading}
                        >
                            <AddCMSForm />


                            {<div className='form-button'>
                                <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }}>Save</Button>
                                <Button onClick={onReset} danger type="primary"> Cancel</Button>
                            </div>}
                        </Form>
                    </Card>
                </div>
            </div >

        </>
    );
};

