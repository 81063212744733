
import { Spin } from 'antd';
// import smalllogo from 'assets/logo2.png';
import { useTranslation } from 'react-i18next';

import { StyledWrapper } from './full-screen-loader.styles';

export const FullScreenLoader = () => {
  const { t } = useTranslation();

  return (
    <StyledWrapper align='center'>

      <Spin
        size='large'
        tip={t('common.loading')}
        aria-label={t('common.loading')}
      />
    </StyledWrapper>
  );
};
