import { PaginatedRequestParams } from "api";

export enum PromotionFilterFormField {
    Name = 'name',
    Brand = 'brandId',
    Group = 'groupId',
    SubCategory = 'categoryId',
    OfferType = 'offerId',
}

export type PromotionFilterFormValues = {
    [PromotionFilterFormField.Name]: string | undefined;
    [PromotionFilterFormField.Brand]: number | undefined;
    [PromotionFilterFormField.Group]: number | undefined;
    [PromotionFilterFormField.SubCategory]: string | undefined;
    [PromotionFilterFormField.OfferType]: string | undefined;
};

export type PromotionFilterFormPaginated = PromotionFilterFormValues &
    PaginatedRequestParams;