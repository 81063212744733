import { PaginatedRequestParams } from "api";

export enum CMSFilterFormField {
    Cms_Type = 'CMSType',
}

export type CMSFilterFormValues = {
    [CMSFilterFormField.Cms_Type]: string | undefined;

};

export type CMSFilterFormValuesPaginated = CMSFilterFormValues &
    PaginatedRequestParams;