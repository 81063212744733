import { PaginatedRequestParams } from "api";

export enum AllItemsReviewsFilterFormField {
    CustomerName = 'CustomerName',
    ItemName_En = "itemName",
    Rating = 'rating',
    ReviewStatus = 'reviewStatus_Fk_Id',
}

export type AllItemsReviewsFilterFormValues = {
    [AllItemsReviewsFilterFormField.CustomerName]: string | undefined;
    [AllItemsReviewsFilterFormField.Rating]: string | undefined;
    [AllItemsReviewsFilterFormField.ItemName_En]: string | undefined;
    [AllItemsReviewsFilterFormField.ReviewStatus]: string | undefined;

};

export type AllItemsReviewsFilterFormPaginated = AllItemsReviewsFilterFormValues &
    PaginatedRequestParams;