import { PaginatedRequestParams } from 'api';
import { Gender as GenderType } from 'types';

export enum UserFormField {
  Id = 'id',
  Date = 'date',
  Company = 'company_id',
  Role = 'role',
  FirstName = 'first_name',
  LastName = 'last_name',
  Gender = 'gender',
  Email = 'email',
  PhoneNumber = 'phone_number',
  Password = 'password',
  ConfirmPassword = 'confirm_password',
  Address = 'address',
  IsActive = 'is_active',
}

export type UserFormValues = {
  [UserFormField.Id]?: string | undefined;
  [UserFormField.Date]: string;
  [UserFormField.Company]: string;
  [UserFormField.Role]: string;
  [UserFormField.FirstName]: string;
  [UserFormField.LastName]: string;
  [UserFormField.Gender]: GenderType | null;
  [UserFormField.Email]: string;
  [UserFormField.PhoneNumber]: string;
  [UserFormField.Password]: string;
  [UserFormField.ConfirmPassword]: string;
  [UserFormField.Address]: string;
  [UserFormField.IsActive]: boolean;
};

export enum UserFilterFormField {
  UserId = 'UserId',
  Name = 'Name',
  EmailId = 'EmailId',
  MobileNo = 'MobileNo',
  Status = 'Status',
}


export type UserFilterFormValues = {
  [UserFilterFormField.UserId]: string | undefined;
  [UserFilterFormField.Name]: string | undefined;
  [UserFilterFormField.EmailId]: string | undefined;
  [UserFilterFormField.MobileNo]: number | undefined;
  [UserFilterFormField.Status]: number | undefined;
};

export type UserFilterFormValuesPaginated = UserFilterFormValues &
  PaginatedRequestParams;


export enum AddUserFormField {
  MemberId = 'MemberId',
  Flag = 'Flag',
  MemFirstName = 'MemFirstName',
  MemLastName = 'MemLastName',
  MemUserID = 'MemUserID',
  MemPassword = 'MemPassword',
  MemEmailId = 'MemEmailId',
  MemMobileNo = 'MemMobileNo',
  MemStatus_FK_Id = 'MemStatus_FK_Id',
  MemIdPreFix_DbUses = "MemIdPreFix_DbUses",
  MemAutoGeneratedId = "MemAutoGeneratedId"
}

export type AddUserFormValues = {
  [AddUserFormField.MemberId]: number;
  [AddUserFormField.Flag]: string;
  [AddUserFormField.MemFirstName]: string;
  [AddUserFormField.MemLastName]: string;
  [AddUserFormField.MemUserID]: string;
  [AddUserFormField.MemPassword]: string;
  [AddUserFormField.MemEmailId]: string;
  [AddUserFormField.MemMobileNo]: string;
  [AddUserFormField.MemStatus_FK_Id]: string;
  [AddUserFormField.MemIdPreFix_DbUses]: string;
  [AddUserFormField.MemAutoGeneratedId]: boolean;
}