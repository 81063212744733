import { Col, Row } from "antd";

import CustomerPieChart from "./customer";
import CustomerNumberChart from "./customer-number-data";



export default function CustomerTwoChart({ data }: { data: any }) {

    return (
        <Row gutter={24}>
            <Col span={12}>
                <CustomerNumberChart data={data} />
            </Col>

            <Col span={12}>
                <CustomerPieChart />
            </Col>

        </Row>
    )
}
