import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { MarketingApiService } from '../api/marketing-api.service';
import { marketingQueryKeys } from './query-keys';

const { updatePromotions } = MarketingApiService();


export const useUpdatePromotions = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return updatePromotions(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {
                queryClient.invalidateQueries({
                    queryKey: marketingQueryKeys.all,
                });
                if (data?.ResponseStatus === 1) {
                    message.success(data.ResponseMessage);
                } else {
                    message.error(data.ResponseMessage);
                }
            },
        },
    );
};