import { PaginatedRequestParams } from "api";

export enum CMSTypeFilterFormField {
    Name = 'Name',
}

export type CMSTypeFilterFormValues = {
    [CMSTypeFilterFormField.Name]: string | undefined;
};

export type CMSTypeFilterFormValuesPaginated = CMSTypeFilterFormValues &
    PaginatedRequestParams;