import { PaginatedRequestParams } from "api";

export enum NewsFilterFormField {
    Title = 'title',
    IsActive = 'statusId',
}

export type NewsFilterFormValues = {
    [NewsFilterFormField.Title]: number | undefined;
    [NewsFilterFormField.IsActive]: string | undefined;
};

export type NewsFilterFormPaginated = NewsFilterFormValues &
    PaginatedRequestParams;