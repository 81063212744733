import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { CustomersApiService } from '../api/customers-api.service';
import { customersQueryKeys } from './query-keys';



const { getCustomersLists } = CustomersApiService();

const getCustomersListQuery = (
  params: any,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<any>;
  options?: UseQueryOptions<any>;
} => ({
  queryKey: customersQueryKeys.list(params),
  queryFn: ({ signal }) => getCustomersLists(params, { config: { signal } }),
  ...options,
});

export const useGetCustomersLists = (
  params: any,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getCustomersListQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};



