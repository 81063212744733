import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Skeleton, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
// import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useMarketingDelete } from 'features/marketing/use-cases/delete-common-marketing';
import { useMarketingCommonStatus } from 'features/marketing/use-cases/update-common-marketing-status';
import { Dispatch, SetStateAction, useState } from 'react';

import { NotificationFilterFormPaginated } from '../filter-form/notification-list-filter/types';
import { AddNotificationModal } from '../models/add-notification-model/add-notification-model';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: NotificationFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
    isFetching: boolean
};

export const NotificationListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    isFetching,
    updateParams,
}: UsersTableProps) => {


    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
        });
    };


    const { confirm } = Modal;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const showDeleteConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to delete this Item ?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });
    };

    const { mutate: mutateUpdateStatus } =
        useMarketingCommonStatus();

    const { mutate: mutateDelete } =
        useMarketingDelete();


    const handleDelete = async (id: number) => {
        mutateDelete({
            Id: id,
            Flag: "NotificationDelete",
        });
    };



    const menuItems = (record: any) => [
        {
            key: 'edit',
            label: <Button type="text" onClick={() => handleEdit(record)} icon={<EditOutlined />} > Edit</Button>,
        },
        {
            key: 'delete',
            label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
                Delete
            </Button>,
        },
    ]


    const handleCloseModal = () => {
        setIsOpen(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    const handleEdit = (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }



    return (
        <div className='order_rtn'>
            <div className={`cstmrightmdl  ${isOpen && "show"}`}>
                <AddNotificationModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isFetching}
                    dataSource={isLoading ? [] : itemList?.Items}

                >

                    <Column<any>
                        title={'App Type'}
                        dataIndex={'AppType'}
                        sorter={(a, b) => {
                            if (!a.AppType) return 1; // Sort null or undefined values to the end
                            if (!b.AppType) return -1;

                            return a.AppType - b.AppType;
                        }}
                        render={(value: any, record: any) => {
                            return (
                                <h1 >
                                    {record?.AppType === 1 ? "Customer" : record?.AppType === 2 ? "Vendor" : ""}
                                </h1>

                            )
                        }}
                    />


                    <Column<any>
                        title={'Title(English)'}
                        dataIndex={'Title_En'}
                        sorter={(a, b) => a.Title_En && a.Title_En.localeCompare(b.Title_En)}
                    />

                    <Column<any>
                        title={'Title(Arabic)'}
                        dataIndex={'Title_Ar'}
                        sorter={(a, b) => a.Title_Ar.localeCompare(b.Title_Ar)}
                    />

                    <Column<any>
                        title={'Description(English)'}
                        dataIndex={'Description_En'}
                        sorter={(a, b) => a.Description_En.localeCompare(b.Description_En)}
                        ellipsis
                    />

                    <Column<any>
                        title={'Description(Arabic)'}
                        dataIndex={'Description_Ar'}
                        sorter={(a, b) => a.Description_Ar.localeCompare(b.Description_Ar)}
                        ellipsis
                    />

                    <Column<any>
                        title={'Device For'}
                        dataIndex={'DeviceTypeName'}
                        sorter={(a, b) => {
                            if (!a.DeviceTypeName) return 1; // Sort null or undefined values to the end
                            if (!b.DeviceTypeName) return -1;

                            return a.DeviceTypeName.localeCompare(b.DeviceTypeName);
                        }}
                    />


                    <Column<any>
                        title={'Notification For'}
                        dataIndex={'NotificationOfferTypeName'}
                        sorter={(a, b) => {
                            if (!a.NotificationOfferTypeName) return 1; // Sort null or undefined values to the end
                            if (!b.NotificationOfferTypeName) return -1;

                            return a.NotificationOfferTypeName.localeCompare(b.NotificationOfferTypeName);
                        }}
                    />

                    <Column<any>
                        title={'Status'}
                        sorter={(a, b) => Number(b.StatusId) - Number(a.StatusId)}
                        render={(value: any, record: any) => {
                            return (
                                <h1 >
                                    {record?.StatusId === 1 ? "Active" : "InActive"}
                                </h1>

                            )
                        }}

                    />


                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>
                                <Tooltip>
                                    <Switch
                                        defaultChecked={record.StatusId}
                                        checked={record.StatusId}
                                        onChange={(StatusId: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "NotificationChangeStatus",
                                                Status: StatusId ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.StatusId = StatusId; // Ensure it's an integer
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
