import { Button, Dropdown, Empty, Skeleton, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { Dispatch, SetStateAction, useState } from 'react';

import { ItemsPurchasedFilterFormPaginated } from '../filter-form/items-purchased-filter/types';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsPurchasedFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsPurchasedTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    return (
        <div className='order_rtn'>

            < StyledTableWrapper >
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                < Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.Items}

                >
                    <Column<any>
                        title={'Customer Name'}
                        dataIndex={'CustomerName'}
                        sorter={(a, b) => {
                            if (!a.CustomerName) return 1; // Sort null or undefined values to the end
                            if (!b.CustomerName) return -1;

                            return a.CustomerName.localeCompare(b.CustomerName);
                        }}

                    />

                    <Column<any>
                        title={'Item Name'}
                        dataIndex={'ItemName_En'}
                        sorter={(a, b) => {
                            if (!a.ItemName_En) return 1; // Sort null or undefined values to the end
                            if (!b.ItemName_En) return -1;

                            return a.ItemName_En.localeCompare(b.ItemName_En);
                        }}
                    />

                    <Column<any>
                        title={'Price'}
                        dataIndex={'Price'}
                        sorter={(a, b) => {
                            if (!a.Price) return 1; // Sort null or undefined values to the end
                            if (!b.Price) return -1;

                            return a.Price - b.Price;
                        }}

                    />

                    <Column<any>
                        title={'Quantity'}
                        dataIndex={'Qty'}
                        sorter={(a, b) => {
                            if (!a.Qty) return 1; // Sort null or undefined values to the end
                            if (!b.Qty) return -1;

                            return a.Qty - b.Qty;
                        }}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
