import {
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Dropdown, Grid, Space } from 'antd';
import { Avatar } from 'components';
import { useSessionContext } from 'features/auth';
import { useLogoutService } from 'features/auth/use-cases/logout';
import { useUserContext } from 'features/users';
import { ChangePasswordModal } from 'features/users/components/modals/change-password';
import { useSideBar } from 'providers/side-bar-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/logo.png';
import {
  StyledDropdownIcon,
  StyledHeader,
  StyledLogoWrapper,
  StyledUserInfo,
  StyledUserMenuButton,
} from './header.styles';

const { useBreakpoint } = Grid;

export const Header = () => {
  const { collapsed, toggleCollapsed } = useSideBar();
  const { logOut } = useSessionContext();
  const { user } = useUserContext();
  const { t } = useTranslation();

  const screens = useBreakpoint();
  const avatarSize = 24;


  const handleLogout = () => {
    const formData = {
      MemId: user?.UserDetails?.MemId
    }

    mutate(formData, { onSuccess: () => logOut() })
  }

  const { mutate } = useLogoutService()
  const [changePassModel, setChangePassModel] = useState<boolean>(false);

  const handleClosePassword = () => {
    setChangePassModel(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenPassword = () => {
    setChangePassModel(true);
    document.body.style.overflow = 'hidden';
  }

  if (!user) return null;

  return (
    <>
      {changePassModel ? (
        <div className={`cstmrightmdl ${changePassModel && "show"}`}>
          <ChangePasswordModal id={user?.UserDetails.MemId} onClose={handleClosePassword} onSuccess={handleClosePassword} />
        </div>
      ) : ''}

      <StyledHeader className='app-header'>
        <Space
          role='button'
          aria-controls='sideBar'
          aria-expanded={!collapsed}
          onClick={toggleCollapsed}
          className='app-header-logo-wrapper'
          aria-label={t('sideBar.toggle')}
        >
          <MenuOutlined className='app-header-hamburger-mobile' />
          <StyledLogoWrapper className='logo'>
            {/* <LogoSvg title={t('li3ibLogo')} height={40} /> */}
            <img src={logo} height={"39px"} />
          </StyledLogoWrapper>
        </Space>

        <Space className='app-header-user-wrapper'>
          <Dropdown
            menu={{
              items: [
                {
                  key: 'changePassword',
                  label: "Change Password",
                  icon: <LockOutlined />,
                  onClick: handleOpenPassword,
                },
                {
                  key: 'logout',
                  label: t('userMenu.logOut'),
                  icon: <LogoutOutlined />,
                  onClick: handleLogout,
                },
              ],
            }}
            trigger={['click']}
            // required for keyboard accessibility, see: https://github.com/ant-design/ant-design/issues/21357
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <StyledUserMenuButton
              className='app-header-user'
              ghost
              $avatarSize={avatarSize}
              aria-label={t('userMenu.toggle')}
            >
              <Avatar size={avatarSize} className='app-header-avatar' />

              <Space className='app-header-space'>
                <StyledUserInfo ellipsis={{ tooltip: true }}>
                  {user?.UserDetails?.MemfullName}
                </StyledUserInfo>
                <StyledUserInfo
                  className='app-header-user-role'
                  ellipsis={{ tooltip: true }}
                >
                  {user?.UserDetails?.RoleName}
                </StyledUserInfo>
              </Space>



              {screens.lg ? (
                <StyledDropdownIcon style={{ marginLeft: '15px' }} />
              ) : null}
            </StyledUserMenuButton>
          </Dropdown>
        </Space>
      </StyledHeader>
    </>
  );
};
