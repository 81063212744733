import { Col, Row } from "antd";

import CustomerStatusPieChart from "./customer-status";
import EquityPieChart from "./equity-history";
import OrderHistoryPieChart from "./order-history";



export default function ThreePieChart() {

    return (
        <div className="threecol_rw">
            <div className="threecol">
                <OrderHistoryPieChart />
            </div>

            <div className="threecol">
                <EquityPieChart />
            </div>

            <div className="threecol"> 
                <CustomerStatusPieChart />
            </div>
        </div>
    )
}
