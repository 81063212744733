import { Card, Row } from 'antd';
// import Background from 'assets/main_login.png';
import styled from 'styled-components';



export const StyledBackground = styled(Row)`  
background:#FFFDFA
`;

export const StyledCard = styled(Card)`
  box-shadow: 2px 2px 2px 2px grey;
`;

export const StyledRow = styled(Row)``;
