import { Button, Form } from 'antd';
import { UserInfoFormItems } from 'features/users/components/modals/user-form-item';
import { useAddUser } from 'features/users/use-cases/add-user';
import { useEffect, useState } from 'react';

import { AddUserFormField, AddUserFormValues } from '../users-filter-form';



export const AddUserModal = ({ onClose, userEditdata }: { onClose: () => void, userEditdata: any }) => {
    const formName = 'add-user-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddUserFormValues>();

    const { isLoading, mutate } = useAddUser();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [AddUserFormField.MemberId]: undefined,
        [AddUserFormField.Flag]: undefined,
        [AddUserFormField.MemFirstName]: undefined,
        [AddUserFormField.MemLastName]: undefined,
        [AddUserFormField.MemUserID]: undefined,
        [AddUserFormField.MemPassword]: undefined,
        [AddUserFormField.MemEmailId]: undefined,
        [AddUserFormField.MemMobileNo]: undefined,
        [AddUserFormField.MemStatus_FK_Id]: undefined,
        [AddUserFormField.MemIdPreFix_DbUses]: undefined,
        [AddUserFormField.MemAutoGeneratedId]: undefined,
    };


    useEffect(() => {
        if (userEditdata) {
            form.setFieldsValue(userEditdata);
        } else {
            form.resetFields();
        }

    }, [userEditdata, form]);

    const handleUpdate = (formData: AddUserFormValues) => {

        // formData.MemberId = userEditdata ? "";
        formData.Flag = userEditdata ? "update" : "Insert";
        formData.MemberId = userEditdata?.MemID;
        formData.MemIdPreFix_DbUses = formData.MemUserID;
        formData.MemAutoGeneratedId = false;

        mutate(formData, { onSuccess: handleClose });

        // if (userEditdata) {
        //     formData.Id = userEditdata.Id;
        //     formData.EventId = userEditdata.EventId;
        //     formData.Password = '';
        //     // update_mutate(formData, { onSuccess: handleClose });
        // } else {
        //     formData.Id = 0;
        //     // mutate(formData, { onSuccess: handleClose });
        // }

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{userEditdata ? 'Edit User' : 'Add User'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddUserFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                        onReset={onReset}
                    >
                        <UserInfoFormItems userEditdata={userEditdata} />

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

