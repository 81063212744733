import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

import { OrderFilterFormField, OrderFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: OrderFilterFormValues) => void;
    initialValues: OrderFilterFormValues;
    closeModal?: () => void;
};

export const OrderListFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<OrderFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const { data: paymentTypeData } = useGetDropdownLists({ Flag: "GetPaymentMethodList" });
    const { data: statusData } = useGetDropdownLists({ Flag: "GetOrderStatusList" });

    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: OrderFilterFormValues) => {
        const formattedValues = {
            ...formValues,
            [OrderFilterFormField.FromDate]: formValues?.fromDate && payFormatDate(formValues?.fromDate),
            [OrderFilterFormField.ToDate]: formValues?.toDate && payFormatDate(formValues?.toDate),
        };
        handleFilter(formattedValues);
        closeModal?.();
    };


    const invoiceData = [
        { label: 'Invoice', value: 1 },
        { label: 'Sales Return', value: 2 },
    ];


    const PaymentStatusList = [
        { Text: "CAPTURED", Value: "CAPTURED" },
        { Text: "NOT CAPTURED", Value: "NOT CAPTURED" },
        { Text: "INCOMPLETE", Value: "INCOMPLETE" },
        { Text: "VOIDED", Value: "VOIDED" },
        { Text: "CANCELED", Value: "CANCELED" },
        { Text: "DENIED BY RISK", Value: "DENIED BY RISK" },
        { Text: "HOST TIMEOUT", Value: "HOST TIMEOUT" },
        { Text: "PENDING", Value: "PENDING" },

    ];

    return (
        <Form<OrderFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>


                        <Form.Item<OrderFilterFormValues>
                            label={'From Date'}
                            name={OrderFilterFormField.FromDate}
                        >
                            <DatePicker format="DD-MM-YYYY" placeholder='From Date' />

                        </Form.Item>


                        <Form.Item<OrderFilterFormValues>
                            label={'To Date'}
                            name={OrderFilterFormField.ToDate}
                        >
                            <DatePicker format="DD-MM-YYYY" placeholder='To Date' />

                        </Form.Item>


                        <Form.Item<OrderFilterFormValues>
                            label={'Name'}
                            name={OrderFilterFormField.Name}
                        >
                            <Input
                                placeholder={"Customer Name"}
                            />
                        </Form.Item>


                        <Form.Item<OrderFilterFormValues>
                            label={'Email'}
                            name={OrderFilterFormField.EmailId}
                        >
                            <Input placeholder={t('adminUsers.formFields.email.placeholder')} />
                        </Form.Item>

                        <Form.Item<OrderFilterFormValues>
                            label={'Mobile No'}
                            name={OrderFilterFormField.MobileNo}
                        >
                            <Input
                                placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
                            />
                        </Form.Item>

                        <Form.Item<OrderFilterFormValues>
                            label={'Transaction Id'}
                            name={OrderFilterFormField.TransactionId}
                        >
                            <Input
                                placeholder={"Transaction Id"}
                            />
                        </Form.Item>

                        <Form.Item<OrderFilterFormValues>
                            label={"Payment Type"}
                            name={OrderFilterFormField.PaymentType}
                        >
                            <Select
                                showSearch
                                options={paymentTypeData?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"payment Type"}
                            />
                        </Form.Item>


                        <Form.Item<OrderFilterFormValues>
                            label={"Invoice Type"}
                            name={OrderFilterFormField.InvoiceType}
                        >
                            <Select
                                showSearch
                                options={invoiceData}

                                placeholder={"Invoice Type"}
                            />
                        </Form.Item>

                        <Form.Item<OrderFilterFormValues>
                            label={"Payment Status"}
                            name={OrderFilterFormField.PaymentStatus}
                        >
                            <Select
                                showSearch
                                options={PaymentStatusList?.map((item: { Text: string; Value: string }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Payment Status"}
                            />
                        </Form.Item>


                        <Form.Item<OrderFilterFormValues>
                            label={"Status"}
                            name={OrderFilterFormField.Status}
                        >
                            <Select
                                showSearch
                                options={statusData?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Status"}
                            />
                        </Form.Item>

                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn onsmsc'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>
        </Form>
    );
};
