import { Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { AddNotificationFormField, AddNotificationFormValues } from './types';



export const AddNotificationForm = () => {

    const { t } = useTranslation();

    const { data: deviceType, isLoading: deviceLoad } = useGetDropdownLists({ Flag: "GetDeviceType" });
    const { data: notification, isLoading: notificationLoad } = useGetDropdownLists({ Flag: "GetNotificationOfferType" });


    const AppTypeList = [
        { Text: "Customer", Value: 1 },
        { Text: "Vendor", Value: 2 }

    ];

    return (
        <FormSectionsSeparator title={""} >
            <Row gutter={8}>
                <Col span={8}>
                    <Form.Item<AddNotificationFormValues>
                        label={"App Type"}
                        name={AddNotificationFormField.AppType}
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            options={AppTypeList?.map((item: { Text: string; Value: number }) => ({
                                label: item.Text,
                                value: item.Value,
                            }))}

                            filterOption={(input, option) =>
                                typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                            }

                            placeholder={"Select Banner Type"}
                        />


                    </Form.Item >
                </Col>
                <Col span={8}>
                    <Form.Item<AddNotificationFormValues>
                        label={"Device Type"}
                        name={AddNotificationFormField.DeviceType}
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Select
                            loading={deviceLoad}
                            showSearch
                            options={deviceType?.map((item: { Text: string; Value: number }) => ({
                                label: item.Text,
                                value: item.Value,
                            }))}

                            filterOption={(input, option) =>
                                typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                            }

                            placeholder={"Select Device Type"}
                        />


                    </Form.Item >
                </Col>
                <Col span={8}>
                    <Form.Item<AddNotificationFormValues>
                        label={"Notification Type"}
                        name={AddNotificationFormField.NotificationType}
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Select
                            loading={notificationLoad}
                            showSearch
                            options={notification?.map((item: { Text: string; Value: number }) => ({
                                label: item.Text,
                                value: item.Value,
                            }))}

                            filterOption={(input, option) =>
                                typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                            }

                            placeholder={"Select Notification Type"}
                        />


                    </Form.Item >
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item<AddNotificationFormValues>
                        label={"Title En"}
                        name={AddNotificationFormField.Title}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >

                        <Input
                            placeholder={"Title"}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item<AddNotificationFormValues>
                        label={"Title Ar"}
                        name={AddNotificationFormField.Title_Ar}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >

                        <Input
                            placeholder={"Title Ar"}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {/* <Row gutter={8}>
                <Col span={12}> */}
            <Form.Item<AddNotificationFormValues>
                label={"Description En"}
                name={AddNotificationFormField.DescriptionEn}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <TextArea
                    placeholder={"Title En"}
                    rows={4}
                />
            </Form.Item>

            <Form.Item<AddNotificationFormValues>
                label={"Description (Arabic)"}
                name={AddNotificationFormField.DescriptionAr}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <TextArea
                    placeholder={"Description (Arabic)"}
                    rows={4}
                />
            </Form.Item>
            {/* </Col>
            </Row> */}

        </FormSectionsSeparator >
    );
};
