import { PaginatedRequestParams } from "api";

export enum FaqFilterFormField {
    Question = 'question',
}

export type FaqFilterFormValues = {
    [FaqFilterFormField.Question]: string | undefined;
};

export type FaqFilterFormPaginated = FaqFilterFormValues &
    PaginatedRequestParams;