
export enum EditCMSTypeFormField {
  Id = "Id",
  Flag = "Flag",
  TypeEn = "TypeEn",
  TypeAr = "TypeAr",
}

export type EditCMSTypeFormValues = {
  [EditCMSTypeFormField.Id]: number;
  [EditCMSTypeFormField.Flag]: string;
  [EditCMSTypeFormField.TypeEn]: string;
  [EditCMSTypeFormField.TypeAr]: string;

};
