import { Grid } from 'antd';
import { ContentFilters } from 'components';
import { useState } from 'react';

import { ItemsSizeTypeFilterForm } from '../filter-form/items-size-type-filter/items-size-type-list-filter';
import { ItemsSizeTypeFilterFormValues } from '../filter-form/items-size-type-filter/types';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsSizeTypeFilterFormValues;
    handleFilter: (values: ItemsSizeTypeFilterFormValues) => void;
};

export const ItemsSizeTypeListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'size-type-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>

            {/* <div className='head_btn'>
                <Button
                    type='primary'
                // onClick={handleExcelDownload}
                >
                    Add Size Type
                </Button>
            </div> */}


            <ContentFilters formId={formId}>
                <ItemsSizeTypeFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
