import { Outlet, useParams } from 'react-router-dom'

import ItemsDetailsTabPage from '../components/items-details-tab/items-details-tab-page';


export const ItemsDetailsView = () => {

  const { id } = useParams();
  if (!id) return null;

  return (
    <div>
      <ItemsDetailsTabPage id={id} />
      <Outlet />
    </div>
  )
}
