import dayjs, { ManipulateType } from 'dayjs';
import {
  Booking,
  PaymentMethod,
  PaymentStatus,
  Role,
  Source,
  User,
} from 'types';

export const USER_ROLES = {
  li3ibRoles: [
    Role.SuperAdmin,
    Role.EFashionAdmin,
    Role.EFashionAgent
  ],
  walletRoles: [Role.SuperAdmin, Role.EFashionAdmin, Role.EFashionAgent],
  companyViewRoles: [Role.SuperAdmin],

  clientRoles: [Role.EFashionAdmin, Role.SuperAdmin, Role.EFashionAgent],
  adminPortalRoles: [
    Role.SuperAdmin,
    Role.EFashionAdmin,
    Role.EFashionAgent
  ],
  all: Object.values(Role),
};

export const hasLi3ibRole = (user: User) =>
  USER_ROLES.li3ibRoles.includes(user.role);

export const hasWalletRole = (user: User) =>
  USER_ROLES.walletRoles.includes(user.role);

export const hasCompanyViewRole = (user: User) =>
  USER_ROLES.companyViewRoles.includes(user.role);

export const hasClientRole = (user: User) =>
  USER_ROLES.clientRoles.includes(user.role);

export const allAvailableRolesExceptUser = (user: User) => {
  if (user.role === Role.EFashionAdmin) {
    return USER_ROLES.all.filter(
      (role) => ![Role.EFashionAdmin, Role.SuperAdmin, Role.EFashionAgent].includes(role),
    );
  }

  return USER_ROLES.all.filter((role) => role !== Role.EFashionAdmin);
};

export const allAvailableRolesForLoggedInUser = (user: User) => {
  if (user.role === Role.EFashionAdmin) {
    return USER_ROLES.all.filter((role) => ![Role.SuperAdmin].includes(role));
  }

  return USER_ROLES.all;
};
const isGlobalAdmin = (user: User) =>
  user.role === Role.SuperAdmin || user.role === Role.EFashionAdmin;
const isManagingRole = (user: User) => user.role === Role.SuperAdmin;
// || user.role === Role.Li3ibAdmin
// || user.role === Role.CallCenterOperative;

// const isAdminPortalUser = (user: User) =>
//   USER_ROLES.adminPortalRoles.includes(user.role);
const isAccountantRole = (user: User) =>
  user.role === Role.SuperAdmin ||
  user.role === Role.EFashionAdmin;
export type Permissions = ReturnType<typeof getUserPermissions>;
export type PermissionKey = NestedKeyOf<Permissions>;

export const getUserPermissions = (user: User) => {
  return {
    company: {
      add: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
      select: hasLi3ibRole(user),
      view: hasCompanyViewRole(user),
    },
    companyType: {
      select: hasLi3ibRole(user),
    },
    fields: {
      view: hasLi3ibRole(user),
      add: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    source: {
      view: hasLi3ibRole(user),
    },
    users: {
      view: isManagingRole(user),
      edit: isManagingRole(user),
    },

    adminUsers: {
      view: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    wallet: {
      view: hasWalletRole(user),
    },
    reports: {
      view: isAccountantRole(user),
    },
  };
};

/** Booking view and edit permissions */
const isBeforeBookingStart = (booking: Booking) =>
  dayjs(new Date()).isBefore(booking.start_date);

const isBeforeBookingEnd = (booking: Booking) =>
  dayjs(new Date()).isBefore(booking.end_date);

const isAfterBookingStartWithExtraTime = (
  booking: Booking,
  extraAmount: number,
  unit: ManipulateType,
) =>
  dayjs(new Date()).isAfter(
    dayjs(booking.start_date).subtract(extraAmount, unit),
  );

const isBeforeBookingEndWithExtraTime = (
  booking: Booking,
  extraAmount: number,
  unit: ManipulateType,
) => dayjs(new Date()).isBefore(dayjs(booking.end_date).add(extraAmount, unit));

const canEditAllSourceBookings = (user: User) =>
  [Role.SuperAdmin, Role.EFashionAdmin, Role.EFashionAgent].includes(
    user.role,
  );

const canEditClientSourceBooking = (user: User, booking: Booking) =>
  [Role.EFashionAdmin].includes(user.role) &&
  (booking.source === Source.ExternalAdminPortal ||
    booking.source === Source.InternalAdminPortal);

const canEditConfirmations = (user: User) =>
  [Role.EFashionAdmin, Role.EFashionAgent].includes(user.role);

export const canViewPhoneNumber = (user: User, booking: Booking) =>
  canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking);

const canEditPhoneNumber = (user: User, booking: Booking) =>
  isBeforeBookingEnd(booking) &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

const canEditName = (user: User, booking: Booking) =>
  isBeforeBookingEnd(booking) &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

const canEditDiscount = (user: User, booking: Booking) =>
  isBeforeBookingEndWithExtraTime(booking, 30, 'minutes') &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

export const canCancelBooking = (user: User, booking: Booking) =>
  isBeforeBookingStart(booking) &&
  !booking.is_cancelled &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

export const canViewBookingAuthor = (user: User) => hasLi3ibRole(user);
export const canViewCompany = (user: User) => hasLi3ibRole(user);

const canEditPresence = (user: User, booking: Booking) => {
  if (booking.client_showed_up !== null) return false;

  return (
    isAfterBookingStartWithExtraTime(booking, 30, 'minutes') &&
    isBeforeBookingEndWithExtraTime(booking, 30, 'minutes') &&
    (canEditAllSourceBookings(user) || canEditConfirmations(user))
  );
};

const canEditPayment = (user: User, booking: Booking) => {
  if (!booking?.transaction) return false;

  if (booking?.transaction?.payment_status !== PaymentStatus?.Created)
    return false;

  const hasRole = canEditAllSourceBookings(user) || canEditConfirmations(user);

  if (booking.transaction.payment_method === PaymentMethod.Cash) {
    return (
      isAfterBookingStartWithExtraTime(booking, 30, 'minutes') &&
      isBeforeBookingEndWithExtraTime(booking, 30, 'minutes') &&
      hasRole
    );
  }

  return isBeforeBookingEndWithExtraTime(booking, 30, 'minutes') && hasRole;
};

export const canEditBooking = (user: User, booking: Booking) => {
  if (booking.is_cancelled)
    return {
      phoneNumber: false,
      firstName: false,
      lastName: false,
      discount: false,
      payment: false,
      presence: false,
    };

  return {
    phoneNumber: canEditPhoneNumber(user, booking),
    firstName: canEditName(user, booking),
    lastName: canEditName(user, booking),
    discount: canEditDiscount(user, booking),
    payment: canEditPayment(user, booking),
    presence: canEditPresence(user, booking),
  };
};

export const canEditCompany = (user: User) => {
  const canEditCompanyPermission = getUserPermissions(user).company.edit;

  return {
    name: canEditCompanyPermission,
    name_arabic: canEditCompanyPermission,
    address: canEditCompanyPermission,
    country: canEditCompanyPermission,
    phone_number: canEditCompanyPermission,
    email: canEditCompanyPermission,
    geolocation: canEditCompanyPermission,
    type: canEditCompanyPermission,
  };
};

export const canEditField = (user?: User) => {
  if (!user) return false;

  return getUserPermissions(user).fields.edit;
};

export const canDownloadAccountsReport = (user?: User) => {
  if (!user) return false;

  return hasLi3ibRole(user);
};
