export enum AddPromotionFormField {
  Flag = 'Flag',
  Id = 'Id',

  Brand = 'Brand_Fk_Id',
  GroupCategory = 'Group_Fk_Id',
  Category = 'Category_Fk_Id',
  Name = 'Name_En',
  NameAr = 'Name_Ar',

  StartDate = 'StartDate',
  EndDate = 'EndDate',
  OfferType = 'OfferType_Fk_Id',
  Offer = 'Offer',
  BannerEn = 'File_En',
  BannerAR = 'File_Ar',
}

export type AddPromotionFormValues = {
  [AddPromotionFormField.Flag]: string;
  [AddPromotionFormField.Id]: string;
  [AddPromotionFormField.Brand]: string;
  [AddPromotionFormField.GroupCategory]: string;
  [AddPromotionFormField.Category]: string;

  [AddPromotionFormField.Name]: string;
  [AddPromotionFormField.NameAr]: string;
  [AddPromotionFormField.OfferType]: string;
  [AddPromotionFormField.Offer]: string;
  [AddPromotionFormField.StartDate]: string;
  [AddPromotionFormField.EndDate]: string;
  [AddPromotionFormField.BannerEn]: File;
  [AddPromotionFormField.BannerAR]: File;
};

