import { Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { AddCMSFormField, AddCMSFormValues } from './types';





export const AddCMSForm = () => {

    const { t } = useTranslation();

    const { data: cmsType, isLoading: cmsLoading } = useGetDropdownLists({ Flag: "GetCMSType" });




    return (
        <FormSectionsSeparator title={""} >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item<AddCMSFormValues>
                        label={"CMS Type"}
                        name={AddCMSFormField.CMSTypeFkId}
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Select
                            loading={cmsLoading}
                            showSearch
                            options={cmsType?.map((item: { Text: string; Value: number }) => ({
                                label: item.Text,
                                value: item.Value,
                            }))}
                            filterOption={(input, option) =>
                                typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder={t('volunteer.field.assign_event.placeholder')}
                        />
                    </Form.Item >
                </Col>
                <Col span={12}>
                </Col>

                <Col span={12}>
                    <Form.Item<AddCMSFormValues>
                        label={"Title En"}
                        name={AddCMSFormField.Title}
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Input
                            placeholder={"Title En"}
                        />
                    </Form.Item>
                </Col>


                <Col span={12}>
                    <Form.Item<AddCMSFormValues>
                        label={"Title Ar"}
                        name={AddCMSFormField.Title_Ar}
                        rules={[
                            {
                                required: true,
                                message: t('validateMessages.custom.nomsg'),
                            },
                        ]}
                    >
                        <Input
                            placeholder={"Title Ar"}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item<AddCMSFormValues>
                        label={"Description En"}
                        name={AddCMSFormField.DescriptionEn}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: t('validateMessages.custom.nomsg'),
                    //     },
                    // ]}
                    >
                        <TextArea
                            placeholder={"Description En"}
                            rows={6}
                        />
                    </Form.Item>
                </Col>


                <Col span={12}>
                    <Form.Item<AddCMSFormValues>
                        label={"Description Ar"}
                        name={AddCMSFormField.DescriptionAr}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: t('validateMessages.custom.nomsg'),
                    //     },
                    // ]}
                    >
                        <TextArea
                            placeholder={"Description Ar"}
                            rows={6}
                        />
                    </Form.Item>
                </Col>

            </Row>

        </FormSectionsSeparator >
    );
};
