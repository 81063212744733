import { Button, DatePicker, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

import { ItemsNotifyFilterFormField, ItemsNotifyFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: ItemsNotifyFilterFormValues) => void;
    initialValues: ItemsNotifyFilterFormValues;
    closeModal?: () => void;
};

export const ItemsNotifyFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<ItemsNotifyFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const { data: customerData, isLoading: custLoading } = useGetDropdownLists({ Flag: "GetCustomerList" });


    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: ItemsNotifyFilterFormValues) => {
        if (formValues.FromDate) formValues.FromDate = payFormatDate(formValues.FromDate);
        if (formValues.ToDate) formValues.ToDate = payFormatDate(formValues.ToDate);
        handleFilter(formValues);
        closeModal?.();
    };

    const statusData = [
        { label: 'All', value: '' },
        { label: 'Active', value: '1' },
        { label: 'Inactive', value: '0' }
    ];


    return (
        <Form<ItemsNotifyFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>

                        <Form.Item<ItemsNotifyFilterFormValues>
                            label={"Customer Name"}
                            name={ItemsNotifyFilterFormField.Customer_Name}
                        >
                            <Input
                                placeholder={"Customer Name"}
                            />
                        </Form.Item>

                        <Form.Item<ItemsNotifyFilterFormValues>
                            label={"From Date"}
                            name={ItemsNotifyFilterFormField.FromDate}
                        >
                            <DatePicker
                                placeholder={"From Date"}
                            />
                        </Form.Item>

                        <Form.Item<ItemsNotifyFilterFormValues>
                            label={"To Date"}
                            name={ItemsNotifyFilterFormField.ToDate}
                        >
                            <DatePicker
                                placeholder={"To Date"}
                            />
                        </Form.Item>


                        <Form.Item<ItemsNotifyFilterFormValues>
                            label={"Status"}
                            name={ItemsNotifyFilterFormField.Status}
                        >
                            <Select
                                allowClear
                                options={statusData}
                                placeholder={"Status"}
                            />
                        </Form.Item>

                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
