import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { MarketingApiService } from 'features/marketing/api/marketing-api.service';
// import { useGetPromotionExcel } from 'features/marketing/use-cases/get-excel-download';
import { useState } from 'react';

import { PromotionListFilterForm } from '../filter-form/promotion-list-filter/promotion-list-filter-form';
import { PromotionFilterFormValues } from '../filter-form/promotion-list-filter/types';
import { AddPromotionModal } from '../models/add-promotion-model/add-promotion-model';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: PromotionFilterFormValues;
    handleFilter: (values: PromotionFilterFormValues) => void;
    Params: any
};

export const PromotionListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    Params
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'promotion-list-filters-form';


    // const { refetch: excelRefetch } = useGetPromotionExcel(Params, {
    //     enabled: false,
    // });

    const screens = useBreakpoint();

    const handleExport = async () => {
        const { getExcelDownload } = MarketingApiService();
        try {
            // Trigger Excel download without resetting or clearing table data
            await getExcelDownload(Params);
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };


    const [isOpen, setIsOpen] = useState<boolean>(false);


    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddPromotionModal onClose={handleCloseModal} editData={null} />
            </div>


            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                    type='primary'
                    onClick={handleExport}
                >
                    Export to Excel
                </Button>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModel}
                >
                    Add Promotion
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <PromotionListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
