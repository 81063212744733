import { Card } from 'antd'
import { useGetCustomersInfo } from 'features/customers/use-cases/get-customer-info';
import moment from 'moment';
import { useEffect } from 'react';

const CustomerInfo = ({ id, tabs }: { id: number; tabs: string }) => {

    const params = {
        Id: id,
        CurrentPage: 1,
        RecordsPerPage: 1
    }
    const { data, isLoading, refetch } = useGetCustomersInfo((params));


    useEffect(() => {
        if (tabs === "1") {
            refetch();
        }
    }, [tabs]);

    return (


        <div>





            <Card className='prfle_crd' title="Customer Info"  >
                <div className='profile_data'>
                    <div className='prf_img custmer_pr'>
                        <div className='prf_img_bx'>
                            <img src={data?.getCustomerLists[0]?.ProfileImage || "null"} alt=""
                                onError={(e) => {
                                    e.currentTarget.src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"; // Set fallback image path here
                                }}
                            />
                        </div>

                    </div>
                    <div className='customer_dtls user_d'>

                        <p>Name : <span>{data?.getCustomerLists[0]?.Name}</span></p>
                        <p>Email : <span>{data?.getCustomerLists[0]?.EmailId}</span></p>
                        <p>Mobile No : <span>{data?.getCustomerLists[0]?.MobileNo}</span></p>
                        <p>DOB : <span>{data?.getCustomerLists[0]?.DOB && moment(data?.getCustomerLists[0]?.DOB).calendar("dd/mm/yyyy")}</span></p>
                        <p>Country : <span>{data?.getCustomerLists[0]?.CountryName}</span></p>
                        <p>Gender : <span>{data?.getCustomerLists[0]?.Gender}</span></p>
                        <p>Registered On : <span>{data?.getCustomerLists[0]?.RegisteredOn && moment(data?.getCustomerLists[0]?.RegisteredOn).calendar("dd/mm/yyyy")}</span></p>
                        <p>Status : <span>{data?.getCustomerLists[0]?.Status === 1 ? "Active" : "InActive"}</span></p>
                    </div>

                </div>

            </Card>
        </div>
    )
}

export default CustomerInfo
