import {
    QueryFunction,
    QueryKey,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { UserApiService, UserListParams } from 'features/users';
import qs from 'qs';

import { userQueryKeys } from './query-keys';

const { getUserDataById, } = UserApiService();

const getUsersQuery = (
    params: UserListParams,
    options?: UseQueryOptions<any>,
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<any>;
    options?: UseQueryOptions<any>;
} => ({
    queryKey: userQueryKeys.list(params),
    queryFn: ({ signal }) =>
        getUserDataById(params, {
            config: {
                signal,
                paramsSerializer: {
                    serialize: (queryParams) =>
                        qs.stringify(queryParams, {
                            arrayFormat: 'repeat',
                            encode: true,
                        }),
                },
            },
        }),
    ...options,
});

export const useGetUsersDetailsById = (
    params: UserListParams,
    options?: UseQueryOptions<any>,
) => {
    const { message } = App.useApp();

    return useQuery<any, Error>(
        getUsersQuery(params, {
            onError: (error: unknown) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            ...options,
        }),
    );
};


