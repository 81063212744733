import { apiClient, ApiClientConfig } from 'api';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};

export type EditDeliveryDaysDTO = {
  Id: number;
  DelieveryDays: number;
  Flag: string;
};

export type updateCommonStatusDTO = {
  Id: number;
  Flag: string;
  Status: number;
};

export type commonDeleteDTO = {
  Id: number;
  Flag: string;
};

export type CMSTypeDTO = {
  Id?: number;
  Flag: string;
  TypeEn: string;
  TypeAr: string;
};

export type CustomSizeDTO = {
  Id?: number;
  Flag: string;
  Title: string;
  TitleAr: string;
};

export type CMSDTO = {
  Id?: number;
  Flag: string;
  Title_En: string;
  Title_Ar: string;
  Description_En: string;
  Description_Ar: string;
  CMSType_Fk_Id: number;
  AppType: number;
};


export const AdminApiService = () => {


  const getDeliveryDaysLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/getdelieverydayslist`,
      params,
    });

    return response.data;
  };

  const getPaymentMethodLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/getpaymentmethodlist`,
      params,
    });

    return response.data;
  };


  const updateDeliveryDays = async (
    payload: EditDeliveryDaysDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/saveandupdatedeliverydays`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getCMSLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/getcmslist`,
      params,
    });

    return response.data;
  };

  const getCMSTypeLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/getcmstypelist`,
      params,
    });

    return response.data;
  };


  const getCustomSizeMasterLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/getcustomsizelist`,
      params,
    });

    return response.data;
  };


  const updateCommonStatus = async (
    payload: updateCommonStatusDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `common/commonupdatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const commonDelete = async (
    payload: commonDeleteDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `common/commondelete`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateCMSType = async (
    payload: CMSTypeDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/saveandupdatecmstype`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateCustomSize = async (
    payload: CustomSizeDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/saveandupdatecustomsize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateCMS = async (
    payload: CMSDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/saveandupdatecms`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    getDeliveryDaysLists,
    getPaymentMethodLists,
    updateDeliveryDays,
    getCMSLists,
    getCMSTypeLists,
    getCustomSizeMasterLists,
    updateCommonStatus,
    commonDelete,
    updateCMSType,
    updateCustomSize,
    updateCMS
  };
};
