import { CONFIG } from 'config';

import { axiosInstance } from './axios-instance';
import { ApiClientConfig } from './types';

/**
 * Get client side timezone.
 *
 * @returns {(+|-)HH:mm} - Where `HH` is 2 digits hours and `mm` 2 digits minutes.
 * @example
 * // From Indian/Reunion with UTC+4
 * // '+04:00'
 * getTimeZone()
 */

const getTimeZone = () => {
  const timezoneOffset = new Date().getTimezoneOffset()
  //   const offset = Math.abs(timezoneOffset)
  //   const offsetOperator = timezoneOffset < 0 ? '+' : '-'
  //   const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0')
  //   const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0')
  // return `${offsetOperator}${offsetHours}:${offsetMinutes}`

  return timezoneOffset
}

/**
 * @param {string} endpoint - API endpoint relative to `baseURL`, for example `/users`
 */
export const apiClient = <TResponseData>({
  endpoint,
  data = {},
  params = {},
  method = 'GET',
  config = {},
  apiVersion = 'v1',
}: ApiClientConfig) => {

  const baseURL = `${CONFIG.EFASHION_API_BASE_URL}/${apiVersion}`;
  const hasRequestData = Object.keys(data).length > 0;
  const hasParams = Object.keys(params).length > 0;


  return axiosInstance.request<TResponseData>({
    ...config,
    baseURL,
    url: endpoint,
    ...(hasRequestData ? { data } : undefined),
    ...(hasParams ? { params } : undefined),
    method,
    headers: {
      'Content-Type': 'application/json', 'Accept': 'application/json', ...config.headers
    },
  });
};
