export enum AddPromotionTypeFormField {
  Flag = 'Flag',
  Id = 'Id',
  Type = 'Type',
}

export type AddPromotionTypeFormValues = {
  [AddPromotionTypeFormField.Flag]: string;
  [AddPromotionTypeFormField.Id]: string;
  [AddPromotionTypeFormField.Type]: string;
};

