import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { UserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { UsersFilterForm } from 'features/users/components/users-filter-form/users-filter-form';
import { useState } from 'react';

import { AddUserModal } from '../modals/add-user-modal';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  getInitialFilterValues: UserFilterFormValues;
  handleFilter: (values: UserFilterFormValues) => void;
};

export const UsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: UsersTopBarProps) => {
  useState<any>();
  const formId = 'users-filters-form';
  const [showAppUserModal, setShowAppUserModal] = useState(false);


  const screens = useBreakpoint();



  const handleCloseModal = () => {
    setShowAppUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAppUserModal(true);
    document.body.style.overflow = 'hidden';
  }



  return (
    <>
      <div className={`cstmrightmdl ${showAppUserModal && "show"}`}>
        <AddUserModal onClose={handleCloseModal} userEditdata={""} />
      </div>


      <div className='head_btn'>
        <Button
          icon={!screens.xs ? <PlusOutlined /> : undefined}
          type='primary'
          onClick={handleOpenModal}
        >
          Add User
          {/* {t('adminUsers.addNewUser')} */}
        </Button>
      </div>

      <ContentFilters formId={formId}>
        <UsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
