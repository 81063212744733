
export enum AddSubCategoryFormField {
  Flag = 'Flag',
  Id = "Id",
  CategoryName_En = 'CategoryName_En',
  CategoryName_Ar = 'CategoryName_Ar',
  CategoryGroup = 'CategoryGroup',
  GroupLogo = 'File',
}

export type AddSubCategoryFormValues = {
  [AddSubCategoryFormField.Flag]: string | undefined;
  [AddSubCategoryFormField.Id]: string | undefined;
  [AddSubCategoryFormField.CategoryName_En]: string | undefined;
  [AddSubCategoryFormField.CategoryName_Ar]: string | undefined;
  [AddSubCategoryFormField.CategoryGroup]: string | undefined;
  [AddSubCategoryFormField.GroupLogo]: any;
};

