import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';
import { handleKeyDownThree } from 'utils/key-press';

import { EditCustomMasterFormField } from './types';



export const EditCustomSizeMasterForm = () => {

    const { t } = useTranslation();


    return (
        <FormSectionsSeparator title={""} >


            <Form.Item<EditCustomMasterFormField >
                label={"Custom size"}
                name={EditCustomMasterFormField.Title}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    }
                ]}
            >
                <Input
                    placeholder={"Custom Size"}
                />
            </Form.Item>

            <Form.Item<EditCustomMasterFormField >
                label={"Custom Size(Arabic)"}
                name={EditCustomMasterFormField.TitleAr}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    }
                ]}
            >
                <Input
                    placeholder={"Custom Size(Arabic)"}
                />
            </Form.Item>


        </FormSectionsSeparator >
    );
};
