import { useSessionContext } from 'features/auth';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from './constants';

export const UnauthenticatedRoute = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { isLoggedIn } = useSessionContext();
  // const location = useLocation();

  const origin = ROUTES.HOME; //location.state?.from?.pathname || ROUTES.HOME;

  if (isLoggedIn) {
    return <Navigate to={origin} replace />;
  }

  return children;
};
