import { Button, Form, Input } from 'antd';
import { useUpdateSocialMedia } from 'features/marketing/use-cases/update-social-media';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddSocialMediaFormField, AddSocialMediaFormValues } from './types';

export const AddSocialMediaModal = ({ isOpen, onClose, editData }: { isOpen: boolean, onClose: () => void, editData: any }) => {
    const formName = 'add-social-media-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddSocialMediaFormValues>();

    const { t } = useTranslation();


    const { isLoading, mutate } = useUpdateSocialMedia();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }



    const getInitialValues = {
        [AddSocialMediaFormField.Flag]: undefined,
        [AddSocialMediaFormField.Id]: undefined,
        [AddSocialMediaFormField.FacebookUrl]: undefined,
        [AddSocialMediaFormField.TwitterUrl]: undefined,
        [AddSocialMediaFormField.InstagramUrl]: undefined,
        [AddSocialMediaFormField.LinkedInUrl]: undefined,
        [AddSocialMediaFormField.YoutubeUrl]: undefined,
        [AddSocialMediaFormField.GooglePlusUrl]: undefined,
        [AddSocialMediaFormField.EmailUrl]: undefined,
        [AddSocialMediaFormField.TeligramUrl]: undefined,


    };

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        }

    }, [editData, form, isOpen]);



    const handleUpdate = (formData: AddSocialMediaFormValues) => {
        formData.Id = editData?.Id;

        mutate(formData, { onSuccess: handleClose });
    };



    const onReset = () => {
        form.resetFields();
        onClose();
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Social-Media' : 'Add Social-Media'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddSocialMediaFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <Form.Item<AddSocialMediaFormValues>
                            label={"Facebook"}
                            name={AddSocialMediaFormField.FacebookUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Facebook"}
                            />
                        </Form.Item>

                        <Form.Item<AddSocialMediaFormValues>
                            label={"Twitter"}
                            name={AddSocialMediaFormField.TwitterUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Twitter"}
                            />
                        </Form.Item>
                        <Form.Item<AddSocialMediaFormValues>
                            label={"Instagram"}
                            name={AddSocialMediaFormField.InstagramUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Instagram"}
                            />
                        </Form.Item>
                        <Form.Item<AddSocialMediaFormValues>
                            label={"LinkedIn"}
                            name={AddSocialMediaFormField.LinkedInUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"LinkedIn"}
                            />
                        </Form.Item>
                        <Form.Item<AddSocialMediaFormValues>
                            label={"Youtube"}
                            name={AddSocialMediaFormField.YoutubeUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Youtube"}
                            />
                        </Form.Item>
                        <Form.Item<AddSocialMediaFormValues>
                            label={"GooglePlus"}
                            name={AddSocialMediaFormField.GooglePlusUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"GooglePlus"}
                            />
                        </Form.Item>
                        <Form.Item<AddSocialMediaFormValues>
                            label={"Email"}
                            name={AddSocialMediaFormField.EmailUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Email"}
                            />
                        </Form.Item>
                        <Form.Item<AddSocialMediaFormValues>
                            label={"Telegram"}
                            name={AddSocialMediaFormField.TeligramUrl}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input
                                placeholder={"Telegram"}
                            />
                        </Form.Item>



                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }} loading={isLoading}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

