import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { BrandsApiService } from '../api/brands-api.service';
import { ordersQueryKeys } from './query-keys';



const { getBrandaLists } = BrandsApiService();

const getOrderListQuery = (
  params: any,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<any>;
  options?: UseQueryOptions<any>;
} => ({
  queryKey: ordersQueryKeys.list(params),
  queryFn: ({ signal }) => getBrandaLists(params, { config: { signal } }),
  ...options,
});

export const useGetBrandsLists = (
  params: any,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getOrderListQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};



