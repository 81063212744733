import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { ItemsApiService } from '../api/items-api.service';
import { itemsQueryKeys } from './query-keys';




const { getItemsLists } = ItemsApiService();

const getItemsListQuery = (
  params: any,
  options?: UseQueryOptions<any>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<any>;
  options?: UseQueryOptions<any>;
} => ({
  queryKey: itemsQueryKeys.list(params),
  queryFn: ({ signal }) => getItemsLists(params, { config: { signal } }),
  ...options,
});

export const useGetItemsLists = (
  params: any,
  options?: UseQueryOptions<any>,
) => {
  const { message } = App.useApp();

  return useQuery<any, Error>(
    getItemsListQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};



