import { Form, FormInstance, Input, Select } from "antd";
import { useGetDropdownLists } from "features/items/use-cases/get-dopdwon-list";
import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next";
import { handleKeyAmount, handleKeyDownThree } from "utils/key-press";

import { DeliveryChargeFilterFormValues } from "../delivery-list-filter/types";
import { DeliveryChargeFormField, DeliveryChargeFormValues } from "./types";

export const DeliveryItem = ({ form, dropdown }: { form: FormInstance<DeliveryChargeFilterFormValues>, dropdown: any }) => {
    const [parentId, setParentId] = useState('')

    const statusData = [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 }
    ];

    const params = {
        Flag: "GetCascadingCityList",
        parentId: parentId
    }

    const { data, isLoading } = useGetDropdownLists((params), {
        retry: false,
    });

    const handleSelect = (id: string) => {
        setParentId(id);
        form.resetFields([DeliveryChargeFormField.CityFkId])
    }


    const { t } = useTranslation()

    const validateChargeInput = (_: any, value: string) => {
        // Regular expression for up to 4 integer digits and up to 3 decimal places
        const pattern = /^\d{1,4}(\.\d{1,3})?$/;
        if (!pattern.test(value)) {
            return Promise.reject('Enter a valid amount (e.g., 9999.999)');
        }

        // Optional: Check for maximum value if needed
        const numericValue = parseFloat(value);
        if (numericValue > 9999.999) {
            return Promise.reject('Maximum allowed value is 9999.999');
        }

        return Promise.resolve();
    };

    return (
        <Fragment >
            <Form.Item<DeliveryChargeFormValues>
                label="Governorate"
                name={DeliveryChargeFormField.GovernorateFkId}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={dropdown?.map((item: any) => ({
                        value: item.Value,
                        label: item.Text,
                    }))}
                    onChange={(value) => handleSelect(value)}
                    placeholder="Select"
                />
            </Form.Item>

            <Form.Item<DeliveryChargeFormValues>
                label="City"
                name={DeliveryChargeFormField.CityFkId}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    allowClear
                    options={data?.map((item: any) => ({
                        value: item.Value,
                        label: item.Text,
                    }))}
                    showSearch
                    filterOption={(input, option) =>
                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={'Select City'}
                />
            </Form.Item>

            <Form.Item<DeliveryChargeFormValues>
                label="Charges"
                name={DeliveryChargeFormField.Charge}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                    { validator: validateChargeInput },
                ]}
            >
                <Input placeholder="Enter Charge"
                    onKeyDown={handleKeyAmount}
                />
            </Form.Item>

            <Form.Item<DeliveryChargeFormValues>
                label="Delivery in Days"
                name={DeliveryChargeFormField.NoOfDays}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input placeholder="Enter Delivery Days"
                    onKeyDown={handleKeyDownThree}
                />
            </Form.Item>
            <Form.Item<DeliveryChargeFormValues>
                label={"Status"}
                name={DeliveryChargeFormField.isActive}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    options={statusData}
                    allowClear
                    placeholder={'Select Status'}
                />
            </Form.Item>
        </Fragment>
    )
}