import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { ItemsCategoryFilterForm } from '../filter-form/items-category-filter-form/items-category-filter-form';
import { ItemsCategoryFilterFormValues } from '../filter-form/items-category-filter-form/types';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsCategoryFilterFormValues;
    handleFilter: (values: ItemsCategoryFilterFormValues) => void;
    tableParams: any
};

export const ItemsCategoryListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {

    const formId = 'category-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>

            {/* <ContentTopBar title={'Items Category List'} /> */}
            <ContentFilters formId={formId}>
                <ItemsCategoryFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
