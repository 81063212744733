import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserFilterFormField, UserFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: UserFilterFormValues) => void;
  initialValues: UserFilterFormValues;
  closeModal?: () => void;
};

export const UsersFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter(initialValues);
  };

  const onFinish = (formValues: UserFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };
  const statusData = [
    { label: 'All', value: '' },
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' }
  ];

  return (
    <Form<UserFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
        <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
          <div className='invoice_list' style={{ width: '100%' }}>

            <Form.Item<UserFilterFormValues>
              label={'UserId'}
              name={UserFilterFormField.UserId}
            >
              <Input
                placeholder={"UserId"}
              />
            </Form.Item>

            <Form.Item<UserFilterFormValues>
              label={'Name'}
              name={UserFilterFormField.Name}
            >
              <Input
                placeholder={t('adminUsers.formFields.userName.placeholder')}
              />
            </Form.Item>



            <Form.Item<UserFilterFormValues>
              label={'Email'}
              name={UserFilterFormField.EmailId}
            >
              <Input placeholder={t('adminUsers.formFields.email.placeholder')} />
            </Form.Item>

            <Form.Item<UserFilterFormValues>
              label={'Mobile No'}
              name={UserFilterFormField.MobileNo}
            >
              <Input
                placeholder={"MobileNo"}
              />
            </Form.Item>

            <Form.Item<UserFilterFormValues>
              label={"Status"}
              name={UserFilterFormField.Status}
            >
              <Select
                options={statusData}
                placeholder={"Status"}
              />
            </Form.Item>


            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>


    </Form>
  );
};
