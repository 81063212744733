import { PaginatedRequestParams } from "api";

export enum PromotionTypeFilterFormField {
    Type = 'Type',
}

export type PromotionTypeFilterFormValues = {
    [PromotionTypeFilterFormField.Type]: string | undefined;

};

export type PromotionTypeFilterFormPaginated = PromotionTypeFilterFormValues &
    PaginatedRequestParams;