import { PaginatedRequestParams } from "api";

export enum OfferTypeFilterFormField {
    OfferTypeType = 'OfferType',
    IsActive = 'status',
}

export type OfferTypeFilterFormValues = {
    [OfferTypeFilterFormField.OfferTypeType]: string | undefined;
    [OfferTypeFilterFormField.IsActive]: string | undefined;
};

export type OfferTypeFilterFormPaginated = OfferTypeFilterFormValues &
    PaginatedRequestParams;