import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';

import { UserApiService } from '../api/user-api.service';
import { userQueryKeys } from './query-keys';


const { getAllRole } = UserApiService();

// Define a query function that does not take any parameters
const getAllRolesQuery = (
    options?: UseQueryOptions<any>
): {
    queryKey: QueryKey;
    queryFn: QueryFunction<any>;
    options?: UseQueryOptions<any>;
} => ({
    queryKey: userQueryKeys.details(),
    queryFn: ({ signal }) => getAllRole({}, { config: { signal } }),
    ...options,
});

export const useGetAllRoles = (
    options?: UseQueryOptions<any>
) => {
    const { message } = App.useApp();

    return useQuery<any, Error>(
        getAllRolesQuery({
            onError: (error: unknown) => {
                const uiMessage = handleError(error);
                message.error(uiMessage);
            },
            ...options,
        }),
    );
};

