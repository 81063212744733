import { Button, Form, Input } from 'antd';
import { useUpdateItemsSize } from 'features/items/use-cases/update-item-size';
import { useEffect, useState } from 'react';

import { EditSizeForm } from './edit-size-form';
import { EditSizeFormField, EditSizeFormValues } from './types';


export const EditSizeTypeModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'edit-size-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<EditSizeFormValues>();


    const { isLoading, mutate } = useUpdateItemsSize();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [EditSizeFormField.Id]: undefined,
        [EditSizeFormField.Flag]: undefined,
        [EditSizeFormField.Category]: undefined,
        [EditSizeFormField.SubCategory]: undefined,
        [EditSizeFormField.SizeType]: undefined,
        [EditSizeFormField.Size]: undefined,
        [EditSizeFormField.IsDefault]: undefined,

    };


    useEffect(() => {
        if (editData) {
            // form.setFieldsValue({
            //     [EditSizeFormField.Id]: editData?.Season_Ar,
            //     [EditSizeFormField.Flag]: editData?.Season_Ar,
            //     [EditSizeFormField.Category]: editData?.Season_Ar,
            //     [EditSizeFormField.SubCategory]: editData?.Season_Ar,
            //     [EditSizeFormField.SizeType]: editData?.Season_Ar,
            //     [EditSizeFormField.Size]: editData?.Season_Ar,
            //     [EditSizeFormField.IsDefault]: editData?.Season_Ar,
            // });
            form.setFieldsValue(editData)
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formData: EditSizeFormValues) => {

        formData.Id = editData?.Id;
        formData.Flag = "insert";
        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        onClose();
    };


    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Edit Size</h4>
                </div>
                <div className='modal_body'>
                    <Form<EditSizeFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <EditSizeForm form={form} editData={editData} />

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

