import { UsersTopBar } from 'features/users/components/top-bar/users-top-bar';
import { UsersTable } from 'features/users/components/users/users-table';
import {
  UserFilterFormValues,
  UserFilterFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { useUserManagementList } from '../use-cases/get-users';

const getInitialFilterValues: UserFilterFormValues = {
  UserId: undefined,
  Name: undefined,
  EmailId: undefined,
  MobileNo: undefined,
  Status: undefined,
};

export const UserManagementView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<UserFilterFormValuesPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const getUsersListQueryResponse = useUserManagementList((params), {
    retry: false,
  });


  const handleFiltersChange = (values: UserFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
    getUsersListQueryResponse.refetch();
  };

  return (
    <>
      <UsersTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <UsersTable
        isUsersListLoading={
          getUsersListQueryResponse.isFetching
        }
        usersList={getUsersListQueryResponse.data?.getMemberData || []}
        itemsCount={getUsersListQueryResponse.data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};
