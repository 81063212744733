import { Button, Form, Image, Input, message } from 'antd';
import { useUpdateBrands } from 'features/brands/use-cases/brands-update';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrandsFormField, BrandsFormValues } from './types';


export const AddBrandsModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-brands-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<BrandsFormValues>();


    const { isLoading, mutate } = useUpdateBrands();
    const [bannerEnFile, setBannerEnFile] = useState<any>(null);

    const handleClose = () => {
        onClose();
        form.resetFields();
        setBannerEnFile(null);
        setFileName("");
    }


    const { t } = useTranslation();

    const getInitialValues = {
        [BrandsFormField.BrandNameAr]: undefined,
        [BrandsFormField.BrandNameEn]: undefined,
        [BrandsFormField.BrandLogo]: undefined,
        [BrandsFormField.status]: undefined,
    };

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(
                {
                    // ...editData,
                    [BrandsFormField.BrandNameEn]: editData?.BrandName_En,
                    [BrandsFormField.BrandNameAr]: editData?.BrandName_Ar,
                }
            );
        } else {
            form.resetFields();
        }

    }, [editData, form]);


    const handleUpdate = (formValues: BrandsFormValues) => {



        const formDataToSend = new FormData();
        formDataToSend.append('Id', editData?.Id);

        if (bannerEnFile) {
            formDataToSend.append('File', bannerEnFile);
        }

        formDataToSend.append('BrandName_Ar', formValues.BrandName_Ar);
        formDataToSend.append('BrandName_En', formValues.BrandName_En);
        formDataToSend.append('Flag', "insert");

        mutate(formDataToSend, { onSuccess: handleClose });
    };


    const onReset = () => {
        form.resetFields();
        handleClose();
    };

    const [fileName, setFileName] = useState<string>("");

    useEffect(() => {
        if (editData?.BrandLogo) {
            const extractedFileName = editData?.BrandLogo.split('/').pop();
            setFileName(extractedFileName || 'BrandLogo.jpg');
        }
    }, [editData]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setBannerEnFile(selectedFile);
                setFileName(selectedFile.name);
            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setBannerEnFile(null);
                setFileName("");
            }
        }
    };



    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Brand' : 'Add Brand'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<BrandsFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <>
                            <Form.Item<BrandsFormValues>
                                label="Brand Name (English)"
                                name={BrandsFormField.BrandNameEn}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input placeholder="Enter brand name (English)" />
                            </Form.Item>

                            <Form.Item<BrandsFormValues>
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                                label="Brand Name (Arabic)"
                                name={BrandsFormField.BrandNameAr}
                            >
                                <Input placeholder="Enter brand name" />
                            </Form.Item>




                            <Form.Item<BrandsFormValues>
                                label="Brand Logo"
                                name={BrandsFormField.BrandLogo}
                                help={"Please select a .jpg, .jpeg, or .png file."}
                            // validateStatus={fileError ? "error" : undefined}

                            >

                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => document.getElementById('fileInput')?.click()}>Choose File</Button>}
                                    value={fileName}
                                    readOnly
                                />
                                <input
                                    type='file'
                                    id='fileInput'
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileChange}
                                />

                            </Form.Item>

                            <div className='edit_img_show'>
                                {bannerEnFile ? (
                                    <Image
                                        src={URL.createObjectURL(bannerEnFile)}
                                        width={"120px"}
                                        height={"90px"}
                                        alt="Preview"
                                    />
                                ) : (

                                    editData?.BrandLogo && (
                                        <Image
                                            src={editData.BrandLogo}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Brand Logo"
                                        />
                                    )
                                )}
                            </div>
                        </>

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" loading={isLoading} disabled={false}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

