import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";

import { DeliveryApiService } from "../api/delivery-api.service";
import { DeliveryQueryKeys } from "./query-keys";

export const useUpdateStatusDelivery = () => {
    const { message } = App.useApp();
    const queryClient = useQueryClient();
    const { createDelivery } = DeliveryApiService()
    
return useMutation(
        (payload: any) => {
            return createDelivery(payload); // Call the actual API function
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);
                message.error(uiMessage);
            },
            onSuccess: (succes:any) => {
                queryClient.invalidateQueries({
                    queryKey: DeliveryQueryKeys.lists(),
                });
                message.success(succes.ResponseMessage);
            },
        }
    );
};
