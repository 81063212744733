import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CMSFilterForm } from '../filter-form/cms-filter/cms-filter-form';
import { CMSFilterFormValues } from '../filter-form/cms-filter/types';
import { AddCMSModal } from '../models/add-cms-model/cms-add-model';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: CMSFilterFormValues;
    handleFilter: (values: CMSFilterFormValues) => void;
};

export const CMSTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'cms-filters-form';
    const [isOpen, setIsOpen] = useState(false);

    const screens = useBreakpoint();



    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }
    const navigate = useNavigate();

    const handleOpenModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';

        // navigate(getRoute(ROUTES.USER_CMS_EDIT, 0))
    }



    return (
        <>

            <div className={`cstmrightmdl notify_modal ${isOpen && "show"}`}>
                <AddCMSModal onClose={handleCloseModal} editData={null} />
            </div>
            <div className='head_btn'>

                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModal}
                >
                    Add CMS
                </Button>
            </div>

            <ContentFilters formId={formId}>
                <CMSFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
