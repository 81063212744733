import { PaginatedRequestParams } from "api";

export enum ItemsReviewsFilterFormField {
    CustomerName = 'CustomerName',
    Rating = 'Rating',
    ReviewStatus = 'ReviewStatus',
}

export type ItemsReviewsFilterFormValues = {
    [ItemsReviewsFilterFormField.CustomerName]: string | undefined;
    [ItemsReviewsFilterFormField.Rating]: string | undefined;
    [ItemsReviewsFilterFormField.ReviewStatus]: string | undefined;

};

export type ItemsReviewsFilterFormPaginated = ItemsReviewsFilterFormValues &
    PaginatedRequestParams;