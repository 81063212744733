import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ReturnFilterFormValues, ReturnFilterFormValuesPaginated } from '../components/filter-form/order-return-filter/types';
import { OrderReturnListTable } from '../components/order-list-table/order-return-table';
import { OrderReturnListTopBar } from '../components/top-bar/order-return-tobbar';
import { useGetOrderLists } from '../use-cases';

const getInitialFilterValues: ReturnFilterFormValues = {
  FromDate: undefined,
  ToDate: undefined,
  Name: undefined,
  EmailId: undefined,
  MobileNo: undefined,
  TransactionId: undefined,
  brand_Fk_Id: undefined,
  isCustomOrder: undefined,
};

export const OrderReturnView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<any>({
      Flag: "Return",
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading } = useGetOrderLists((params), {
    retry: false,
  });


  const handleFiltersChange = (values: ReturnFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
  };

  return (
    <div>
      <OrderReturnListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
        tableParams={params}
        
      />

      <OrderReturnListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}
        

      />
      <Outlet />
    </div>
  );
};


