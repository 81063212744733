import { PaginatedRequestParams } from "api";

export enum SystemFilterFormField {
    Title = 'FieldName',
    IsActive = 'status',
}

export type SystemFilterFormValues = {
    [SystemFilterFormField.Title]: string | undefined;
    [SystemFilterFormField.IsActive]: string | undefined;

};

export type SystemFilterFormPaginated = SystemFilterFormValues &
    PaginatedRequestParams;