export const handleKeyDownThree = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.currentTarget as HTMLInputElement;
    const currentValue = input.value;
    const selectionStart = input.selectionStart ?? 0; // Default to 0 if null or undefined
    const selectionEnd = input.selectionEnd ?? 0; // Default to 0 if null or undefined

    // Allow backspace if there's a selection or if it's not at the beginning
    if (event.key === 'Backspace') {
        if (selectionStart !== selectionEnd || selectionStart > 0) {

            return; // Allow backspace when there's text selected or cursor is not at the beginning
        }
    }

    // Check if the input exceeds 3 digits and not selecting anything
    if (currentValue.length >= 3 && selectionStart === selectionEnd) {
        event.preventDefault();

        return;
    }

    // Allow only numbers (0-9)
    if (!(event.key === 'Backspace' || /[0-9]/.test(event.key))) {
        event.preventDefault();
    }
};


export const handleKeyDownSix = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.currentTarget as HTMLInputElement;
    const currentValue = input.value;
    const selectionStart = input.selectionStart ?? 0; // Default to 0 if null or undefined
    const selectionEnd = input.selectionEnd ?? 0; // Default to 0 if null or undefined

    // Allow backspace if there's a selection or if it's not at the beginning
    if (event.key === 'Backspace') {
        if (selectionStart !== selectionEnd || selectionStart > 0) {

            return; // Allow backspace when there's text selected or cursor is not at the beginning
        }
    }

    // Check if the input exceeds 3 digits and not selecting anything
    if (currentValue.length >= 6 && selectionStart === selectionEnd) {
        event.preventDefault();

        return;
    }

    // Allow only numbers (0-9)
    if (!(event.key === 'Backspace' || /[0-9]/.test(event.key))) {
        event.preventDefault();
    }
};




export const handleKeyAmount = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.currentTarget as HTMLInputElement;
    const currentValue = input.value;
    const selectionStart = input.selectionStart ?? 0; // Default to 0 if null or undefined
    const selectionEnd = input.selectionEnd ?? 0; // Default to 0 if null or undefined

    // Allow backspace if there's a selection or if it's not at the beginning
    if (event.key === 'Backspace') {

        return;
    }

    // Allow only numbers and a decimal point
    if (!/[0-9.]/.test(event.key)) {
        event.preventDefault();

        return;
    }

    // Check if the current input already has a decimal point
    const dotIndex = currentValue.indexOf('.');
    const hasDecimal = dotIndex !== -1;

    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = currentValue.split('.');

    // Prevent entering more than 4 digits before the decimal point
    if (!hasDecimal && integerPart.length >= 4 && selectionStart <= dotIndex) {
        event.preventDefault();

        return;
    }

    // Prevent entering more than 3 digits after the decimal point
    if (hasDecimal && selectionStart > dotIndex && decimalPart?.length >= 3) {
        event.preventDefault();

        return;
    }

    // Prevent entering a second decimal point
    if (event.key === '.' && hasDecimal) {
        event.preventDefault();

        return;
    }

    // Prevent entering more than 4 digits before the decimal if selection is at the end
    if (integerPart.length >= 4 && !hasDecimal && event.key !== '.' && selectionStart === selectionEnd) {
        event.preventDefault();

        return;
    }
};
