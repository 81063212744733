import { PaginatedRequestParams } from "api";

export enum OrderFilterFormField {
    FromDate = 'fromDate',
    ToDate = 'toDate',
    Name = 'Name',
    EmailId = 'EmailId',
    MobileNo = 'MobileNo',
    TransactionId = 'transactionId',

    PaymentType = 'paymentTypeId',
    InvoiceType = 'invoiceTypeId',
    PaymentStatus = 'paymentStatus',
    Brand = 'brand_Fk_Id',
    IsCustomOrder = 'isCustomOrder',
    Status = 'statusId',
    Flag = "Flag"
}


export type OrderFilterFormValues = {
    [OrderFilterFormField.Flag]: string | undefined;
    [OrderFilterFormField.FromDate]: string | undefined;
    [OrderFilterFormField.ToDate]: string | undefined;
    [OrderFilterFormField.Name]: string | undefined;
    [OrderFilterFormField.EmailId]: string | undefined;
    [OrderFilterFormField.MobileNo]: string | undefined;
    [OrderFilterFormField.TransactionId]: string | undefined;


    [OrderFilterFormField.PaymentType]: string | undefined;
    [OrderFilterFormField.InvoiceType]: string | undefined;
    [OrderFilterFormField.PaymentStatus]: string | undefined;
    [OrderFilterFormField.Brand]: string | undefined;
    [OrderFilterFormField.IsCustomOrder]: boolean | undefined;

    [OrderFilterFormField.Status]: string | undefined;
};

export type OrderFilterFormPaginated = OrderFilterFormValues &
    PaginatedRequestParams;