import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { SystemFilterFormPaginated, SystemFilterFormValues } from '../components/system-list-filter/types';
import { SsytemListTable } from '../components/system-list-table/system-list-table';
import { SystemListTopBar } from '../components/top-bar/system-list-topbar';
import { useGetSystemLists } from '../use-cases/get-system-list';

const getInitialFilterValues: SystemFilterFormValues = {
    FieldName: undefined,
    status: undefined,
};

export const SystemsView = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<SystemFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading } = useGetSystemLists((params), {
        retry: false,
    });

    const handleFiltersChange = (values: SystemFilterFormPaginated) => {
        updateParams(values);
        resetPage();
    };

    return (
        <>
            <SystemListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <SsytemListTable
                isLoading={isLoading}
                itemList={data?.items}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}
            />
            <Outlet />
        </>
    );
};

