import { Button, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useUpdateFaq } from 'features/marketing/use-cases/update-faq';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddContactTypeFormField, AddContactTypeFormValues } from './types';





export const AddFaqModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-faq-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddContactTypeFormValues>();

    const { t } = useTranslation();


    const { isLoading, mutate } = useUpdateFaq();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const getInitialValues = {
        [AddContactTypeFormField.Flag]: undefined,
        [AddContactTypeFormField.Id]: undefined,
        [AddContactTypeFormField.QuestionAr]: undefined,
        [AddContactTypeFormField.QuestionEn]: undefined,
        [AddContactTypeFormField.AnswerAr]: undefined,
        [AddContactTypeFormField.AnswerEn]: undefined,

    };

    // console.log(editData, "editData")
    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        } else {
            form.resetFields();
        }

    }, [editData, form]);



    const handleUpdate = (formData: AddContactTypeFormValues) => {
        formData.Flag = "insert";
        formData.Id = editData?.Id;

        mutate(formData, { onSuccess: handleClose });
    };



    const onReset = () => {
        form.resetFields();
        onClose();
    };

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit FAQ' : 'Add FAQ'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddContactTypeFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <Form.Item<AddContactTypeFormValues>
                            label={"Question"}
                            name={AddContactTypeFormField.QuestionEn}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <TextArea
                                rows={3}
                                placeholder={"Question"}
                            />
                        </Form.Item>

                        <Form.Item<AddContactTypeFormValues>
                            label={"Answer"}
                            name={AddContactTypeFormField.AnswerEn}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <TextArea
                                rows={3}
                                placeholder={"Answer"}
                            />
                        </Form.Item>

                        <Form.Item<AddContactTypeFormValues>
                            label={"Question (Arabic)"}
                            name={AddContactTypeFormField.QuestionAr}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <TextArea
                                rows={3}
                                placeholder={"Question (Arabic)"}
                            />
                        </Form.Item>

                        <Form.Item<AddContactTypeFormValues>
                            label={"Answer (Arabic)"}
                            name={AddContactTypeFormField.AnswerAr}
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <TextArea
                                rows={3}
                                placeholder={"Answer (Arabic)"}
                            />
                        </Form.Item>

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" style={{ marginRight: '10px' }} loading={isLoading}>Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

