import { useSessionContext } from 'features/auth';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { User } from 'types/user';

import { useGetUser } from './use-cases';

export type ContextValue =
  | { user: undefined; status: 'error' }
  | { user: undefined; status: 'loading' }
  | { user: User; status: 'success' };

export const UserContext = createContext<ContextValue | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      'You tried to use UserContext outside the scope of the UserContextProvider.',
    );
  }

  return context;
};

export function UserContextProvider({ children }: { children: ReactNode }) {
  const { data } = useSessionContext();

  const userQueryResult = useGetUser("1", data?.userId as string, {
    enabled: Boolean(data?.userId),
    retry: false,
    onError: (error) => {
      console.error(error);
    },
  });

  const contextValue = useMemo(
    () =>
    ({
      status: userQueryResult.status,
      user: userQueryResult.data,
    } as ContextValue),
    [userQueryResult.status, userQueryResult.data],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
