import { DeleteOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Empty, Image, Modal, Skeleton, Spin, Switch, Tooltip } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Button, Column } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetItemImagesListById } from 'features/items/use-cases/get-item-images-list-by-id';
import { useGetItemStockListById } from 'features/items/use-cases/items-stocks-list-by-id';
import { useUploadItemImg } from 'features/items/use-cases/upload-item-img';
import { AddUserModal } from 'features/users/components/modals/add-user-modal';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { Volunteer } from 'types';

import { AddImgModal } from './model/add-img-modal';



const ItemImageTab = ({ id, tabs }: { id: number; tabs: string }) => {

    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            Item_Fk_Id: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
        });
    const { data, isLoading, refetch } = useGetItemImagesListById((params));

    const [isOpen, setIsOpen] = useState(false);

    const { isLoading: updateLoading, mutate } = useUploadItemImg();




    useEffect(() => {
        if (tabs === "4") {
            refetch();
        }
    }, [tabs]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }

    const handleOpenModel = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const menuItems = (record: any) => [
        {
            key: 'delete',
            label: <Button onClick={() => showDefaultConfirm(record, "delete")} type="text" danger icon={<DeleteOutlined />}>
                Delete
            </Button>,
        },
    ]

    const { confirm } = Modal;

    const showDefaultConfirm = (record: any, type: string) => {
        //console.log(record,"record");
        confirm({
            title: `Are you sure you want to ${type === "default" ? "Set default" : "Delete"} this Image?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleSetDefault(record, type);
            },
        });
    };

    const handleSetDefault = (record: any, type: string) => {
        const payload = {
            Flag: type === "default" ? "Default" : "Delete",
            Id: record?.Id,
            Item_Fk_Id: +record?.Item_Fk_Id,

        }
        mutate(payload, { onSuccess: refetch })
    };




    return (
        <div>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddImgModal onClose={handleCloseModal} id={id} />
            </div>


            <StyledTableWrapper>
                <StyledTableHeader>
                    <>
                        <RecordCountBadge
                            text={'Total Item Images'}
                            count={data?.TotalRecords || 0}
                        />

                        <Button onClick={handleOpenModel} type={"primary"}>
                            Add Image
                        </Button>
                    </>
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.page,
                        defaultPageSize: params.size,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : data?.itemImages}
                    className='cstm_table'

                >

                    <Column<any>
                        title={'Image'}
                        // dataIndex={'Size'}
                        // sorter={true}
                        // sortDirections={['ascend', 'descend', 'ascend']}
                        // defaultSortOrder={'ascend'}
                        render={(value: any, record: any) => {
                            return (
                                <Image src={record?.ProductImageSmall} alt='item img' height={"50px"} />
                            )
                        }}
                    />



                    <Column<any>
                        title={"Is Default"}
                        // dataIndex={'IsDefault'}
                        render={(_, record) => (
                            <Tooltip>
                                {/* <Spin spinning={updateLoading}> */}

                                <Checkbox
                                    defaultChecked={record.IsDefault}
                                    checked={record.IsDefault}
                                    disabled={updateLoading || data?.TotalRecords === 1}
                                    onChange={(isChecked) => showDefaultConfirm(record, "default")}
                                />
                                {/* </Spin> */}
                            </Tooltip>

                        )}
                    />

                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>

                            </>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default ItemImageTab

