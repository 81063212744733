import { Card, Col, Row } from "antd";




export default function CustomerNumberChart({ data }: { data: any }) {

    const mainData = data?.getDashboardData?.CustomerList[0];


    return (

        <Card title={"Customer Registration"} style={{ minHeight: "420px", marginTop:'20px'}}>
            <div className="cstm_regis">
                <div className="cstm_bx">
                    
                        <h2>ToDay</h2>
                        <h4>{mainData?.ToDayCustomer}</h4>
                    
                </div>
                <div className="cstm_bx">

                    
                        <h2>This Week</h2>
                        <h4>{mainData?.WeeklyCustomer}</h4>
                    
                </div>
                <div className="cstm_bx">
                   
                        <h2> This Month</h2>
                        <h4>{mainData?.MonthlyCustomer}</h4>
                    

                </div>

                <div className="cstm_bx">

                        <h2>This Year</h2>
                        <h4>{mainData?.YearlyCustomer}</h4>
                    </div>
                    <div className="cstm_bx">
                 
                        <h2>Total</h2>
                        <h4>{mainData?.TotalCustomer}</h4>
                    
                </div>
            </div>


            
              
                
            

        </Card>

    )
}
