import { Button, Form, Input } from 'antd';
import { useAddSystem } from 'features/system-setting/use-cases/add-system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleKeyDownThree } from 'utils/key-press';



export const AddSystemModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
  const formName = 'add-settings-form';
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<any>();

  const { t } = useTranslation();
  const { isLoading, mutate } = useAddSystem();

  const handleClose = () => {
    onClose();
    form.resetFields();
  }

  const onReset = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(editData);
    } else {
      form.resetFields();
    }

  }, [editData, form]);

  const handleUpdate = (formData: any) => {

    formData.Id = editData?.Id;
    formData.Flag = "Insert";
    mutate(formData, { onSuccess: onClose });

  };

  return (
    <>

      <div className={'popup'}>
        <div className='modal_head'>
          <button onClick={handleClose} className='close-btn'>
            &times;
          </button>
          <h4>{editData ? 'Edit Settings' : 'Add Settings'}</h4>
        </div>
        <div className='modal_body'>
          <Form
            id={formName}
            name={formName}
            form={form}
            layout="vertical"
            initialValues={'' as any}
            onFinish={handleUpdate}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
          >
            <Form.Item label="Field" name={'Field'}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('validateMessages.custom.nomsg'),
                },
              ]}
            >
              <Input placeholder="Enter Field" />
            </Form.Item>
            <Form.Item label="Value" name={'Value'}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('validateMessages.custom.nomsg'),
                },
              ]}
            >
              <Input placeholder="Enter Value"
                onKeyDown={handleKeyDownThree}
              />
            </Form.Item>
            <div className='form-button'>
              <Button type="primary" htmlType='submit' className="btn" loading={isLoading}>Save</Button>
              <Button onClick={onReset} danger type="primary"> Cancel</Button>
            </div>
          </Form>
        </div>
      </div >

    </>
  );
};


