import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateUserDTO,
  UserApiService,
} from 'features/users/api/user-api.service';
import i18n from 'locales/i18n';

import { userQueryKeys } from './query-keys';


const { createUser, updateStatus } = UserApiService();

export const useCreateUser = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateUserDTO) => {
      return createUser(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (user) => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });
        message.success(
          i18n.t('adminUsers.formStatus.userCreated', {
            fullName: `${user.first_name} ${user.last_name}`,
            role: user.role,
          }),
        );
      },
    },
  );
};


export const useUpdateStatus = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return updateStatus(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data: any) => {
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.lists(),
        });

        if (data?.ResponseStatus === 1) {
          message.success(data.ResponseMessage);
        } else {
          message.error(data.ResponseMessage);
        }
      },
    },
  );
};







