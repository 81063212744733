import { Button, Checkbox, Col, Form, Image, Input, Row } from 'antd';
import { useAddCustomSizeByItemsId } from 'features/items/use-cases/add-custom-size-by-item-id';
import { useGetCustomSizeByItemsId } from 'features/items/use-cases/get-custom-size-details-by-items-id';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import noImg from "../../../../../assets/No_Image_Available.jpg"
import { AddCategoryFormField, AddCategoryFormValues } from './types';


type CustomSizeDetail = {
    Value: number;
    Text: string;
    IsSelected: boolean;
};

export const AddCustomSizeModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'add-custom-size-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddCategoryFormValues>();

    const params = {
        Id: editData?.Id
    }

    const { data, isLoading: getLoading, refetch, isFetching } = useGetCustomSizeByItemsId((params), {
        retry: false,
    });

    const [checkboxData, setCheckboxData] = useState<CustomSizeDetail[]>([]);

    const [enabledCheck, setEnabledCheck] = useState(false);

    const { isLoading, mutate } = useAddCustomSizeByItemsId();

    const handleClose = () => {
        onClose();
        form.resetFields();
        setIsPreviewAvailable(true);
    }


    const getInitialValues = {
        [AddCategoryFormField.FullCategoryName]: undefined,
        [AddCategoryFormField.PhoneNumber]: undefined,
        [AddCategoryFormField.Role]: undefined,

    };

    useEffect(() => {
        if (editData) {
            form.setFieldsValue(editData);
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    useEffect(() => {
        setCheckboxData(data?.items?.customSizesDetails || []); // Ensure to set checkboxData when your data is available
        setEnabledCheck(editData?.IsCustom)
    }, [data]);

    const handleUpdate = () => {

        const selectedChecked = checkboxData
            .filter((item) => item.IsSelected)
            .map((item) => ({ CustomSizeId: item.Value }));


        const payload = {
            Flag: "Insert",
            CustomSizeTypeId: data?.items?.Id,
            Item_Fk_Id: editData?.Id,
            IsCustom: enabledCheck,
            TableTypeValue: selectedChecked
        }

        mutate(payload, { onSuccess: handleClose });

    };




    const handleCheckboxChange = (customSizeId: number) => {
        const updatedData = checkboxData.map((item) => {
            if (item.Value === customSizeId) {
                return { ...item, IsSelected: !item.IsSelected }; // toggle the IsSelected value
            }
            
return item;
        });

        setCheckboxData(updatedData); // update the state with the new values
    };


    const [isPreviewAvailable, setIsPreviewAvailable] = useState(true);

    const handleImageError = () => {
        setIsPreviewAvailable(false); // Disable preview when the image has an error
    };





    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{"Add Custom Size Detail"}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddCategoryFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >
                            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Col span={12}>
                                    <Form.Item<AddCategoryFormField>
                                        label={"Category List"}
                                    >
                                        <Input
                                            placeholder={"Category Name"}
                                            value={data?.items?.CategoryName}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ textAlign: 'center' }}>
                                    <Checkbox
                                        checked={enabledCheck}
                                        onChange={(e) => setEnabledCheck(e.target.checked)}
                                    >
                                        Is Custom Enabled
                                    </Checkbox>


                                </Col>
                            </Row>


                            <Image
                                height={120}
                                src={data?.items?.Image}
                                // onError={(e) => e.currentTarget.src = noImg}
                                fallback={noImg}
                                onError={handleImageError}
                                preview={isPreviewAvailable}
                            />

                            <Form.Item label="Available Sizes">
                                {checkboxData?.map((item, i) => (
                                    <div key={i}>
                                        <Checkbox
                                            disabled={enabledCheck}
                                            checked={item.IsSelected} // bind the checkbox checked state to IsSelected
                                            onChange={() => handleCheckboxChange(item.Value)} // handle checkbox change
                                        >
                                            {item.Text} {/* display the label for the checkbox */}
                                        </Checkbox>
                                    </div>

                                ))}
                            </Form.Item>


                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" loading={isLoading}>Add to Custom</Button>
                            <Button onClick={handleClose} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

