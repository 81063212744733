import { PaginatedRequestParams } from "api";

export enum CustomerFilterFormField {
    Name = 'Name',
    EmailId = 'EmailId',
    MobileNo = 'mobile',
    FromDate = 'FromDate',
    ToDate = 'ToDate',
    Status = 'StatusId',
}


export type CustomerFilterFormValues = {
    [CustomerFilterFormField.Name]: string | undefined;
    [CustomerFilterFormField.EmailId]: string | undefined;
    [CustomerFilterFormField.MobileNo]: string | undefined;
    [CustomerFilterFormField.FromDate]: string | undefined;
    [CustomerFilterFormField.ToDate]: string | undefined;
    [CustomerFilterFormField.Status]: boolean | undefined;

};

export type CustomerFilterFormValuesPaginated = CustomerFilterFormValues &
    PaginatedRequestParams;