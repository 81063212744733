import { apiClient } from '../../../api';

export type LoginDTO = {
  MemEmailId: string;
  MemPassword: string;
};

export type LoginResponse = {
  Item1: {
    access_token: string;
    MemberId: number;
    Name: string;
    MobileNo: string;
    EmailId: string;
  };
  Item2: number;
  Item3: string;
};

export type RefreshTokenDTO = {
  grant_type: 'refresh_token';
  token: string;
};

export type logoutDTO = {
  access_token?: string;
  refresh_token?: string;
  type?: string;
  MemId: number;
};

export const AuthApiService = () => {
  const login = async (payload: LoginDTO) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/account/login',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  const refreshToken = async (payload: RefreshTokenDTO) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/admin/login/refresh',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  const AuthLogoutService = async (payload: logoutDTO) => {
    const response = await apiClient<any>({
      endpoint: '/account/logout',
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    login,
    refreshToken,
    AuthLogoutService
  };
};
