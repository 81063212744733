import { PaginatedRequestParams } from "api";

export enum ItemsStocksFilterFormField {
    Brand = 'BrandId',
    Category = 'categoryId',
    SubCategory = 'SubCategoryId',
    ItemCode = 'branch_Fk_Id',
}



export type ItemsStocksFilterFormValues = {
    [ItemsStocksFilterFormField.Brand]: string | undefined;
    [ItemsStocksFilterFormField.Category]: string | undefined;
    [ItemsStocksFilterFormField.SubCategory]: string | undefined;
    [ItemsStocksFilterFormField.ItemCode]: string | undefined;

};

export type ItemsStocksFilterFormPaginated = ItemsStocksFilterFormValues &
    PaginatedRequestParams;