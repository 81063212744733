import { Col } from 'antd';
import logo2 from 'assets/log.png';
import { LoginForm } from 'features/auth/components';

// import { useTranslation } from 'react-i18next';
// import { colors } from 'styles';
import { StyledBackground, StyledCard, StyledRow } from './login.styles';

export const LoginView = () => {
  // const { t } = useTranslation();

  return (
    <StyledBackground align='middle' justify='center' className='sctn_login'>
      <Col xs={24} sm={18} md={12} lg={8}>

        <StyledCard className='sl_bx' >
          <StyledRow justify='center'>
            <Col style={{ textAlign: 'center' }}>
              <img src={logo2} alt="" style={{ width: '43%' }}
              />
              {/* <LogoSvg
                title={t('li3ibLogo')}
                color={colors.redBright}
                width={126}
                height={39}
              /> */}
            </Col>
          </StyledRow>

          <StyledRow className='slb_rw'>
            <Col xs={24} className='slbr_clm'>
              <LoginForm />
            </Col>
          </StyledRow>

          {/* <Row justify='center'>
            <Col>
              <Space direction='vertical' align='center'>
                <img src={KuwaitFlag} alt={t('login.flagDescription')} />
                <Typography.Text type='secondary'>
                  {t('login.countryDescription')}
                </Typography.Text>
              </Space>
            </Col>
          </Row> */}
        </StyledCard>
      </Col>
    </StyledBackground>
  );
};
