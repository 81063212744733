
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { ItemsSubCategoryFilterForm } from '../filter-form/items-sub-category-filter-form/items-sub-category-filter-form';
import { ItemsSubCategoryFilterFormValues } from '../filter-form/items-sub-category-filter-form/types';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsSubCategoryFilterFormValues;
    handleFilter: (values: ItemsSubCategoryFilterFormValues) => void;
};

export const ItemsSubCategoryListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    const formId = 'item-sub-category-list-filters-form';

    const screens = useBreakpoint();


    return (
        <>

            <ContentFilters formId={formId}>
                <ItemsSubCategoryFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
