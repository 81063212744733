import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { BrandsFilterFormPaginated, BrandsFilterFormValues } from '../components/brands-list-filter/types';
import { BrandsListTable } from '../components/brands-list-table/brands-list-table';
import { BrandsListTopBar } from '../components/top-bar/brands-list-topbar';
import { useGetBrandsLists } from '../use-cases';

const getInitialFilterValues: BrandsFilterFormValues = {
  Title: undefined,
  statusId: undefined,
  MobileNo: undefined,
  FromDate: undefined,
  ToDate: undefined,
  arabic: undefined,
};

export const BrandsView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<BrandsFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading } = useGetBrandsLists((params), {
    refetchOnWindowFocus: true, // Fetches data when window regains focus
    staleTime: 0, // No stale data, fetch fresh data every time
    cacheTime: 0, // Do not cache the data, fetch again
  });


  const handleFiltersChange = (values: BrandsFilterFormPaginated) => {
    updateParams(values);
    resetPage();
  };


  return (
    <>
      <BrandsListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
        tableParams={params}
      />

      <BrandsListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

