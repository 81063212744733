
export enum AddRoleFormField {
    Id = 'Id',
    Flag = 'Flag',
    RoleName = 'RoleName',
    IsActive = 'IsActive',
}

export type AddRoleFormValues = {
    [AddRoleFormField.Id]: number | undefined;
    [AddRoleFormField.Flag]: string;
    [AddRoleFormField.RoleName]: string;
    [AddRoleFormField.IsActive]: boolean;
}

export type AddRoleDTO = {
    Id: number | undefined;
    Flag: string;
    RoleName: string;
    IsActive: boolean;
};
