import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { FaqListFilterForm } from '../filter-form/faq-list-filter/faq-list-filter-form';
import { FaqFilterFormValues } from '../filter-form/faq-list-filter/types';
import { AddFaqModal } from '../models/add-faq-model/add-faq-model';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: FaqFilterFormValues;
    handleFilter: (values: FaqFilterFormValues) => void;
};

export const FaqListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'faq-list-filters-form';

    const screens = useBreakpoint();

    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddFaqModal onClose={handleCloseModal} editData={null} />
            </div>



            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModal}
                >
                    Add FAQ
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <FaqListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
