import { DeleteOutlined } from '@ant-design/icons';
import { Empty, Skeleton, Switch, Tooltip } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetCustomersAddress } from 'features/customers/use-cases/get-customer-address';
import { useGetItemsLists } from 'features/items/use-cases';
import { useGetItemSizeListById } from 'features/items/use-cases/get-item-size-list-by-id';
import { useGetItemStockListById } from 'features/items/use-cases/items-stocks-list-by-id';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Volunteer } from 'types';



const ItemStockTab = ({ id, tabs }: { id: number; tabs: string }) => {

    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            Item_Fk_Id: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
        });
    const { data, isLoading, refetch } = useGetItemStockListById((params));


    useEffect(() => {
        if (tabs === "3") {
            refetch();
        }
    }, [tabs]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }


    return (
        <div>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total Item Stock'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.page,
                        defaultPageSize: params.size,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : data?.itemStockLists}
                    className='cstm_table'

                >

                    <Column<any>
                        title={' Size Type'}
                        dataIndex={'Size'}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    // render={(value: any, record: any) => {
                    //     return (
                    //         <span className='arow_tab' onClick={() => navigateDetails(record)}>
                    //             <RightOutlined />
                    //             <h1>{record.Name}</h1>
                    //         </span>

                    //     )
                    // }}
                    />


                    <Column<any>
                        title={'Avialbe Qty'}
                        dataIndex={'Qty'}
                    />

                    <Column<any>
                        title={'Transit Qty'}
                        dataIndex={'InTransit_Qty'}
                    />

                    <Column<any>
                        title={'Sold Qty'}
                        dataIndex={'SoldQty'}
                    />

                    <Column<any>
                        title={'Total Qty'}
                        dataIndex={'TotalQty'}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default ItemStockTab

