
import { Empty, Skeleton, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { NotifyStatusDTO } from 'features/items/api/items-api.service';
import { useUpdateNotifyStatus } from 'features/items/use-cases/update-notify-status';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

import { ItemsNotifyFilterFormPaginated } from '../filter-form/items-notify-filter/types';



type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ItemsNotifyFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const ItemsNotifyTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };

    const { isLoading: statusLoading, mutate } = useUpdateNotifyStatus();



    const handleStatusChange = (id: number) => {

        const payload: NotifyStatusDTO = {
            Id: id,
            Flag: "ChangeStatus"
        }

        mutate(payload);
    }


    return (
        <div className='order_rtn'>

            < StyledTableWrapper >
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                < Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}

                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} />
                            : !itemList?.items && itemList?.TotalRecords ? <Skeleton active={true} /> : <Empty />
                    }}

                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.items}
                >


                    <Column<any>
                        title={'Item Name'}
                        dataIndex={'ItemName_En'}
                        sorter={(a, b) => {
                            if (!a.ItemName_En) return 1; // Sort null or undefined values to the end
                            if (!b.ItemName_En) return -1;

                            return a.ItemName_En.localeCompare(b.ItemName_En);
                        }}
                    />

                    <Column<any>
                        title={'Customer Name'}
                        dataIndex={'CustomerName'}
                        sorter={(a, b) => {
                            if (!a.CustomerName) return 1; // Sort null or undefined values to the end
                            if (!b.CustomerName) return -1;

                            return a.CustomerName.localeCompare(b.CustomerName);
                        }}


                    />


                    <Column<any>
                        title={'Notify On'}
                        dataIndex={'NotifyOn'}
                        sorter={(a, b) => {
                            if (!a.NotifyOn) return 1; // Sort null or undefined values to the end
                            if (!b.NotifyOn) return -1;

                            return a.NotifyOn.localeCompare(b.NotifyOn);
                        }}
                        render={(_, record) => (
                            <>
                                <h1>{record?.NotifyOn && moment(record?.NotifyOn).calendar("dd/mm/yyyy")}</h1>
                            </>
                        )}

                    />
                    <Column<any>
                        title={'Notify Receive On'}
                        dataIndex={'NotifyRecieveOn'}
                        sorter={(a, b) => {
                            if (!a.NotifyRecieveOn) return 1; // Sort null or undefined values to the end
                            if (!b.NotifyRecieveOn) return -1;

                            return a.NotifyRecieveOn.localeCompare(b.NotifyRecieveOn);
                        }}

                        render={(_, record) => (
                            <>
                                <h1>{record?.NotifyRecieveOn && moment(record?.NotifyRecieveOn).calendar("dd/mm/yyyy")}</h1>
                            </>
                        )}


                    />

                    <Column<any>
                        title={'Status'}
                        render={(_, record) => (
                            <h1>{record.IsNotify ? "Active" : "InActive"}</h1>

                        )}
                        sorter={(a, b) => {
                            if (!a.IsNotify) return 1; // Sort null or undefined values to the end
                            if (!b.IsNotify) return -1;

                            return a.IsNotify - b.IsNotify;
                        }}
                    />

                    <Column<any>
                        title={"Action"}
                        key={"action"}
                        render={(_, record) => (
                            <>
                                <Tooltip title={"Is Active"} >
                                    <Switch
                                        loading={statusLoading}
                                        checked={record.IsNotify}
                                        onChange={(checked) => handleStatusChange(record.Id)}
                                    />
                                </Tooltip>
                            </>
                        )}

                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
