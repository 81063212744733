import { Steps } from 'antd';
const { Step } = Steps;

const OrderStatusTimeline = ({ item }: { item: any }) => {
    // Function to get the index of the first ISStatus === 0
    const currentStepIndex = (item: any) => {
        return item.findIndex((status: any) => status.ISStatus === 0);
    };

    // Get the status list and filter it up to the first ISStatus === 0
    const statusList = item?.ItemStatusIconList;
    const stopIndex = currentStepIndex(statusList);
    const filteredStatusList = stopIndex !== -1 ? statusList.slice(0, stopIndex + 1) : statusList;

    return (
        <Steps current={filteredStatusList.length - 1} progressDot>
            {filteredStatusList.map((status: any, index: number) => {
                // const isActive = index === filteredStatusList.length - 1;  // Check if the current status is the last in the filtered list

                return (
                    <Step
                        key={index}
                        title={
                            <span>

                                {status.Status_En}
                            </span>
                        }
                        description={status.CreatedDate !== '0' ? status.CreatedDate : 'NA'}
                    />
                );
            })}
        </Steps >
    );
};

export default OrderStatusTimeline;
