import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ContactTypeListTable } from '../components/contact-type-list-table/contact-type-list-table';
import { ContactTypeFilterFormField, ContactTypeFilterFormPaginated, ContactTypeFilterFormValues } from '../components/filter-form/contact-type-list-filter/types';
import { ContactTypeListTopBar } from '../components/tob-bar/contact-type-list-topbar';
import { useGetContactTypeLists } from '../use-cases';

const getInitialFilterValues: ContactTypeFilterFormValues = {
  [ContactTypeFilterFormField.Type]: undefined,
  [ContactTypeFilterFormField.Status]: undefined,
};


export const ContactTypeListView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<ContactTypeFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetContactTypeLists((params), {
    retry: false,
  });


  const handleFiltersChange = (values: ContactTypeFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <ContactTypeListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <ContactTypeListTable
        isLoading={isLoading}
        isFetching={isFetching}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

