import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PromotionFilterFormField, PromotionFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: PromotionFilterFormValues) => void;
    initialValues: PromotionFilterFormValues;
    closeModal?: () => void;
};

export const PromotionListFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<PromotionFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const { data: subCategory, isLoading: subCatLoading } = useGetDropdownLists({ Flag: "GetSubCategoryListByParentId" });
    const { data: category, isLoading: catLoading } = useGetDropdownLists({ Flag: "GetGroupList" });
    const { data: brandData, isLoading: brandLoading } = useGetDropdownLists({ Flag: "GetBrandList" });
    const { data: offerTypeData, isLoading: offerLoading } = useGetDropdownLists({ Flag: "GetOfferTypeList" });


    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: PromotionFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };


    return (
        <Form<PromotionFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>
                        <Form.Item<PromotionFilterFormValues>
                            label={'Name'}
                            name={PromotionFilterFormField.Name}
                        >
                            <Input
                                placeholder={"Name"}
                            />
                        </Form.Item>

                        <Form.Item<PromotionFilterFormValues>
                            label={"Brand"}
                            name={PromotionFilterFormField.Brand}
                        >
                            <Select
                                loading={brandLoading}
                                showSearch
                                options={brandData?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Brand"}
                            />
                        </Form.Item>

                        <Form.Item<PromotionFilterFormValues>
                            label={"Group Category"}
                            name={PromotionFilterFormField.Group}
                        >
                            <Select
                                loading={catLoading}
                                showSearch
                                options={category?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Group Category"}
                            />
                        </Form.Item>

                        <Form.Item<PromotionFilterFormValues>
                            label={"Category"}
                            name={PromotionFilterFormField.SubCategory}
                        >
                            <Select
                                loading={subCatLoading}
                                showSearch
                                options={subCategory?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Category"}
                            />
                        </Form.Item>


                        <Form.Item<PromotionFilterFormValues>
                            label={"Offer Type"}
                            name={PromotionFilterFormField.OfferType}
                        >
                            <Select
                                loading={offerLoading}
                                showSearch
                                options={offerTypeData?.map((item: { Text: string; Value: number }) => ({
                                    label: item.Text,
                                    value: item.Value,
                                }))}
                                filterOption={(input, option) =>
                                    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={"Offer Type"}
                            />
                        </Form.Item>


                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
