import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { ItemsApiService } from '../api/items-api.service';
import { itemsQueryKeys } from './query-keys';


const { updateCategory } = ItemsApiService();


export const useUpdateCategory = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return updateCategory(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {
                queryClient.invalidateQueries({
                    queryKey: itemsQueryKeys.all,
                });

                if (data?.ResponseStatus === 1) {
                    message.success(data.ResponseMessage);
                } else {
                    message.error(data.ResponseMessage);
                }
            },
        },
    );
};