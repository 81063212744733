import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { handleError } from "api";
import { commonDeleteDTO } from "features/admin";

import { DeliveryApiService } from "../api/delivery-api.service";
import { DeliveryQueryKeys } from "./query-keys";



export const useDeliveryDelete = () => {
    const { message } = App.useApp();
    const queryClient = useQueryClient();

    const { commonDelete } = DeliveryApiService();

    return useMutation(
        (payload: commonDeleteDTO) => {
            return commonDelete(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: (data: any) => {

                queryClient.invalidateQueries({
                    queryKey: DeliveryQueryKeys.lists(),
                });

                if (data?.ResponseStatus === 1) {
                    message.success(data.ResponseMessage);
                } else {
                    message.error(data.ResponseMessage);
                }
            },
        },
    );
};