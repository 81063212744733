import { DeleteOutlined } from '@ant-design/icons';
import { Empty, Skeleton, Switch, Tooltip } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, ContentFilters } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetItemTransactionListById } from 'features/items/use-cases/get-item-transaction-byid';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Volunteer } from 'types';

import { ItemsListFilterForm } from '../../filter-form/items-list-filter/items-list-filter-form';
import { ItemsTransactionFilterForm } from './items-transaction-list-filter/items-transaction-list-filter-form';



const ItemTransactionTab = ({ id, tabs }: { id: number; tabs: string }) => {

    const getInitialFilterValues: any = {
        FromDate: undefined,
        ToDate: undefined,
        Name: undefined,
        EmailId: undefined,
        MobileNo: undefined,
        TransactionId: undefined,
        PaymentType: undefined,
        InvoiceType: undefined,
        PaymentStatus: undefined,
        BrandName_En: undefined,
        IsCustomItems: undefined,
        StatusText: undefined,
    };


    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            ItemId: id,
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });
    const { data, isLoading, refetch } = useGetItemTransactionListById((params));

    const handleFiltersChange = (values: any) => {
        updateParams(values);
        resetPage();
    };

    useEffect(() => {
        if (tabs === "5") {
            refetch();
        }
    }, [tabs]);

    const formId = "transaction-items-form"

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }


    return (
        <div>
            <ContentFilters formId={formId}>
                <ItemsTransactionFilterForm
                    formId={formId}
                    handleFilter={handleFiltersChange}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total Item Stock'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.page,
                        defaultPageSize: params.size,
                        total: data?.TotalRecords
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : data?.getItemTransactions}
                    className='cstm_table'

                >

                    <Column<any>
                        title={'Customer Name'}
                        dataIndex={'Name'}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    // render={(value: any, record: any) => {
                    //     return (
                    //         <span className='arow_tab' onClick={() => navigateDetails(record)}>
                    //             <RightOutlined />
                    //             <h1>{record.Name}</h1>
                    //         </span>

                    //     )
                    // }}
                    />


                    <Column<any>
                        title={'TansactionId'}
                        dataIndex={'TransactionId'}
                    />

                    <Column<any>
                        title={'Order Date'}
                        dataIndex={'OrderDate'}
                    />

                    <Column<any>
                        title={'Selling Price'}
                    // dataIndex={'TotalSalesPrice'}
                    />

                    <Column<any>
                        title={'Discount'}
                    // dataIndex={'TotalQty'}
                    />


                    <Column<any>
                        title={'Delievery Charge'}
                        dataIndex={'DeliveryCharge'}
                    />

                    <Column<any>
                        title={'Amount'}
                        dataIndex={'AmountPaid'}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'StatusText'}
                    />

                    <Column<any>
                        title={'Payment Status'}
                        dataIndex={'PaymentStatus'}

                    />

                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default ItemTransactionTab

