import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsCategoryFilterFormPaginated, ItemsCategoryFilterFormValues } from '../components/filter-form/items-category-filter-form/types';
import { ItemsCategoryTable } from '../components/items-category-table/items-category-table';
import { ItemsCategoryListTopBar } from '../components/top-bar/item-category-list';
import { useGetItemsCategoryLists } from '../use-cases/get-items-category';;

const getInitialFilterValues: ItemsCategoryFilterFormValues = {
    title: undefined,
    status: undefined,
};


export const ItemsCategoryListView = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsCategoryFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch } = useGetItemsCategoryLists((params));


    const handleFiltersChange = (values: ItemsCategoryFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsCategoryListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
                tableParams={params}
            />

            <ItemsCategoryTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

