export enum AddItemsFormField {
    Flag = 'Flag',
    Id = "Id",
    ItemName_En = 'ItemName_En',
    ItemName_Ar = "ItemName_Ar",

    ItemDescription_En = 'ItemDescription_En',
    ItemDescription_Ar = "ItemDescription_Ar",
    OtherInformation_En = 'OtherInformation_En',
    OtherInformation_Ar = "OtherInformation_Ar",

    Group_Fk_Id = 'Group_Fk_Id',
    Category_Fk_Id = 'Category_Fk_Id',
    Brand_Fk_Id = 'Brand_Fk_Id',
    Item_Code = 'Item_Code',

    ItemPrice = 'ItemPrice',
    OfferType_Fk_Id = 'OfferType_Fk_Id',
    Offer = 'Offer',
    OfferPrice = 'OfferPrice',
    ItemCellableQty = "ItemCellableQty",
    IsDiscount = 'IsDiscount',
}


export type AddItemsFormValues = {
    [AddItemsFormField.Flag]: string;
    [AddItemsFormField.Id]: string;
    [AddItemsFormField.ItemName_En]: string;
    [AddItemsFormField.ItemName_Ar]: string;
    [AddItemsFormField.ItemDescription_En]: string;
    [AddItemsFormField.ItemDescription_Ar]: string;
    [AddItemsFormField.OtherInformation_En]: string;
    [AddItemsFormField.OtherInformation_Ar]: string;
    [AddItemsFormField.Group_Fk_Id]: string;
    [AddItemsFormField.Category_Fk_Id]: string;
    [AddItemsFormField.Brand_Fk_Id]: string;
    [AddItemsFormField.Item_Code]: string;
    [AddItemsFormField.ItemPrice]: number;
    [AddItemsFormField.OfferType_Fk_Id]: string | undefined;
    [AddItemsFormField.Offer]: string | undefined;
    [AddItemsFormField.OfferPrice]: number | undefined;
    [AddItemsFormField.ItemCellableQty]: number | undefined;
    [AddItemsFormField.IsDiscount]: any;
}