import { Card, Image } from 'antd'
import { Loader } from 'components';
import { useGetOrderListDetails } from 'features/order-mannagemnet/use-cases/get-order-details';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { formatValue } from 'utils/formate-value';

import noImg from '../../../../assets/No_Image_Available.jpg';



const OrderReturnListDetailsPage = ({ id }: { id: string }) => {

    const { data, isLoading } = useGetOrderListDetails(id);

    const details = data?.getOrderDetails || {};
    const customerDetails = details?.CustomerDetailsList?.[0] || {};
    const customerAddress = details?.CustomerAddressList?.[0] || {};
    // const ItemStatusHistoryList = details?.ItemStatusHistoryList || {};

    const ListForReturn = details?.ReturnList || [];


    if (isLoading) return <Loader />;

    return (

        <>

            <div>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={ROUTES.ORDER_LIST} >Order Management</Link>
                        </li>

                        <li className="breadcrumb-item " aria-current="page">
                            <Link to={ROUTES.ORDER_RETURN} >Order Return</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            Order Return Detail/{id}
                        </li>
                    </ul>
                </nav>


                <Card className='prfle_crd ordr_dtl_crd' title=""  >
                    <div className='order_dtl' style={{ marginBottom: 0 }}>

                        {/* Order Information */}
                        <div className='dtl_bx'>
                            <p>Order Id: <span>{details.OrderId || "N/A"}</span></p>
                            <p>Order Date: <span>{details.OrderDate || "N/A"}</span></p>
                            <p>Is Customer Self Pickup: <span>{details.IsPickupCustomer ? "Yes" : "No"}</span></p>
                            <p>Estimated Delivery Days: <span>{details.EstDeliveryDays || "N/A"}</span></p>
                        </div>

                        {/* Customer Information */}
                        <div className='dtl_bx'>
                            <p>Name: <span>{customerDetails.CustomerName || "N/A"}</span></p>
                            <p>Mobile: <span>{customerDetails.MobileNo || "N/A"}</span></p>
                            <p>Email: <span>{customerDetails.EmailId || "N/A"}</span></p>
                            <p>Address: <span>{customerAddress.Address || "N/A"}</span></p>
                        </div>

                        <div className='dtl_bx'>
                            <p>Transaction Id: <span>{details.TransactionId || "N/A"}</span></p>
                            <p>Payment Type: <span>{details.PaymentType || "N/A"}</span></p>
                            <p>Sub Total: <span>{formatValue(details.TotalSalesPrice)}</span></p>
                            <p>Delivery Charge: <span>{formatValue(details.DeliveryCharge)}</span></p>
                            <p>Grant Total: <span>{formatValue(details.TotalSalesPrice + details.DeliveryCharge)}</span></p>
                        </div>

                    </div>

                </Card>
            </div>

            <Card className='prfle_crd' title="Sales Return"  >
                <table className='order_dtl_table'>
                    <thead>
                        <tr style={{ fontWeight: "700" }}>
                            <th>Item Name</th>
                            <th>Images</th>
                            <th>Original Qty</th>
                            <th>Return Qty</th>
                            <th>Return Amt</th>
                            <th>Return Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ListForReturn?.map((row: any, index: number) => (
                            <tr key={index}>
                                <td>{row.ItemName_En}</td>
                                <td><Image src={row?.ProductImage} width={80} height={50} onError={(e) => e.currentTarget.src = noImg} /></td>
                                <td>{row.OrgQty}</td>
                                <td>{row.ReturnQty}</td>
                                <td>{formatValue(row.ReturnAmt)}</td>
                                <td>{row.ReturnDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
        </>
    )
}

export default OrderReturnListDetailsPage
