import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AllItemsReviewsFilterFormField, AllItemsReviewsFilterFormValues } from './types';


const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: AllItemsReviewsFilterFormValues) => void;
    initialValues: AllItemsReviewsFilterFormValues;
    closeModal?: () => void;
};

export const AllItemsReviewsFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AllItemsReviewsFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();

    const { data: customerData, isLoading: custLoading } = useGetDropdownLists({ Flag: "GetCustomerList" });

    const onReset = () => {
        form.resetFields();
        handleFilter(initialValues);
    };

    const onFinish = (formValues: AllItemsReviewsFilterFormValues) => {
        handleFilter(formValues);
        closeModal?.();
    };

    const statusData = [
        { value: 1, label: "Approve" },
        { value: 2, label: "Rejected" },
        { value: 3, label: "Pending" },

    ];


    const ratingData = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
    ];


    return (
        <Form<AllItemsReviewsFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper className='cf_frm' style={{ width: '100%' }}>
                <StandardItemsWrapper className='cf_frm_row' style={{ width: '100%' }}>
                    <div className='invoice_list' style={{ width: '100%' }}>


                        <Form.Item<AllItemsReviewsFilterFormValues>

                            label={"Customer"}
                            name={AllItemsReviewsFilterFormField.CustomerName}
                        >
                            <Input
                                allowClear
                                // loading={custLoading}
                                // showSearch
                                // options={customerData?.map((item: { Text: string; Value: number }) => ({
                                //     label: item.Text,
                                //     value: item.Value,
                                // }))}
                                // filterOption={(input, option) =>
                                //     typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                // }
                                placeholder={"Customer Name"}
                            />
                        </Form.Item>


                        <Form.Item<AllItemsReviewsFilterFormValues>
                            label={"Item Name"}
                            name={AllItemsReviewsFilterFormField.ItemName_En}
                        >
                            <Input
                                placeholder={"Item Name"}
                            />
                        </Form.Item>

                        <Form.Item<AllItemsReviewsFilterFormValues>
                            label={"Rating"}
                            name={AllItemsReviewsFilterFormField.Rating}
                        >
                            <Select
                                options={ratingData}
                                placeholder={t('events.formEvents.currency.placeholder')}
                            />
                        </Form.Item>


                        <Form.Item<AllItemsReviewsFilterFormValues>
                            label={"Status"}
                            name={AllItemsReviewsFilterFormField.ReviewStatus}
                        >
                            <Select
                                showSearch
                                options={statusData}
                                placeholder={"Status"}
                            />





                        </Form.Item>

                        {screens.lg && (
                            <ContentFilters.ButtonsWrapper className='filter_btn'>
                                <Button type='primary' htmlType='submit'>
                                    {t('common.buttons.filter', { count: 1 })}
                                </Button>
                                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                            </ContentFilters.ButtonsWrapper>
                        )}
                    </div>
                </StandardItemsWrapper>
            </ContentFilters.FormItemsWrapper>


        </Form>
    );
};
