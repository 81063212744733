import * as React from 'react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Option } from 'types';


const MainFiltersContext = React.createContext<
  | {
    facilityOptions: [Option[], Dispatch<SetStateAction<Option[]>>];
    companyOptions: [Option[], Dispatch<SetStateAction<Option[]>>];
    clientTypeOptions: [Option[], Dispatch<SetStateAction<Option[]>>];
    params: [any, Dispatch<SetStateAction<any>>];
    fetchParams: [
      { facility: object; company: object; clientType: object },
      Dispatch<
        SetStateAction<{
          facility: object;
          company: object;
          clientType: object;
        }>
      >,
    ];
  }
  | undefined
>(undefined);

export const MainFiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {

  const params = React.useState<any>({
    company_type: undefined,
    // company_id: user?.company_id || undefined,
    facility_id: undefined,
  });

  const facilityOptions = useState<Option[]>([]);
  const companyOptions = useState<Option[]>([]);
  const clientTypeOptions = useState<Option[]>([]);
  const fetchParams = useState<{
    facility: object;
    company: object;
    clientType: object;
  }>({
    facility: {},
    company: {},
    clientType: {},
  });

  const value = useMemo(() => {
    return {
      params,
      facilityOptions,
      clientTypeOptions,
      companyOptions,
      fetchParams,
    };
  }, [facilityOptions, clientTypeOptions, companyOptions, fetchParams, params]);

  return (
    <MainFiltersContext.Provider value={value}>
      {children}
    </MainFiltersContext.Provider>
  );
};

export const useMainFiltersContext = () => {
  const context = React.useContext(MainFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useMainFiltersContext must be used within a MainFiltersProvider',
    );
  }

  return context;
};
