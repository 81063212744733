import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { NewsListFilterForm } from '../filter-form/news-list-filter/news-list-filter-form';
import { NewsFilterFormValues } from '../filter-form/news-list-filter/types';
import { AddNewsModal } from '../models/add-news-model/add-news-model';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: NewsFilterFormValues;
    handleFilter: (values: NewsFilterFormValues) => void;
};

export const NewsListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'news-list-filters-form';
    const screens = useBreakpoint();


    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddNewsModal onClose={handleCloseModal} editData={null} />
            </div>


            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModal}
                >
                    Add News
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <NewsListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
