import { AdminDetailTopBar } from 'features/users/components/top-bar/admin-detail-top-bar';
// import {
//   // allAvailableRolesExceptUser,
//   allAvailableRolesForLoggedInUser,
//   USER_ROLES,
// } from 'features/users/permissions';
import { useParams } from 'react-router-dom';


export const AdminDetailView = () => {
  const { id } = useParams();
  if (!id) return null;


  return (
    <>

      <AdminDetailTopBar
        id={id}
      />

    </>
  );
};
