import { PaginatedRequestParams } from "api";

export enum ItemsNotifyFilterFormField {
    Customer_Name = "CustomerName",
    FromDate = 'FromDate',
    ToDate = 'ToDate',
    Status = 'statusId',
}

export type ItemsNotifyFilterFormValues = {
    [ItemsNotifyFilterFormField.Customer_Name]: string | undefined;
    [ItemsNotifyFilterFormField.FromDate]: any | undefined;
    [ItemsNotifyFilterFormField.ToDate]: any | undefined;
    [ItemsNotifyFilterFormField.Status]: string | undefined;

};

export type ItemsNotifyFilterFormPaginated = ItemsNotifyFilterFormValues &
    PaginatedRequestParams;