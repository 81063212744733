import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Skeleton, Space, Switch, Tooltip } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateCommonStatus } from 'features/admin/use-cases/update-common-status';
import { Dispatch, SetStateAction, useState } from 'react';

import { CMSTypeFilterFormValuesPaginated } from '../filter-form/cms-type-filter/types';
import { EditCMSTypeModal } from '../models/cms-type-model/cms-type-model';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: CMSTypeFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const CMSTypeTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => {

        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
        });

    };


    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);

    const menuItems = (record: any) => [
        {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenModel(record)} icon={<EditOutlined />} >Edit</Button>,
        },
    ]



    const handleOpenModel = (record: any) => {
        setOpenEdit(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    const handleCloseModal = () => {
        setOpenEdit(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }
    const { mutate: mutateUpdateStatus } = useUpdateCommonStatus();


    return (
        <div className='order_rtn'>

            <div className={`cstmrightmdl ${openEdit && "show"}`}>
                <EditCMSTypeModal onClose={handleCloseModal} editData={editData} />
            </div>



            <StyledTableWrapper>
                {/* <Card> */}
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={itemList?.items}

                >


                    <Column<any>
                        title={'Type'}
                        dataIndex={'Type_En'}
                        sorter={(a, b) => a.Type_En.localeCompare(b.Type_En)}
                    // sorter
                    />

                    <Column<any>
                        title={'Type (Arabic)'}
                        dataIndex={'Type_AR'}
                        sorter={(a, b) => a.Type_AR.localeCompare(b.Type_AR)}
                    // sorter
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'IsActive'}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                        // sorter
                        render={(_, record) => (
                            <h1>{record?.IsActive ? "Active" : "InActive"}</h1>
                        )}
                    />

                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        // dataIndex={'Status'}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>

                                <Tooltip>

                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        onChange={(isActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "CMSTypeChangeStatus",
                                                Status: isActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.IsActive = isActive; // Ensure it's an integer
                                        }}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }}
                                    />
                                </Tooltip>
                            </Space>

                        )}
                    />
                </Table>
                {/* </Card> */}
            </StyledTableWrapper >
        </div>
    );
};
