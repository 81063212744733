import { Button, Input, message } from 'antd';
import { useUploadItemImg } from 'features/items/use-cases/upload-item-img';
import { useState } from 'react';

export const AddImgModal = ({ onClose, id }: { onClose: () => void, id: any }) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null); // To store the selected image
    const { isLoading, mutate } = useUploadItemImg();

    const [fileName, setFileName] = useState<string>('');

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            // File validation: size, type, and dimensions
            const validFileSize = file.size <= 1 * 1024 * 1024; // 1 MB
            const validFileType = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);

            if (validFileSize && validFileType) {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = function () {
                    const validDimensions = img.width >= 1020 && img.height >= 1280;
                    if (validDimensions) {
                        setSelectedImage(file);
                        setFileName(file.name);
                    } else {
                        message.error("Image dimensions should be at least 1020x1280.");
                        setFileName("");
                    }
                };
            } else {
                message.error("Invalid file. Please ensure the file is an image (JPEG, JPG, PNG) under 1MB.");
                setFileName("");
            }
        }
    };


    const handleSubmit = () => {
        if (selectedImage) {
            console.log(selectedImage, "selectedImage")
            const formData = new FormData();
            formData.append('File', selectedImage);
            // formData.append('IsDefault', selectedImage);
            formData.append('Flag', "Insert");
            formData.append('ItemDetails_Fk_Id', "0");
            formData.append('Item_Fk_Id', id);

            mutate(formData, { onSuccess: handleClose });

        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Add Image</h4>
                </div>
                <div className='modal_body add_img_mdl'>
                    {/* <Input
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        onChange={handleImageChange}
                        required
                        multiple={false}
                    /> */}

                    <Input
                        prefix={<Button type="primary" size="small" onClick={() => document.getElementById('fileInput')?.click()}>Choose File</Button>}
                        value={fileName}
                        readOnly
                    />
                    <input
                        type='file'
                        id='fileInput'
                        style={{ display: 'none' }}
                        accept=".jpg,.png,.jpeg"
                        onChange={handleImageChange}
                    />

                    <div >
                        <div className='note_dtls'>
                            <h2>Note:</h2>
                            <p>File size should not greater than 1 mb.</p>
                            <p>Image type should be jpeg or jpg and png.</p>
                            <p>Minimum image dimensions should be 1020x1280.</p>
                        </div>
                        <Button
                            className='form-button uplod_btn'
                            onClick={handleSubmit}
                            type='primary'
                            disabled={!selectedImage || isLoading}
                            loading={isLoading}
                        >
                            {isLoading ? 'Uploading...' : 'Upload Image'}
                        </Button>
                    </div>
                </div>

            </div>
        </>
    );
};
