import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { Card, Checkbox, Form, Select, Skeleton, } from 'antd';
import { Button } from 'components';
import { updateUserMenuPermissionDTO } from 'features/users/api/user-api.service';
import { useAssignRoleToUser } from 'features/users/use-cases/assign-role-to-user';
import { useGetAllRoles } from 'features/users/use-cases/get-all-role';
import { useGetMenutree } from 'features/users/use-cases/get-menutree';
import { useGetUsersDetailsById } from 'features/users/use-cases/get-user-id-data';
import { useGetUserRoleById } from 'features/users/use-cases/get-user-role-by-id';
import { useUpdateUserMenuPermission } from 'features/users/use-cases/update-user-menu-permission';
import { useUserContext } from 'features/users/user-context';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { AddUserModal } from '../modals/add-user-modal';
import { ChangePasswordModal } from '../modals/change-password';


type UsersTopBarProps = {
  id: string;
};



export const AdminDetailTopBar = ({
  id
}: UsersTopBarProps) => {
  useState<any>();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  // const [profileModal, setProfileModal] = useState<boolean>(false);


  const [changePassModel, setChangePassModel] = useState<boolean>(false);

  const navigate = useNavigate();

  const { user } = useUserContext();

  const handleCloseModal = () => {
    setShowAddUserModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddUserModal(true);
    // setUserEditdata()
    document.body.style.overflow = 'hidden';
  }

  // const handleOpenProfileModal = () => {
  //   setProfileModal(true);
  //   document.body.style.overflow = 'hidden';
  // }

  // const handleCloseProfileModal = () => {
  //   setProfileModal(false);
  //   document.body.style.overflow = 'visible';
  // }

  const params = {
    MemId: id
  }


  const userParams = {
    PeopleId: id
  }




  const { data, isLoading } = useGetUsersDetailsById(params as any);

  const { data: allRoles, isLoading: roleRolling } = useGetAllRoles();
  const { data: userRole, refetch: roleIdRefetch } = useGetUserRoleById(userParams as any);

  const { mutate: mutateMenuPermission, isLoading: updateMenuLoading } = useUpdateUserMenuPermission();
  const { mutate: assignRoleMutate } = useAssignRoleToUser();

  const [role, setRole] = useState("");


  const mneuTreeParams = {
    PeopleId: id,
    RoleId: role,
    Flag: "GetPrivilegesByApplicationUsers"
  }
  const { data: menuTree, refetch, isLoading: menuGetLoading } = useGetMenutree(mneuTreeParams as any, { enabled: false });

  useEffect(() => {
    if (userRole?.UserRole === 0 || userRole?.UserRole) {
      setRole(userRole?.UserRole)
    }
  }, [userRole])

  useEffect(() => {
    if (role || role == "0") {
      refetch();
    }
  }, [role])



  const handleClosePassword = () => {
    setChangePassModel(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenPassword = () => {
    setChangePassModel(true);
    document.body.style.overflow = 'hidden';
  }

  const handleChangeRole = (value: any) => {
    setRole(value);
    const assignPayload = {
      PeopleId: +id,
      RoleId: value,
      UserId: user?.UserDetails?.MemId
    }
    assignRoleMutate(assignPayload, { onSuccess: () => roleIdRefetch })
  };

  const [updatedPermissions, setUpdatedPermissions] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  useEffect(() => {
    setUpdatedPermissions(menuTree?.PermissionsTypes)
  }, [menuTree])

  // Function to handle checkbox change
  const handleCheckboxChange = (pageId: any, privilageId: any, isParent = false) => {
    setUpdatedPermissions((prev: any[]) => {
      // Toggle the parent permission
      let updated = prev.map(permission =>
        permission.PageId === pageId && permission.PrivilageId === privilageId
          ? { ...permission, IsSelected: !permission.IsSelected }
          : permission
      );

      // If the row is a parent, update all its child permissions
      if (isParent) {
        const isSelected = !prev.find(
          permission => permission.PageId === pageId && permission.PrivilageId === privilageId
        )?.IsSelected;

        const childMenus = getChildMenus(pageId);
        childMenus.forEach((child: { PageId: any; }) => {
          updated = updated.map(permission =>
            permission.PageId === child.PageId && permission.PrivilageId === privilageId
              ? { ...permission, IsSelected: isSelected }
              : permission
          );
        });
      }

      return updated;
    });
  };


  // Handle "select all" checkboxes in the header
  const handleSelectAll = (privilageId: any, isSelected: boolean) => {
    setUpdatedPermissions((prev: any[]) =>
      prev.map(permission =>
        permission.PrivilageId === privilageId
          ? { ...permission, IsSelected: isSelected }
          : permission
      )
    );
  };

  // Toggle show/hide for parent rows
  const toggleExpand = (parentId: number) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(parentId)
        ? prevExpandedRows.filter((pId) => pId !== parentId)
        : [...prevExpandedRows, parentId]
    );
  };

  // Helper to check if all checkboxes in the column are selected
  const isAllSelected = (privilageId: any) => {
    return updatedPermissions?.filter((permission: { PrivilageId: any; }) => permission.PrivilageId === privilageId)
      .every((permission: { IsSelected: any; }) => permission.IsSelected);
  };

  // Filter parent menus
  const parentMenus = menuTree?.MenuPagesList?.filter((page: { IsParent: number; }) => page.IsParent === 1);

  // Filter child menus for a specific parent
  const getChildMenus = (parentId: any) => {
    return menuTree?.MenuPagesList?.filter((page: { ParentId: any; IsParent: number; }) => page.ParentId === parentId && page.IsParent === 2);
  };


  // Function to update user menu tree
  const updateUserMenuTree = (privileges: string) => {
    const payload: updateUserMenuPermissionDTO = {
      Flag: 'SetPrivilegesByApplicationUsers',
      PeopleId: +id,
      Privileges: privileges,
      UserId: user?.UserDetails?.MemId,
    };

    mutateMenuPermission(payload, { onSuccess: () => refetch() });
  };



  const generatePrivilegesString = (permissions: any[]) => {
    return permissions
      .filter((item) => item.IsSelected) // Filter selected items
      .map((item) => `${item.PageId}-${item.PrivilageId}`) // Format PageId-PrivilageId
      .join(','); // Join into comma-separated string
  };

  // Handle form submission
  const handleSubmit = () => {
    const privileges = generatePrivilegesString(updatedPermissions);
    updateUserMenuTree(privileges);
  };

  // Handle grant all (check all checkboxes)
  const handleGrantAll = () => {
    const allGranted = updatedPermissions.map((permission: any) => ({
      ...permission,
      IsSelected: true,
    }));

    setUpdatedPermissions(allGranted);
    const privileges = generatePrivilegesString(allGranted);
    updateUserMenuTree(privileges);
  };

  // Handle revoke all (uncheck all checkboxes)
  const handleRevokeAll = () => {
    const allRevoked = updatedPermissions.map((permission: any) => ({
      ...permission,
      IsSelected: false,
    }));

    setUpdatedPermissions(allRevoked);
    const privileges = generatePrivilegesString(allRevoked);
    updateUserMenuTree(privileges);
  };


  const handleNavigate = () => {
    navigate(ROUTES.ROLE_PERMISSION_MANAGEMENT)
  }

  return (
    <>
      {showAddUserModal ? (
        <div className={`cstmrightmdl ${showAddUserModal && "show"}`}>
          <AddUserModal onClose={handleCloseModal} userEditdata={data?.getMemberData} />
        </div>
      ) : ''}

      {changePassModel ? (
        <div className={`cstmrightmdl ${changePassModel && "show"}`}>
          <ChangePasswordModal id={id} onClose={handleClosePassword} onSuccess={handleClosePassword} />
        </div>
      ) : ''}


      {/* <div className={`cstmrightmdl upld_modl ${profileModal && "show"}`}>
        <ProfileImageUpload id={id} onClose={handleCloseProfileModal} gallaryModal={profileModal} setProfileImage={setProfileImage} />
      </div> */}

      <Card className='prfle_crd' title="My Profile" extra={
        <>

          <Button
            type="primary"
            onClick={handleOpenPassword}>
            Change Password
          </Button>

          <Button
            type="primary"
            onClick={handleOpenModal}>
            Edit
          </Button>


        </>
      }  >



        <div className='profile_data'>

          <div className='user_d'>
            {isLoading ?
              <>

                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
              </>
              :
              <>

                <p>User Id : <span>{data?.getMemberData?.MemUserID}</span></p>
                <p>First Name : <span>{data?.getMemberData?.MemFirstName}</span></p>
                <p>Last Name : <span>{data?.getMemberData?.MemLastName}</span></p>
                <p>Mobile No : <span>{data?.getMemberData?.MemMobileNo}</span></p>
                <p>Email : <span>{data?.getMemberData?.MemEmailId}</span></p>
                <p>Role : <span>{data?.getMemberData?.MemStatus_FK_Id === 1 ? "Active" : "InActive"}</span></p>
              </>}
          </div>

        </div>

      </Card>


      <Card title="Set Permission" className='role_card'>
        <Button className="mnge_btn" onClick={handleNavigate}>
          <PlusOutlined />
          Mange Role
        </Button>

        <div className='role_head'>
          {roleRolling ?
            <Skeleton.Input active />
            :
            <Form.Item
              label={"Select Role"}
            >

              <Select
                options={allRoles?.getRoles.map((item: any) => ({ "value": item?.Value, "label": item?.Text }))}
                placeholder={"Select Role"}
                style={{ width: "250px" }}
                onChange={handleChangeRole}
                value={role}
              />
            </Form.Item>
          }



          <div className='role_btn'>
            {menuGetLoading ? (
              <>
                <Skeleton.Button active size="default" shape="round" />
                <Skeleton.Button active size="default" shape="round" />
                <Skeleton.Button active size="default" shape="round" />
              </>
            ) :
              <>
                <Button type="primary" onClick={handleSubmit} loading={updateMenuLoading}>
                  Save
                </Button>
                <Button type="primary" onClick={handleGrantAll} loading={updateMenuLoading}>
                  Grant All
                </Button>
                <Button type="primary" onClick={handleRevokeAll} loading={updateMenuLoading}>
                  Revoke ALl
                </Button>
              </>}
          </div>
        </div>

        {menuGetLoading ?
          <Skeleton active={true} paragraph={{
            rows: 15,
          }} />
          :

          <>

            {
              menuTree?.ColumnNames.length > 0 &&
              <div>
                <table className='role_table'>
                  <thead>
                    <tr>
                      <th>Pages</th>
                      {menuTree?.ColumnNames?.map((col: any) => (
                        <th key={col.PrivilageId}>
                          <label>

                            <Checkbox
                              checked={isAllSelected(col.PrivilageId)}
                              onChange={(e) => handleSelectAll(col.PrivilageId, e.target.checked)}
                            />
                            {col.ColumnName}
                          </label>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {parentMenus?.map((parentMenu: any) => (
                      <React.Fragment key={parentMenu.PageId}>
                        <tr style={{ marginTop: "10px" }} className='parent_tr'>
                          {/* Parent menu with expandable row */}
                          <td>
                            <button onClick={() => toggleExpand(parentMenu.PageId)}>
                              {expandedRows.includes(parentMenu.PageId) ? '-' : '+'}
                            </button>
                            <span className='pge_nme'> {parentMenu.MenuName}</span>
                          </td>
                          {menuTree?.ColumnNames.map((col: any) => {
                            const permission = updatedPermissions?.find(
                              (perm: any) => perm.PageId === parentMenu.PageId && perm.PrivilageId === col.PrivilageId
                            );

                            return (
                              <td key={col.PrivilageId}>
                                <Checkbox
                                  checked={permission?.IsSelected || false}
                                  onChange={() => handleCheckboxChange(parentMenu.PageId, col.PrivilageId, true)}
                                />
                              </td>
                            );
                          })}
                        </tr>
                        {/* Display child menus if parent is expanded */}
                        {expandedRows.includes(parentMenu.PageId) &&
                          getChildMenus(parentMenu.PageId).map((childMenu: any) => (
                            <tr key={childMenu.PageId} style={{ marginTop: "5px" }}>
                              <td style={{ paddingLeft: '20px' }}>{childMenu.MenuName}</td>
                              {menuTree?.ColumnNames.map((col: any) => {
                                const permission = updatedPermissions.find(
                                  (perm: any) => perm.PageId === childMenu.PageId && perm.PrivilageId === col.PrivilageId
                                );

                                return (
                                  <td key={col.PrivilageId}>
                                    <Checkbox
                                      checked={permission?.IsSelected || false}
                                      onChange={() => handleCheckboxChange(childMenu.PageId, col.PrivilageId)}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>}
          </>}
      </Card >
    </>
  );
};
