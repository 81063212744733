import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsSizeTypeFilterFormPaginated, ItemsSizeTypeFilterFormValues } from '../components/filter-form/items-size-type-filter/types';
import { ItemsSizeTypeTable } from '../components/items-size-type-tabel/items-size-type-tabel';
import { ItemsSizeTypeListTopBar } from '../components/top-bar/items-size-type-topbar';
import { useGetItemsSizeTypeLists } from '../use-cases/get-items-size-type';;


const getInitialFilterValues: ItemsSizeTypeFilterFormValues = {
    Desc: undefined,
    statusId: undefined,

};


export const ItemsSizeTypeViews = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsSizeTypeFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            ...getInitialFilterValues,
        });

    const { data, refetch, isLoading } = useGetItemsSizeTypeLists((params), {
        retry: false,
    });


    const handleFiltersChange = (values: ItemsSizeTypeFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsSizeTypeListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsSizeTypeTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};



