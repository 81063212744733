import { Tabs } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'routes/constants'

import CustomerAddress from './customer-address'
import CustomerInfo from './customer-info'
import CustomerOrder from './customer-order'


const DetailsTabPage = ({ id }: { id: any }) => {

    const [tabs, setTabs] = useState<any>("1")

    const handleTabChange = (activeKey: any) => {
        setTabs(activeKey);
    };

    const items = [
        {
            key: "1",
            label: "Customer Info",
            children: <CustomerInfo id={id} tabs={tabs} />,
            className: 'cltb'

        },
        {
            key: "2",
            label: "Customer Address",
            children: <CustomerAddress id={id} tabs={tabs} />,
            className: 'cltb'

        },
        {
            key: "3",
            label: "Customer Order",
            children: <CustomerOrder id={id} tabs={tabs} />,
            className: 'cltb'

        }
    ]


    return (
        <>
            <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={ROUTES.CUSTOMER_LIST} >Customer</Link>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                        <Link to={ROUTES.CUSTOMER_LIST} >Customer List</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        {`Customer Detail/ ${id}/${tabs === "1" ? "Customer Info" : tabs === "2" ? "Customer Address" : tabs === "3" ? "Customer Order" : ""}`}
                    </li>
                </ul>
            </nav>
            <Tabs
                className="sctabbx"
                activeKey={tabs}
                onChange={handleTabChange}
                items={items} />
        </>
    )
}

export default DetailsTabPage
