import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Modal, Skeleton, Space, Switch, Tooltip } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useCommonDelete } from 'features/admin/use-cases/delete-common';
import { useUpdateCommonStatus } from 'features/admin/use-cases/update-common-status';
import { Dispatch, SetStateAction, useState } from 'react';

import { CMSFilterFormValuesPaginated } from '../filter-form/cms-filter/types';
import { AddCMSModal } from '../models/add-cms-model/cms-add-model';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: CMSFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const CMSTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };

    const { mutate: mutateUpdateStatus } = useUpdateCommonStatus();
    const { mutate: mutateDelete } = useCommonDelete();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | null>(null);


    const handleOpenModel = (record: any) => {
        setIsOpen(true);
        setEditData(record)
        document.body.style.overflow = 'hidden';
    }


    const handleCloseModal = () => {
        setIsOpen(false);
        setEditData(null)
        document.body.style.overflow = 'visible';
    }

    const { confirm } = Modal;

    const showDeleteConfirm = (record: any) => {
        //console.log(record,"record");
        confirm({
            title: 'Are you sure you want to delete this Items?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.Id);
            },
        });
    };

    const handleDelete = async (id: any) => {
        mutateDelete({
            Id: id,
            Flag: "CMSDelete",
        });
    };

    const menuItems = (record: any) => [
        {
            key: 'edit',
            label: <Button type="text" onClick={() => handleOpenModel(record)} icon={<EditOutlined />} >Edit</Button>,
        },
        {
            key: 'delete',
            label: <Button onClick={() => showDeleteConfirm(record)} type="text" icon={<DeleteOutlined />} danger>
                Delete
            </Button>,
        },
    ]


    return (
        <div className='order_rtn'>
            <div className={`cstmrightmdl notify_modal ${isOpen && "show"}`}>
                <AddCMSModal onClose={handleCloseModal} editData={editData} />
            </div>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.Items}
                    className='cstm_table'

                >


                    <Column<any>
                        title={'CMS Type'}
                        dataIndex={'Type_En'}
                        sorter={(a, b) => a.Type_En && a.Type_En.localeCompare(b.Type_En)}
                    />

                    <Column<any>
                        title={'Title'}
                        dataIndex={'Title_En'}
                        sorter={(a, b) => a.Title_En && a.Title_En.localeCompare(b.Title_En)}
                    />

                    <Column<any>
                        title={'Description'}
                        dataIndex={'Description_En'}
                        sorter={(a, b) => a.Description_En && a.Description_En.localeCompare(b.Description_En)}
                        render={(_, record) => (
                            // <h1 dangerouslySetInnerHTML={{ __html: record?.Description_En }}></h1>
                            <h1>{record?.Description_En && record?.Description_En.replace(/(<([^>]+)>)/gi, "").replace(/\s+/g, " ").slice(0, 100)}</h1>

                        )}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'IsActive'}
                        sorter={(a, b) => Number(b.IsActive) - Number(a.IsActive)}
                        render={(_, record) => (
                            <h1>{record?.IsActive ? "Active" : "InActive"}</h1>
                        )}
                    />

                    <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Space>
                                <Dropdown
                                    menu={{ items: menuItems(record) }} // Use `menu` prop instead of `overlay`
                                    trigger={['click']} className='drop_down_cstm'
                                >
                                    <Button shape={"circle"} icon={<MoreOutlined />} />
                                </Dropdown>

                                <Tooltip>

                                    <Switch
                                        defaultChecked={record.IsActive}
                                        checked={record.IsActive}
                                        onChange={(isActive: any) => {
                                            mutateUpdateStatus({
                                                Id: record.Id,
                                                Flag: "CMSChangeStatus",
                                                Status: isActive ? 1 : 0, // Use `isChecked` directly
                                            });
                                            record.IsActive = isActive; // Ensure it's an integer
                                        }}
                                        onClick={(event: any) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
