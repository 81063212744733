import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { OrderFilterFormPaginated, OrderFilterFormValues } from '../components/filter-form/order-list-filter/types';
import { OrderListTable } from '../components/order-list-table/orders-list-table';
import { OrderListTopBar } from '../components/top-bar/order-list-topbar';
import { useGetOrderLists } from '../use-cases';

const getInitialFilterValues: OrderFilterFormValues = {
  Flag: "OrderList",
  fromDate: undefined,
  toDate: undefined,
  Name: undefined,
  EmailId: undefined,
  MobileNo: undefined,
  transactionId: undefined,
  paymentTypeId: undefined,
  invoiceTypeId: undefined,
  paymentStatus: undefined,
  brand_Fk_Id: undefined,
  isCustomOrder: undefined,
  statusId: undefined,
};


export const OrderListView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<OrderFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      ...getInitialFilterValues,
    });

  const { data, isLoading } = useGetOrderLists((params), {
    retry: false,
  });


  const handleFiltersChange = (values: OrderFilterFormPaginated) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <OrderListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
        tableParams={params}
      />

      <OrderListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

