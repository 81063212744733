import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useGetExcelDownload } from 'features/customers/use-cases/get-excel-donload';
import { useGetPdfDownload } from 'features/customers/use-cases/pdf-download';
import { useState } from 'react';

import { OrderReturnListFilterForm } from '../filter-form/order-return-filter/order-return-filter-form';
import { ReturnFilterFormValues } from '../filter-form/order-return-filter/types';


const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ReturnFilterFormValues;
    handleFilter: (values: ReturnFilterFormValues) => void;
    tableParams: any
};

export const OrderReturnListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'order-list-filters-form';

    const { isLoading, refetch } = useGetPdfDownload((tableParams), {
        enabled: false,
    });

    const { refetch: excelRefetch } = useGetExcelDownload((tableParams), {
        enabled: false,
    });

    const screens = useBreakpoint();


    const handlePdfDownload = () => {
        refetch();
    }

    const handleExcelDownload = () => {
        excelRefetch();
    }

    return (
        <>



            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                    type='primary'
                    onClick={handleExcelDownload}
                >
                    Export to Excel
                </Button>
                <Button
                    icon={!screens.xs ? <FilePdfOutlined /> : undefined}
                    type='primary'
                    onClick={handlePdfDownload}
                    loading={isLoading}
                >
                    Print PDF
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <OrderReturnListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
