import { FileExcelOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';

import { ItemsListFilterForm } from '../filter-form/items-list-filter/items-list-filter-form';
import { ItemsFilterFormValues } from '../filter-form/items-list-filter/types';




const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsFilterFormValues;
    handleFilter: (values: ItemsFilterFormValues) => void;
    tableParams: any
};

export const ItemsListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {

    const formId = 'item-list-filters-form';



    const screens = useBreakpoint();



    return (
        <>



            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                    type='primary'
                // onClick={handleExcelDownload}
                // loading={excelLoading}
                >
                    Export to Excel
                </Button>
                <Button
                    icon={!screens.xs ? <FilePdfOutlined /> : undefined}
                    type='primary'
                // onClick={handlePdfDownload}
                // loading={pdfFetching}
                >
                    Print PDF
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <ItemsListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
