import { RightOutlined } from '@ant-design/icons';
import { Button, Empty, message, Modal, Skeleton, Space, Switch, Table, TablePaginationConfig, Tooltip } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateCustomerCommonStatus } from 'features/customers/use-cases/update-customer-common-status';
import { useMarketingCommonStatus } from 'features/marketing/use-cases/update-common-marketing-status';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Volunteer } from 'types';

import { CustomerFilterFormValuesPaginated } from '../filter-form/customer-filter/types';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: CustomerFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const CustomersTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
        useUpdateCustomerCommonStatus();






    const navigateDetails = (record: any) => {
        navigate(getRoute(ROUTES.CUSTOMER_DETAILS_ID, record.CustId));
    }

    return (
        <div className='order_rtn'>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<Volunteer>
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.getCustomerLists}
                    className='cstm_table'

                >
                    <Column<Volunteer>
                        title={'Name'}
                        sorter={(a, b) => {
                            if (!a.Name) return 1; // Sort null or undefined values to the end
                            if (!b.Name) return -1;

                            return a.Name.localeCompare(b.Name);
                        }}
                        render={(value: any, record: any) => {
                            return (
                                <span className='arow_tab' onClick={() => navigateDetails(record)}>
                                    <RightOutlined />
                                    <h1>{record.Name}</h1>
                                </span>

                            )
                        }}
                    />
                    <Column<any>
                        title={'Email'}
                        dataIndex={'EmailId'}
                        sorter={(a, b) => {
                            if (!a.EmailId) return 1; // Sort null or undefined values to the end
                            if (!b.EmailId) return -1;

                            return a.EmailId.localeCompare(b.EmailId);
                        }}
                    />

                    <Column<any>
                        title={'Mobile'}
                        dataIndex={'MobileNo'}
                        sorter={(a, b) => {
                            if (!a.MobileNo) return 1; // Sort null or undefined values to the end
                            if (!b.MobileNo) return -1;

                            return a.MobileNo - b.MobileNo;
                        }}
                    />

                    <Column<any>
                        title={'Registered On '}
                        dataIndex={'RegisteredOn'}
                        render={(_, record) => record.RegisteredOn && moment(record.RegisteredOn).format("DD-MM-YYYY")}
                        sorter={(a, b) => moment(a.RegisteredOn || 0).unix() - moment(b.RegisteredOn || 0).unix()}
                    />



                    <Column<any>
                        title={'Status'}
                        render={(value: any, record: any) => {
                            return (
                                <h1>{record.Status === 1 ? "Active" : "Inactive"} </h1>
                            )
                        }}
                        sorter={(a, b) => {
                            if (!a.Status) return 1; // Sort null or undefined values to the end
                            if (!b.Status) return -1;

                            return a.Status - b.Status;
                        }}
                    />

                    <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Tooltip>
                                <Switch
                                    defaultChecked={record.Status}
                                    checked={record.Status}
                                    loading={isLoadingUpdate}
                                    onChange={(Status) => {
                                        mutateUpdateStatus({
                                            Id: record.CustId,
                                            Flag: "CustomerListChangeStatus",
                                            Status: Status ? 1 : 0,
                                        });
                                        record.Status = Status;
                                    }}
                                />
                            </Tooltip>

                        )}
                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
