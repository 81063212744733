import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';

import { ItemsSeasonsFilterForm } from '../filter-form/items-seasons-list-filter/items-seasons-list-filter';
import { ItemsSeasonsFilterFormValues } from '../filter-form/items-seasons-list-filter/types';
import { AddItemsSeasonsModal } from '../model/add-season-model/add-items-season-model';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: ItemsSeasonsFilterFormValues;
    handleFilter: (values: ItemsSeasonsFilterFormValues) => void;
};

export const ItemsSeasonsListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'season-list-filters-form';

    const screens = useBreakpoint();

    const [isOpen, setIsOpen] = useState<boolean | null>(false)


    const handleOpenModel = (record: any) => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }


    return (
        <>

            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddItemsSeasonsModal onClose={handleCloseModal} editData={null} />
            </div>

            <div className='head_btn'>
                <Button
                    type='primary'
                    onClick={handleOpenModel}
                >
                    Add Season
                </Button>
            </div>


            <ContentFilters formId={formId}>
                <ItemsSeasonsFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
