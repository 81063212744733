export const getUpdatedQueryParams = (params: { [key: string]: any }) => {
    // Merge base parameters with overrides (e.g., RecordsPerPage)
    const updatedParams = { ...params, RecordsPerPage: "500000" };

    // Filter out any null or empty values from the parameters
    const filteredParams = Object.fromEntries(
        Object.entries(updatedParams).filter(([_, value]) => value != null && value !== "")
    );

    // Convert the filtered parameters to a query string
    return new URLSearchParams(filteredParams).toString();
};