import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { EditCMSTypeFormField } from './types';



export const EditCMSTypeForm = () => {

    const { t } = useTranslation();


    return (
        <FormSectionsSeparator title={""} >


            <Form.Item<EditCMSTypeFormField>
                label={"Type"}
                name={EditCMSTypeFormField.TypeEn}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    }
                ]}
            >
                <Input
                    placeholder={"Type"}
                />
            </Form.Item>

            <Form.Item<EditCMSTypeFormField>
                label={"Type (Arabic)"}
                name={EditCMSTypeFormField.TypeAr}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    }
                ]}
            >
                <Input
                    placeholder={"Type (Arabic)"}
                />
            </Form.Item>


        </FormSectionsSeparator >
    );
};
