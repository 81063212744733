import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { clearStorage } from 'features/auth';
import { UserApiService } from 'features/users';
import { queryClient } from 'providers';
import { User } from 'types';

import { userQueryKeys } from './query-keys';

const { getUser } = UserApiService();

const getUserQuery = (
  lang: string,
  id: string,
  options?: UseQueryOptions<User>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<User>;
  options?: UseQueryOptions<User>;
} => ({
  //   queryKey: userQueryKeys.detail(id),
  //   queryFn: ({ signal }) => getUser(id, { config: { signal } }),
  //   ...options,
  // });
  queryKey: userQueryKeys.detail(id),
  queryFn: async ({ signal }) => {
    const response = await getUser(lang, id, { config: { signal } });

    if (response.status === 200) {
      return response.data;
    } else {
      clearStorage();
      queryClient.clear();
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  },
  ...options,
});

export const useGetUser = (lang: string, id: string, options?: UseQueryOptions<User>) => {
  const { message } = App.useApp();

  return useQuery<User, Error>(
    getUserQuery(lang, id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};




