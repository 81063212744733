import { apiClient, ApiClientConfig } from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};

export type NotifyStatusDTO = {
  Id: number;
  Flag: string;
};

export type OfferTypeDTO = {
  Id: number;
  Flag: string;
  OfferType_Ar: string;
  OfferType_En: string;
};

// export type SocialDTO = {
//   Id?: number;
//   Flag: string;
//   BenefitsType: number;
//   Title_En: string;
//   Title_Ar: string;
//   Logo: string;
//   Description_En: string;
//   Description_Ar: string;
// };

export type UpdateItemsInfoDTO = {
  Flag: string;
  Id: string;
  ItemName_En: string;
  ItemName_Ar: string;
  ItemDescription_En: string;
  ItemDescription_Ar: string;
  OtherInformation_En: string;
  OtherInformation_Ar: string;
  Group_Fk_Id: string;
  Category_Fk_Id: string;
  Brand_Fk_Id: string;
  Item_Code: string;
  ItemPrice: number;
  OfferType_Fk_Id?: string;
  Offer?: string;
  OfferPrice?: number;
  ItemCellableQty: string;
  // IsDiscount: string;
};


export type UpdateNotificationDTO = {
  Flag: string;
  Id: number;
  AppType: number;
  DeviceTypeId: number;
  NotificationOfferTypeId: number;
  Title_En: string;
  Title_Ar: string;
  Description_En: string;
  Description_Ar: string;
};


export const MarketingApiService = () => {





  const getPdfDownload = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/orderlist/pdforderlist`,
      params,
    });

    return response.data;
  };


  const getExcelDownload = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/orderlist/exporttoexcelorderlist`,
      params,
    });

    return response.data;
  };

  const getDropdownLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dropdown/getdropdownlist`,
      params,
    });

    return response.data?.dropdownLists;
  };



  const updateItemsInfo = async (
    payload: UpdateItemsInfoDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/itemmaster/saveandupadateitem`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getPromotionLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getpromotionlist`,
      params,
    });

    return response.data;
  };

  const getPromotionTypeLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getpromotiontypelist`,
      params,
    });

    return response.data;
  };


  const getNotificationsLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getnotificationslist`,
      params,
    });

    return response.data;
  };



  const getBannerLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getbannerslist`,
      params,
    });

    return response.data;
  };



  const getContactTypeLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getcontacttypelist`,
      params,
    });

    return response.data;
  };


  const getContactLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getcontactuslist`,
      params,
    });

    return response.data;
  };


  const getSubscribersLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getsubscriberslist`,
      params,
    });

    return response.data;
  };


  const getNewsLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getnewslist`,
      params,
    });

    return response.data;
  };

  const getFaqLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getfaqlist`,
      params,
    });

    return response.data;
  };


  const getSocialMediaLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getsocialmedialist`,
      params,
    });

    return response.data;
  };


  const getBenefitLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getbenefitslist`,
      params,
    });

    return response.data;
  };


  const getOfferTypeLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/marketing/getoffertypelist`,
      params,
    });

    return response.data;
  };


  const updatePromotions = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/marketing/saveandupdatepromotion`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };




  const updatePromotionType = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/marketing/saveandupdatepromotiontype`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateNotificationType = async (
    payload: UpdateNotificationDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/marketing/saveandupdatenotifications`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateBanner = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/marketing/SaveAndUpdateBanners`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const updateContactType = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/marketing/saveandupdatecontacttype`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateNews = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/marketing/saveandupdatenews`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const updateFaq = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/marketing/saveandupdatefaq`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateOfferType = async (
    payload: OfferTypeDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/marketing/saveandupdateoffertype`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateBenefits = async (formData: FormData) => {
    const baseURL = CONFIG.EFASHION_API_BASE_URL;
    const response = await axiosInstance.post(
      `${baseURL}/v1/marketing/saveandupdatesocialbenefits`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const updateSocialMedia = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/marketing/saveandupdatesocialmedia`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    getPdfDownload,
    getExcelDownload,
    getDropdownLists,
    updateItemsInfo,

    getPromotionLists,
    getPromotionTypeLists,
    getNotificationsLists,
    getBannerLists,
    getContactTypeLists,
    getContactLists,
    getSubscribersLists,
    getNewsLists,
    getFaqLists,
    getSocialMediaLists,
    getBenefitLists,
    getOfferTypeLists,

    updatePromotions,
    updatePromotionType,
    updateNotificationType,
    updateBanner,
    updateContactType,
    updateNews,
    updateFaq,

    updateOfferType,
    updateBenefits,
    updateSocialMedia
  };
};
