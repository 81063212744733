/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const adminQueryKeys = {
  all: ['reports'] as const,
  lists: () => [...adminQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...adminQueryKeys.lists(), { filters }] as const,
  details: () => [...adminQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...adminQueryKeys.details(), id] as const,
};
