import { apiClient, ApiClientConfig } from 'api';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};



export const CustomersApiService = () => {


  const getCustomersLists = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerlist`,
      params,
    });

    return response.data;
  };

  const getPdfDownload = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/pdfcustomerslist`,
      params,
    });

    return response.data;
  };


  const getExcelDownload = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/exporttoexcelcustomerslist`,
      params,
    });

    return response.data;
  };

  const getCustomerInfo = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerlist`,
      params,
    });

    return response.data;
  };


  const getCustomersOrder = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomerorderlist`,
      params,
    });

    return response.data;
  };

  const getCustomersAddress = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/cutomer/getcustomeraddresslist`,
      params,
    });

    return response.data;
  };


  return {
    getCustomersLists,
    getPdfDownload,
    getExcelDownload,
    getCustomerInfo,
    getCustomersAddress,
    getCustomersOrder
  };
};
