import { PaginatedRequestParams } from "api";

export enum ItemsSubCategoryFilterFormField {
    Title = 'title',
    Status = 'statusId',
    CategoryName = "GroupId"
}

export type ItemsSubCategoryFilterFormValues = {
    [ItemsSubCategoryFilterFormField.Title]: string | undefined;
    [ItemsSubCategoryFilterFormField.Status]: string | undefined;
    [ItemsSubCategoryFilterFormField.CategoryName]: string | undefined;

};

export type ItemsSubCategoryFilterFormPaginated = ItemsSubCategoryFilterFormValues &
    PaginatedRequestParams;