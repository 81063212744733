// import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Table } from 'antd';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
// import { useSettingDelete } from 'features/system-setting/use-cases/delete-setting';
// import { useSettingStatus } from 'features/system-setting/use-cases/update-setting-status';


export const UitilesListTable = () => {

    // const handleTableChange = (
    //     pagination: TablePaginationConfig,
    //     filters: Record<string, FilterValue | null>,
    //     sorter: SorterResult | SorterResult[],
    // ) => {
    //     updateParams({
    //         ...tableParams,
    //         CurrentPage: pagination.current,
    //         RecordsPerPage: pagination.pageSize,
    //     });
    // };

    // const { confirm } = Modal;
    // const showDeleteConfirm = (record: any) => {
    //     confirm({
    //         title: 'Are you sure you want to delete this setting?',
    //         icon: <ExclamationCircleOutlined />,
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         onOk() {
    //             // handleDelete(record.Id);
    //         },
    //     });
    // };

    // const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
    //     useSettingStatus();



    // const { mutate: mutateDelete } =
    //     useSettingDelete();


    // const handleDelete = async (id: number) => {
    //     mutateDelete({
    //         Id: id,
    //         Flag: "",
    //     });
    // };


    // const menuItems = (record: any) => [
    //     {
    //         key: 'delete',
    //         label: <Button type="text" icon={<EditOutlined />} >
    //             Edit
    //         </Button>,
    //     },
    //     {
    //         key: 'delete',
    //         label: <Button onClick={() => showDeleteConfirm(record)} type="text" danger icon={<DeleteOutlined />}>
    //             Delete
    //         </Button>,
    //     },
    // ]
    const dataList = [{ isSelected: false, E: 10, TableName: 'Sync Algolia Data' }];

    return (
        <div className='order_rtn'>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={dataList.length}
                    />
                </StyledTableHeader>
                <Table
                    rowKey='E'
                    pagination={false}
                    // onChange={handleTableChange}
                    // pagination={{
                    //     current: tableParams.page,
                    //     defaultPageSize: tableParams.size,
                    //     total: itemsCount,
                    // }}
                    // locale={{
                    //     emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    // }}
                    // loading={isLoading}
                    dataSource={dataList}
                    className='cstm_table'
                >
                    {/* <Column
                        title={'Field'}
                        dataIndex={'Field'}
                    /> */}
                    <Column
                        title={'Table'}
                        dataIndex={'TableName'}
                    />
                    <Column
                        title={'#'}
                        dataIndex={'isSelected'}
                        render={(_, record: any) => (<Checkbox />)}

                    />
                    <Column<any>
                        className="action_col"
                        title={"Action"}
                        dataIndex={'Status'}

                        render={() => (
                            <Button>
                                Sync
                            </Button>
                        )}

                    />
                </Table>
            </StyledTableWrapper >
        </div>
    );
};
