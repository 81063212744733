export enum AddCMSFormField {
    Id = "Id",
    Flag = 'Flag',
    Title = 'Title_En',
    Title_Ar = "Title_Ar",
    DescriptionEn = 'Description_En',
    DescriptionAr = "Description_Ar",
    AppType = 'AppType',
    CMSTypeFkId = "CMSType_Fk_Id"
}

export type AddCMSFormValues = {
    [AddCMSFormField.Id]: number;
    [AddCMSFormField.Flag]: string;
    [AddCMSFormField.Title]: string;
    [AddCMSFormField.Title_Ar]: string;
    [AddCMSFormField.DescriptionEn]: string;
    [AddCMSFormField.DescriptionAr]: string;
    [AddCMSFormField.AppType]: number;
    [AddCMSFormField.CMSTypeFkId]: number;
}