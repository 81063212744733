import { FileExcelOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { useGetExcelDownload } from 'features/customers/use-cases/get-excel-donload';
import { useGetPdfDownload } from 'features/customers/use-cases/pdf-download';

import { useState } from 'react';


import { CustomerFilterForm } from '../filter-form/customer-filter/customer-filter-form';
import { CustomerFilterFormValues } from '../filter-form/customer-filter/types';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: CustomerFilterFormValues;
    handleFilter: (values: CustomerFilterFormValues) => void;
    tableParams: any
};

export const CustomerTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {

    const formId = 'end-users-filters-form';
    const [showAppUserModal, setShowAppUserModal] = useState(false);

    const { data, isLoading, refetch } = useGetPdfDownload((tableParams), {
        enabled: false,
    });

    const { data: excelData, isLoading: excelLoading, refetch: excelRefetch } = useGetExcelDownload((tableParams), {
        enabled: false,
    });

    const screens = useBreakpoint();



    const handleCloseModal = () => {
        setShowAppUserModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowAppUserModal(true);
        document.body.style.overflow = 'hidden';
    }

    // const handlePdfDownload = () => {
    //     refetch();
    // }

    // const handleExcelDownload = () => {
    //     excelRefetch();
    // }

    return (
        <>

            <div className='head_btn'>
                <Button
                    icon={!screens.xs ? <FileExcelOutlined /> : undefined}
                    type='primary'
                // onClick={handleExcelDownload}
                >
                    Export to Excel
                </Button>
                <Button
                    icon={!screens.xs ? <FilePdfOutlined /> : undefined}
                    type='primary'
                    // onClick={handlePdfDownload}
                    loading={isLoading}
                >
                    Print PDF
                </Button>
            </div>

            <ContentFilters formId={formId}>
                <CustomerFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
