import { apiClient, ApiClientConfig } from 'api';

export type GetDayViewStatsParams = {
  date?: any;
  company_id?: any;
  facility_id?: any;
};


export const DashboardApiService = () => {
  const getDashboardTopData = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dashboard/getdashboarddata`,
      params,
    });

    return response.data;
  };


  const getDashboardSalesReport = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dashboard/getdashboardchartdata`,
      params,
    });

    return response.data;
  };


  const getDashboardOrderHistory = async (
    params: GetDayViewStatsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dashboard/getdashboardpiechartdata`,
      params,
    });

    return response.data;
  };

  return {
    getDashboardTopData,
    getDashboardSalesReport,
    getDashboardOrderHistory
  };
};
