export enum AddContactTypeFormField {
  Flag = 'Flag',
  Id = 'Id',
  TypeEn = 'Type_Eng',
  TypeAr = 'Type_AR',
}

export type AddContactTypeFormValues = {
  [AddContactTypeFormField.Flag]: string;
  [AddContactTypeFormField.Id]: string;
  [AddContactTypeFormField.TypeEn]: string;
  [AddContactTypeFormField.TypeAr]: string;
};

