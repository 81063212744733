import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { queryClient } from 'providers';

import { MarketingApiService } from '../api/marketing-api.service';
import { marketingQueryKeys } from './query-keys';

const { updateNews } = MarketingApiService();


export const useUpdateNews = () => {
    const { message } = App.useApp();

    return useMutation(
        (payload: any) => {
            return updateNews(payload);
        },
        {
            onError: (error) => {
                const uiMessage = handleError(error);

                message.error(uiMessage);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: marketingQueryKeys.all,
                });
            },
        },
    );
};