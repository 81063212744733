import { Grid } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { useGetExcelDownload } from 'features/customers/use-cases/get-excel-donload';
import { useGetPdfDownload } from 'features/customers/use-cases/pdf-download';
import { useState } from 'react';

import { BrandsListFilterForm } from '../brands-list-filter/brands-list-filter-form';
import { BrandsFilterFormValues } from '../brands-list-filter/types';


const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: BrandsFilterFormValues;
    handleFilter: (values: BrandsFilterFormValues) => void;
    tableParams: any
};

export const BrandsListTopBar = ({
    getInitialFilterValues,
    handleFilter,
    tableParams
}: UsersTopBarProps) => {

    const formId = 'brand-list-filters-form';
    const [showAppUserModal, setShowAppUserModal] = useState(false);

    const { data, isLoading, refetch } = useGetPdfDownload((tableParams), {
        enabled: false,
    });

    const { data: excelData, isLoading: excelLoading, refetch: excelRefetch } = useGetExcelDownload((tableParams), {
        enabled: false,
    });

    const screens = useBreakpoint();



    const handleCloseModal = () => {
        setShowAppUserModal(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setShowAppUserModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handlePdfDownload = () => {
        refetch();
    }

    const handleExcelDownload = () => {
        excelRefetch();
    }

    return (
        <>

            {/* <ContentTopBar title={''} /> */}
            <ContentFilters formId={formId}>
                <BrandsListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
