import { Outlet, useParams } from 'react-router-dom'

import OrderListDetailsPage from '../components/order-list-details/order-list-details-page';

export const OrderListDetailsView = () => {

    const { id } = useParams();
    if (!id) return null;

    return (
        <div>
            <OrderListDetailsPage id={id} />
            <Outlet />
        </div>
    )
}
