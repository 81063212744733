import { Empty, Skeleton } from 'antd';
import Table, { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column } from 'components';
import { RecordCountBadge } from 'components/record-count-badge/record-count-badge';
import { StyledTableHeader, StyledTableWrapper } from 'components/table/table.styled';
import { useGetCustomersAddress } from 'features/customers/use-cases/get-customer-address';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Volunteer } from 'types';



const CustomerAddress = ({ id, tabs }: { id: number; tabs: string }) => {
    const getInitialFilterValues: any = {
        id: id,
    }


    const { params, updateParams, resetPage } =
        usePaginationParams<any>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch } = useGetCustomersAddress((params), {
        retry: false,
    });

    useEffect(() => {
        if (tabs === "2") {
            refetch();
        }
    }, [tabs]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...params,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    }




    console.log(data)

    return (
        <div>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={data?.TotalRecords || 0}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='id'
                    onChange={handleTableChange}
                    pagination={{
                        current: params.page,
                        defaultPageSize: params.size,
                        total: data?.getCustomerAddress?.length
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : data?.getCustomerAddress}
                    className='cstm_table'

                >
                    <Column<any>
                        title={'Name'}
                        dataIndex={'Name'}
                    // sorter={true}
                    // sortDirections={['ascend', 'descend', 'ascend']}
                    // defaultSortOrder={'ascend'}
                    // render={(value: any, record: any) => {
                    //     return (
                    //         <span className='arow_tab' onClick={() => navigateDetails(record)}>
                    //             <RightOutlined />
                    //             <h1>{record.Name}</h1>
                    //         </span>

                    //     )
                    // }}
                    />


                    <Column<any>
                        title={'Mobile'}
                        dataIndex={'MobileNo'}
                    />

                    <Column<any>
                        title={'Country'}
                        dataIndex={'CountryName'}
                    />



                    <Column<any>
                        title={'Governorate'}
                        dataIndex={'GovernorateName'}
                    />

                    <Column<any>
                        title={'City'}
                        dataIndex={'CityName'}
                    />


                    <Column<any>
                        title={'Block'}
                        dataIndex={'Block'}
                    />

                    <Column<any>
                        title={'Street'}
                        dataIndex={'StreetName'}
                    />

                    <Column<any>
                        title={'Jadda'}
                        dataIndex={'Jadda'}
                    />



                    <Column<any>
                        title={'Building'}
                        dataIndex={'BuildingName'}
                    />

                    <Column<any>
                        title={'Floor'}
                        dataIndex={'FloorNo'}
                    />

                    <Column<any>
                        title={'House No.'}
                        dataIndex={'HouseNo'}
                    />



                    <Column<any>
                        title={'Office'}
                        dataIndex={'Office'}
                    />

                    <Column<any>
                        title={'Address'}
                        dataIndex={'Address'}
                    />


                    <Column<any>
                        title={'Is Default'}
                        dataIndex={'IsDefault'}
                    />







                    {/* 
                    <Column<any>
                        title={'Status'}
                        render={(value: any, record: any) => {
                            return (
                                <h1>{record.Status === 1 ? "Active" : "Inactive"} </h1>
                            )
                        }}
                    /> */}

                    {/* <Column<any>
                        title={"Action"}
                        dataIndex={'Status'}
                        render={(_, record) => (
                            <Tooltip>
                                <Switch
                                    defaultChecked={record.Status}
                                    checked={record.Status}
                                // loading={loadingSwitchId == record.Id && isLoadingUpdate}
                                // onChange={(isChecked) => {
                                //     setLoadingSwitchId(record.Id);
                                //     mutateUpdateStatus({
                                //         Id: record.Id,
                                //         flag: "any_SET_STATUS",
                                //         status: isChecked,
                                //     }, { onSettled: resetRecorId });
                                //     record.Status = isChecked;
                                // }}
                                />
                            </Tooltip>

                        )}
                    /> */}
                </Table>
            </StyledTableWrapper >
        </div>
    )
}

export default CustomerAddress

