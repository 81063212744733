import { Empty, Skeleton, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

import { ContactFilterFormPaginated } from '../filter-form/contact-list-filter/types';


type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: ContactFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
    isFetching: boolean
};

export const ContactListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
    isFetching
}: UsersTableProps) => {

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    return (
        <div className='order_rtn'>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isFetching}
                    dataSource={isLoading ? [] : itemList?.Items}

                >
                    <Column<any>
                        title={'App Type'}
                        dataIndex={'AppTypeName'}
                        sorter={(a, b) => a.AppTypeName.localeCompare(b.AppTypeName)}
                    />

                    <Column<any>
                        title={'Name'}
                        dataIndex={'Name'}
                        sorter={(a, b) => a.Name.localeCompare(b.Name)}
                    />

                    <Column<any>
                        title={'Email'}
                        dataIndex={'Email'}
                        sorter={(a, b) => a.Email.localeCompare(b.Email)}
                    />

                    <Column<any>
                        title={'Mobile'}
                        dataIndex={'MobileNo'}
                        sorter={(a, b) => Number(b.MobileNo) - Number(a.MobileNo)}
                    />

                    <Column<any>
                        title={'Contact Type'}
                        dataIndex={'ContactType'}
                        sorter={(a, b) => a.ContactType.localeCompare(b.ContactType)}
                    />

                    <Column<any>
                        title={'Message'}
                        dataIndex={'Message'}
                        sorter={(a, b) => a.Message.localeCompare(b.Message)}
                    />

                    <Column<any>
                        title={'Created On'}
                        dataIndex={'CreatedOn'}
                        sorter={(a, b) => {
                            const dateA = moment(a.CreatedOn, 'DD MMM YY').toDate(); // Convert string to Date
                            const dateB = moment(b.CreatedOn, 'DD MMM YY').toDate();

                            return dateA.getTime() - dateB.getTime(); // Sort by timestamp
                        }}
                        render={(value: any, record: any) => {
                            return (
                                <h1>
                                    {record?.CreatedOn
                                        ? moment(record.CreatedOn, "DD MMM YY").format("DD/MM/YYYY")
                                        : ""}
                                </h1>

                            )
                        }}

                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
