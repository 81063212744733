import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters } from 'components';
import { useState } from 'react';


import { CustomSizeFilterForm } from '../filter-form/custom-size-master-filter/custom-size-filter-form';
import { CustomSizeFilterFormValues } from '../filter-form/custom-size-master-filter/types';
import { EditCustomSizeModal } from '../models/custom-size-master-model/custom-size-model';
// import { userPermissionPage } from 'utils/user-permission-page';
// import { useLocation } from 'react-router-dom';



const { useBreakpoint } = Grid;

type UsersTopBarProps = {
    getInitialFilterValues: CustomSizeFilterFormValues;
    handleFilter: (values: CustomSizeFilterFormValues) => void;
};

export const CustomSizeMasterTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {

    const formId = 'custom-size-filters-form';
    const [isOpen, setIsOpen] = useState(false);

    const screens = useBreakpoint();



    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
    }

    const handleOpenModal = () => {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }
    // const location = useLocation();
    // const { canView, canEdit, canAdd } = userPermissionPage(location.pathname);


    return (
        <>
            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <EditCustomSizeModal onClose={handleCloseModal} editData={""} />
            </div>

            <div className='head_btn'>
                {/* {canAdd && */}
                <Button
                    icon={!screens.xs ? <PlusOutlined /> : undefined}
                    type='primary'
                    onClick={handleOpenModal}
                >
                    Add Custom Size
                </Button>
                {/* } */}

            </div>

            <ContentFilters formId={formId}>
                <CustomSizeFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
