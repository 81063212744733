import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsSeasonsFilterFormPaginated, ItemsSeasonsFilterFormValues } from '../components/filter-form/items-seasons-list-filter/types';
import { ItemsSeasonsTable } from '../components/item-season-list-tabel/items-season-list-tabel';
import { ItemsSeasonsListTopBar } from '../components/top-bar/item-seasons-topbar';
import { useGetItemsSeasonLists } from '../use-cases/get-items-seasons-list';;

const getInitialFilterValues: ItemsSeasonsFilterFormValues = {
    title: undefined,
    statusId: undefined,

};

export const ItemsSeasonsViews = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsSeasonsFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch } = useGetItemsSeasonLists((params), {
        retry: false,
    });


    const handleFiltersChange = (values: ItemsSeasonsFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsSeasonsListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
            />

            <ItemsSeasonsTable
                isLoading={isLoading}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

