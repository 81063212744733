import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsStocksFilterFormField, ItemsStocksFilterFormPaginated, ItemsStocksFilterFormValues } from '../components/filter-form/items-stocks-filter-form/types';
import { ItemsStocksTable } from '../components/items-stocks-table/items-stocks-table';
import { ItemsStocksListTopBar } from '../components/top-bar/items-stocks-list-topbar';
import { useGetItemsStocksLists } from '../use-cases/get-items-stocks';;

const getInitialFilterValues: ItemsStocksFilterFormValues = {
    [ItemsStocksFilterFormField.Brand]: undefined,
    [ItemsStocksFilterFormField.Category]: undefined,
    [ItemsStocksFilterFormField.SubCategory]: undefined,
    [ItemsStocksFilterFormField.ItemCode]: undefined,
};


export const ItemsStocksListView = () => {

    const { params, updateParams, resetPage } =
        usePaginationParams<ItemsStocksFilterFormPaginated>({
            CurrentPage: 1,
            RecordsPerPage: 10,
            // order_by: 'name',
            ...getInitialFilterValues,
        });

    const { data, isLoading, refetch, isFetching } = useGetItemsStocksLists((params), {
        retry: false,
    });


    const handleFiltersChange = (values: ItemsStocksFilterFormPaginated) => {
        updateParams(values);
        resetPage();
        refetch();
    };

    return (
        <>
            <ItemsStocksListTopBar
                getInitialFilterValues={getInitialFilterValues}
                handleFilter={handleFiltersChange}
                tableParams={params}
            />

            <ItemsStocksTable
                isLoading={isLoading || isFetching}
                itemList={data}
                itemsCount={data?.TotalRecords || 0}
                tableParams={params}
                updateParams={updateParams}

            />
            <Outlet />
        </>
    );
};

