import {
  DownOutlined,
  IdcardOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';
import { phoneNumberPattern } from 'utils/validations';

import { AddUserFormField } from '../users-filter-form/types';


export const UserInfoFormItems = ({ userEditdata }: { userEditdata: any }) => {


  const { t } = useTranslation();


  const status = [
    { value: 1, label: 'Active' },
    { value: 0, label: 'InActive' },
  ];

  return (
    <FormSectionsSeparator title={""} >
      <Form.Item<AddUserFormField>
        label={"First Name"}
        name={AddUserFormField.MemFirstName}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Input
          placeholder={"First Name"}
          disabled={userEditdata}
        />
      </Form.Item>

      <Form.Item<AddUserFormField>
        label={"Last Name"}
        name={AddUserFormField.MemLastName}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Input
          placeholder={"Last Name"}
          disabled={userEditdata}
        />
      </Form.Item>


      <Form.Item<AddUserFormField>
        label={"User Id (Ex-ABC01))"}
        name={AddUserFormField.MemUserID}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Input
          placeholder={"User Id (Ex-ABC01)"}
          disabled={userEditdata}
          maxLength={5}
        />
      </Form.Item>

      {!userEditdata && <Form.Item<AddUserFormField>
        label={"Password"}
        name={AddUserFormField.MemPassword}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
          {
            min: 4,
          },
          {
            pattern: /(?=.*\d)/,
            message: t('validateMessages.custom.passwordDigit'),
          },
          // {
          //   pattern: /(?=.*[a-z])/,
          //   message: t('validateMessages.custom.passwordLowercase'),
          // },
          // {
          //   pattern: /(?=.*[A-Z])/,
          //   message: t('validateMessages.custom.passwordUppercase'),
          // },
        ]}
      >
        <Input.Password
          placeholder={"Enter Password"}
          prefix={<LockOutlined className='input-prefix-icon' />}
          autoComplete='new-password'
        />
      </Form.Item>}



      <Form.Item<AddUserFormField >
        label={t('adminUsers.formFields.email.label')}
        name={AddUserFormField.MemEmailId}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
          {
            type: 'email',
            message: t('validateMessages.custom.email'),
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.email.placeholder')}
          prefix={<MailOutlined className='input-prefix-icon' />}
          autoComplete="off"
        // name="unique-email"
        />
      </Form.Item>


      <Form.Item<AddUserFormField>
        label={"Contact"}
        name={AddUserFormField.MemMobileNo}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('validateMessages.custom.nomsg'),
          },
          {
            pattern: phoneNumberPattern,
            message: t('validateMessages.custom.phoneNumber'),
          },
        ]}
      >
        <Input
          type='tel'
          placeholder={"Enter Contact number"}
          prefix={<PhoneOutlined className='input-prefix-icon' />}
          maxLength={17}
          onKeyPress={(e) => {
            // Check if the entered key is a number, "+", or Backspace (to allow deletion)
            const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
            const isBackspace = e.key === 'Backspace';

            // Check if the total length after the new character insertion will be <= 15
            const inputValue = (e.target as HTMLInputElement).value || '';
            const totalLength = inputValue.length + (isBackspace ? -1 : 1);

            if (!isNumberOrPlus || totalLength > 17) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item<AddUserFormField>
        label={"Status"}
        name={AddUserFormField.MemStatus_FK_Id}
        rules={[
          {
            required: true,
            message: t('validateMessages.custom.nomsg'),
          },
        ]}
      >
        <Select
          options={status}
          placeholder={t('volunteer.field.assign_event.placeholder')}
          className='has-prefix-icon'
          suffixIcon={
            <>
              <IdcardOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />


      </Form.Item >


    </FormSectionsSeparator >
  );
};
