import { Card } from "antd";
import { useGetDashboardOrderHistory } from "features/dashboard/use-cases/get-order-history-report";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from "react";



export default function CustomerStatusPieChart() {

    const params = {
        Flag: "CustomerStatus"
    }


    const { data, isLoading } = useGetDashboardOrderHistory((params), {
        retry: false,
    });

    const [apiData, setApiData] = useState([])

    useEffect(() => {
        setApiData(mainData)
    }, [data])

    const mainData = data?.Items;

    const options = {
        chart: {
            type: 'pie',
            style: {
                fontFamily: 'Arial, sans-serif' // Ensure a clean default font
            }
        },
        title: {
            text: 'Customer Status',
            style: {
                fontSize: '20px'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b> orders',
            style: {
                fontSize: '16px', // Increased font size for tooltip
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    style: {
                        fontSize: '12px', // Ensure this font size for data labels
                        color: '#333333',
                        fontWeight: '900'
                    },
                    distance: 10,
                },
                size: '70%'
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            itemStyle: {
                fontSize: '12px' // Larger font size for legend
            },

        },

        credits: {
            enabled: false
        },

        series: [{
            name: 'Orders',
            colorByPoint: true,
            data: apiData?.map((item: any, index: number) => ({
                ...item,
                color: ['#95CEFF', '#5C5C61'][index % 2] // Customize colors per slice
            }))
        }]
    };


    return (

        <Card >
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Card>

    )
}
