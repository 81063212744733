import { ContentFilters } from 'components';
import { useState } from 'react';

import { ContactListFilterForm } from '../filter-form/contact-list-filter/contact-list-filter-form';
import { ContactFilterFormValues } from '../filter-form/contact-list-filter/types';



type UsersTopBarProps = {
    getInitialFilterValues: ContactFilterFormValues;
    handleFilter: (values: ContactFilterFormValues) => void;
};

export const ContactListTopBar = ({
    getInitialFilterValues,
    handleFilter,
}: UsersTopBarProps) => {
    useState<any>();
    const formId = 'contact-list-filters-form';


    return (
        <>
            <ContentFilters formId={formId}>
                <ContactListFilterForm
                    formId={formId}
                    handleFilter={handleFilter}
                    initialValues={getInitialFilterValues}
                />
            </ContentFilters>
        </>
    );
};
