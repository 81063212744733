import { Layout, Result404 } from 'components';
import { ResultError } from 'components/errors';
import { MainFiltersProvider } from 'components/main-filters/main-filters.context';
// import { sideBarPermission } from 'components/side-bar/side-bar-permission';
import { CMSTypeView, CMSView, CustomSizeMasterView, DeliveryDaysView, PaymentMethodView } from 'features/admin';
import { LoginView } from 'features/auth';
import { BrandsView } from 'features/brands/routes/brands';
import { CustomerDetailsView, CustomersView } from 'features/customers';
import DashboardView from 'features/dashboard/routes/dashboard';
import WelcomeView from 'features/dashboard/routes/welcome';
import { DeliveryItemView } from 'features/delivery-charges/routes/delivery-item';
import { ItemsCategoryListView, ItemsDetailsView, ItemsListView, ItemsNotifyViews, ItemsPurchasedViews, ItemsReviewListView, ItemsSeasonsViews, ItemsSizeTypeViews, ItemsSizeViews, ItemsStocksListView } from 'features/items/routes';
import { ItemsSubCategoryListView } from 'features/items/routes/items-sub-category-view';
import { ItemsUploadListView } from 'features/items/routes/upload-items-views';
import { BannerListView, BenefitsListView, ContactListView, ContactTypeListView, FaqListView, NewsListView, NotificationsListView, PromotionListView, PromotionTypeListView, SocialMediaListView, SubscribersListView } from 'features/marketing/routes';
import { OfferTypeListView } from 'features/marketing/routes/offer-type-list-views';
import { OrderListDetailsView, OrderListView, OrderReturnDetailsView, OrderReturnView } from 'features/order-mannagemnet/routes';
import { SystemsView } from 'features/system-setting/routes/system-view';
import { UitilesView } from 'features/system-uitiles/routes/uitiles-view';
import {
  AdminDetailView
} from 'features/users';
import { RoleManagementView } from 'features/users/routes/role-management';
import { UserManagementView } from 'features/users/routes/user-management';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute } from './authenticated-route';
import { ROUTES } from './constants';
// import { RestrictedSidebarRoute } from './restricted-route';
import { UnauthenticatedRoute } from './unauthenticated-route';





export const routerConfig: RouteObject[] = [
  {
    errorElement: <ResultError />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <UnauthenticatedRoute>
            <LoginView />
          </UnauthenticatedRoute>
        ),
      },



      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: ROUTES.HOME,
            element: <WelcomeView />,
          },

          {
            path: ROUTES.USERS,
            element: <Navigate to={ROUTES.USERS_APP} replace />,
          },


          {
            path: ROUTES.USERS_ADMIN_DETAIL,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='adminUsers.view'
              // >
              <AdminDetailView />
              // </RestrictedRoute>
            )
          },

          {
            path: ROUTES.USER_MANAGEMENT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <UserManagementView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },



          {
            path: ROUTES.ROLE_PERMISSION_MANAGEMENT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <RoleManagementView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_DELIVERY_DAYS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <DeliveryDaysView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_CMS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <CMSView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_CMS_TYPE,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <CMSTypeView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.USER_CUSTOM_SIZE_MASTER,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <CustomSizeMasterView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.USER_PAYMENT_METHOD,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <PaymentMethodView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },



          {
            path: ROUTES.DASHBOARD,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <DashboardView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ORDER_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <OrderListView />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.ORDER_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <OrderListDetailsView />
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ORDER_RETURN,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <OrderReturnView />
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ORDER_RETURN_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <OrderReturnDetailsView />
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.BRANDS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <BrandsView />
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <ItemsListView />
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_LIST_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <ItemsDetailsView />
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.ITEMS_REVIEWS,
            element: (<ItemsReviewListView />),
          },

          {
            path: ROUTES.ITEMS_STOCK_LIST,
            element: (<ItemsStocksListView />),
          },

          {
            path: ROUTES.ITEMS_CATEGORY,
            element: (<ItemsCategoryListView />),
          },

          {
            path: ROUTES.ITEMS_SUB_CATEGORY,
            element: (<ItemsSubCategoryListView />),
          },

          {
            path: ROUTES.ITEMS_SEASONS,
            element: (<ItemsSeasonsViews />),
          },

          {
            path: ROUTES.ITEMS_SIZE_TYPE,
            element: (<ItemsSizeTypeViews />),
          },


          {
            path: ROUTES.ITEMS_SIZE,
            element: (<ItemsSizeViews />),
          },

          {
            path: ROUTES.ITEMS_PURCHASED,
            element: (<ItemsPurchasedViews />),
          },

          {
            path: ROUTES.ITEMS_NOTIFY,
            element: (<ItemsNotifyViews />),
          },
          {
            path: ROUTES.ITEMS_UPLOAD_ITEMS,
            element: (<ItemsUploadListView />),
          },

          // {
          //   path: ROUTES.ITEMS_REVIEWS,
          //   element: (
          //     // <RestrictedRoute
          //     //   redirectPath={ROUTES.HOME}
          //     //   requiredPermission='fields.view'
          //     // >
          //     <ItemsReviewsView />
          //     // </RestrictedRoute>
          //   ),
          // },

          {
            path: ROUTES.CUSTOMER_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <CustomersView />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CUSTOMER_DETAILS_ID,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <CustomerDetailsView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.DELIVERY_CHARGE,
            element: (
              <DeliveryItemView />
            ),
          },
          {
            path: ROUTES.SYSTEM_SETTING,
            element: (
              <SystemsView />
            ),
          },
          {
            path: ROUTES.SYSTEM_UITILES,
            element: (
              <UitilesView />
            ),
          },

          {
            path: ROUTES.PROMOTION_LIST,
            element: (
              <PromotionListView />
            ),
          },
          {
            path: ROUTES.PROMOTION_TYPE_LIST,
            element: (
              <PromotionTypeListView />
            ),
          },
          {
            path: ROUTES.BANNER_LIST,
            element: (
              <BannerListView />
            ),
          },
          {
            path: ROUTES.NOTIFICATIONS_LIST,
            element: (
              <NotificationsListView />
            ),
          },
          {
            path: ROUTES.CONTACT_LIST,
            element: (
              <ContactListView />
            ),
          },
          {
            path: ROUTES.CONTACT_TYPE_LIST,
            element: (
              <ContactTypeListView />
            ),
          },

          {
            path: ROUTES.SUBSCRIBER_LIST,
            element: (
              <SubscribersListView />
            ),
          },

          {
            path: ROUTES.NEWS_LIST,
            element: (
              <NewsListView />
            ),
          },

          {
            path: ROUTES.FAQ_LIST,
            element: (
              <FaqListView />
            ),
          },


          {
            path: ROUTES.SOCIAL_MEDIA_LIST,
            element: (
              <SocialMediaListView />
            ),
          },


          {
            path: ROUTES.BENEFITS_LIST,
            element: (
              <BenefitsListView />
            ),
          },

          {
            path: ROUTES.OFFER_TYPE_LIST,
            element: (
              <OfferTypeListView />
            ),
          },

        ],
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Result404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
