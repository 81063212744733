
export enum EditDeliveryDaysFormField {
  Flag = "Flag",
  Id = "Id",
  DelieveryDays = "DelieveryDays",
}

export type EditDeliveryDaysFormValues = {
  [EditDeliveryDaysFormField.Flag]: string;
  [EditDeliveryDaysFormField.Id]: number;
  [EditDeliveryDaysFormField.DelieveryDays]: number;

};

