import { PaginatedRequestParams } from "api";

export enum DeliveryChargeFilterFormField {
    CityFkId = 'City_Fk_Id',
    GovernorateFkId = 'Governorate_Fk_Id',
    isActive = 'Status',
    FromDate = 'FromDate',
    ToDate = 'ToDate',
    arabic = 'arabic',
}

export type DeliveryChargeFilterFormValues = {
    [DeliveryChargeFilterFormField.CityFkId]: string | undefined;
    [DeliveryChargeFilterFormField.GovernorateFkId]: string | undefined;
    [DeliveryChargeFilterFormField.isActive]: string | undefined;
    [DeliveryChargeFilterFormField.FromDate]: string | undefined;
    [DeliveryChargeFilterFormField.ToDate]: string | undefined;
    [DeliveryChargeFilterFormField.arabic]: string | undefined;

};

export type DeliveryChargeFilterFormPaginated = DeliveryChargeFilterFormValues &
    PaginatedRequestParams;