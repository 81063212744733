import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ItemsFilterFormField, ItemsFilterFormPaginated, ItemsFilterFormValues } from '../components/filter-form/items-list-filter/types';
import { ItemsListTable } from '../components/items-list-table/items-list-table';
import { ItemsListTopBar } from '../components/top-bar/items-list-topbar';
import { useGetItemsLists } from '../use-cases';

const getInitialFilterValues: ItemsFilterFormValues = {
  [ItemsFilterFormField.Brand]: undefined,
  [ItemsFilterFormField.Category]: undefined,
  [ItemsFilterFormField.SubCategory]: undefined,
  [ItemsFilterFormField.ItemName_En]: undefined,
  [ItemsFilterFormField.ItemCode]: undefined,
  [ItemsFilterFormField.IsActive]: undefined,
  [ItemsFilterFormField.Qty]: "-1",
};


export const ItemsListView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<ItemsFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch } = useGetItemsLists((params));


  const handleFiltersChange = (values: ItemsFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <ItemsListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
        tableParams={params}
      />

      <ItemsListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}

      />
      <Outlet />
    </>
  );
};

