import { Button, Form, Image,Input, message, Select } from 'antd';
import { useGetDropdownLists } from 'features/items/use-cases/get-dopdwon-list';
import { useUpdateSubCategory } from 'features/items/use-cases/upload-items-sub-category';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddSubCategoryFormField, AddSubCategoryFormValues } from './types';




export const EditSubCategoryModal = ({ onClose, editData }: { onClose: () => void, editData: any }) => {
    const formName = 'edit-sub-category-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<AddSubCategoryFormValues>();

    const { t } = useTranslation();

    const { data: statusData, isLoading: statusLoad } = useGetDropdownLists({ Flag: "GetGroupList" });

    const { isLoading, mutate } = useUpdateSubCategory();
    const [image, setImage] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");

    const handleClose = () => {
        onClose();
        form.resetFields();
        setImage(null);
        setFileName("");
    }


    const getInitialValues = {
        [AddSubCategoryFormField.Flag]: undefined,
        [AddSubCategoryFormField.Id]: undefined,
        [AddSubCategoryFormField.CategoryName_En]: undefined,
        [AddSubCategoryFormField.CategoryName_Ar]: undefined,
        [AddSubCategoryFormField.GroupLogo]: undefined,
        [AddSubCategoryFormField.CategoryGroup]: undefined,

    };


    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                ...editData,
                [AddSubCategoryFormField.CategoryGroup]: editData?.Group_Fk_Id,
            });
        } else {
            form.resetFields();
        }

    }, [editData, form]);

    const handleUpdate = (formValues: AddSubCategoryFormValues) => {
        const formData = new FormData();

        // Append other form values
        Object.keys(formValues).forEach((key) => {
            if (key !== 'File') {
                formData.append(key, formValues[key as keyof AddSubCategoryFormValues] as string);
            }
        });

        formData.append('Flag', "insert");

        if (image) {
            formData.append('File', image);
        }

        if (editData) {
            formData.append('Id', editData.Id);
        }

        mutate(formData, { onSuccess: handleClose });

    };

    const onReset = () => {
        form.resetFields();
        handleClose();
    };


    useEffect(() => {
        if (editData?.CategoryImage) {
            const extractedFileName = editData?.CategoryImage.split('/').pop();
            setFileName(extractedFileName || 'subCat.jpg');
        }
    }, [editData]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (validFileTypes.includes(selectedFile.type)) {
                setImage(selectedFile);
                setFileName(selectedFile.name);

            } else {
                message.error("Invalid file type. Please select a .jpg, .jpeg, or .png file.")
                e.target.value = ''; // Clear the file input
                setImage(null);
                setFileName("");
            }
        }
    };

    const inputRef = useRef<any>();

    return (
        <>

            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={handleClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>{editData ? 'Edit Sub Category' : 'Add Sub Category'}</h4>
                </div>
                <div className='modal_body'>
                    <Form<AddSubCategoryFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onReset={onReset}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""} >

                            <Form.Item<AddSubCategoryFormField>
                                label={"Category Name"}
                                name={AddSubCategoryFormField.CategoryGroup}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Select
                                    loading={statusLoad}
                                    showSearch
                                    options={statusData?.map((item: { Text: string; Value: number }) => ({
                                        label: item.Text,
                                        value: item.Value,
                                    }))}
                                    filterOption={(input, option) =>
                                        typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    placeholder={"Select Category Name"}
                                />


                            </Form.Item >

                            <Form.Item<AddSubCategoryFormField>
                                label={"Sub Category Name"}
                                name={AddSubCategoryFormField.CategoryName_En}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Category Name"}
                                />
                            </Form.Item>

                            <Form.Item<AddSubCategoryFormField>
                                label={"Category Name(Arabic)"}
                                name={AddSubCategoryFormField.CategoryName_Ar}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Category Name(Arabic)"}
                                />
                            </Form.Item>


                            <Form.Item<AddSubCategoryFormField>
                                label={"Category Image"}
                                name={AddSubCategoryFormField.GroupLogo}
                            >
                                <Input
                                    prefix={<Button type="primary" size="small" onClick={() => inputRef.current.click()}>Choose File</Button>}
                                    value={fileName}
                                    readOnly
                                />
                                <input
                                    type='file'
                                    // id='fileInput'
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    accept=".jpg,.png,.jpeg"
                                    onChange={handleFileChange}
                                />
                                <p>
                                    <p>Recommended size</p>
                                    <p>Width : 1050 px</p>
                                    <p>Height : 1050 px</p>
                                </p>
                            </Form.Item>
                            <>
                                <div className='edit_img_show'>
                                    {image ? (
                                        <Image
                                            src={URL.createObjectURL(image)}
                                            width={"120px"}
                                            height={"90px"}
                                            alt="Preview"
                                        />
                                    ) : (

                                        editData?.CategoryImage && (
                                            <Image
                                                src={editData.CategoryImage}
                                                width={"120px"}
                                                height={"90px"}
                                                alt="Category Image"
                                            // preview={false}
                                            />
                                        )
                                    )}
                                </div>
                            </>


                        </FormSectionsSeparator >

                        <div className='form-button'>
                            <Button type="primary" htmlType='submit' className="btn" >Save</Button>
                            <Button onClick={onReset} danger type="primary"> Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>

        </>
    );
};

