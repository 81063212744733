import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { PromotionTypeFilterFormField, PromotionTypeFilterFormPaginated, PromotionTypeFilterFormValues } from '../components/filter-form/promotion-type-filter/types';
import { PromotionTypeListTable } from '../components/promotion-type-list-table/promotion-type-list-table';
import { PromotionTypeListTopBar } from '../components/tob-bar/promotion-type-list-topbar';
import { useGetPromotionTypeLists } from '../use-cases';

const getInitialFilterValues: PromotionTypeFilterFormValues = {
  [PromotionTypeFilterFormField.Type]: undefined,

};


export const PromotionTypeListView = () => {

  const { params, updateParams, resetPage } =
    usePaginationParams<PromotionTypeFilterFormPaginated>({
      CurrentPage: 1,
      RecordsPerPage: 10,
      // order_by: 'name',
      ...getInitialFilterValues,
    });

  const { data, isLoading, refetch, isFetching } = useGetPromotionTypeLists((params), {
    retry: false,
  });


  const handleFiltersChange = (values: PromotionTypeFilterFormPaginated) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (
    <>
      <PromotionTypeListTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />

      <PromotionTypeListTable
        isLoading={isLoading}
        itemList={data}
        itemsCount={data?.TotalRecords || 0}
        tableParams={params}
        updateParams={updateParams}
        isFetching={isFetching}

      />
      <Outlet />
    </>
  );
};

