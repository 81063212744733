import dayjs from "dayjs";

export const payFormatDateTime = (dateTime: string | number | Date | dayjs.Dayjs | null | undefined) => {
    return dayjs(dateTime).format('YYYY-MM-DD HH:mm');
};

export const payFormatDate = (date: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if (!date) return ""
    
return dayjs(date).format('YYYY-MM-DD');
};