
export enum EditSizeFormField {
  Flag = "Flag",
  Id = "Id",
  Category = "Category_Id",
  SubCategory = "SubCategory_Id",
  SizeType = "SizeType_Fk_Id",
  Size = "Size",
  IsDefault = "IsDefault",
}


export type EditSizeFormValues = {
  [EditSizeFormField.Flag]: string | undefined;
  [EditSizeFormField.Id]: number | undefined;
  [EditSizeFormField.Category]: number | undefined;
  [EditSizeFormField.SubCategory]: number | undefined;
  [EditSizeFormField.SizeType]: string | undefined;
  [EditSizeFormField.Size]: string | undefined;
  [EditSizeFormField.IsDefault]: number | undefined;
};

