import { PaginatedRequestParams } from 'api';

export enum AddCategoryFormField {
  FullCategoryName = 'full_Category_name',
  PhoneNumber = 'phone_number',
  Role = 'role',
  Company = 'company_id',
  Email = 'email',
}

export type AddCategoryFormValues = {
  [AddCategoryFormField.FullCategoryName]: string | undefined;
  [AddCategoryFormField.PhoneNumber]: string | undefined;
  [AddCategoryFormField.Email]: string | undefined;
  [AddCategoryFormField.Role]: string | undefined;
  [AddCategoryFormField.Company]: string | undefined;
};

