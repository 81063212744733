export enum AddCategoryFormField {
  Flag = 'Flag',
  Id = "Id",
  GroupName_En = 'GroupName_En',
  GroupName_Ar = 'GroupName_Ar',
  GroupLogo = 'File',
}

export type AddCategoryFormValues = {
  [AddCategoryFormField.Flag]: string | undefined;
  [AddCategoryFormField.Id]: string | undefined;
  [AddCategoryFormField.GroupName_En]: string | undefined;
  [AddCategoryFormField.GroupName_En]: string | undefined;
  [AddCategoryFormField.GroupLogo]: any;
};

