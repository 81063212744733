export enum AddISizeTypeFormField {
  Id = "Id",
  Flag = "Flag",
  CatId = "Group_Fk_Id",
  SubCatId = 'Category_Fk_Id',
  Title = 'Title_En',
  Title_Ar = 'Title_Ar',
  Code = 'Code',
}


export type AddISizeTypeFormValues = {
  resetFelids(arg0: string[]): unknown;
  [AddISizeTypeFormField.Id]: number | undefined;
  [AddISizeTypeFormField.Flag]: string | undefined;
  [AddISizeTypeFormField.CatId]: number | undefined;
  [AddISizeTypeFormField.SubCatId]: number | undefined;
  [AddISizeTypeFormField.Title]: string | undefined;
  [AddISizeTypeFormField.Title_Ar]: string | undefined;
  [AddISizeTypeFormField.Code]: string | undefined;
};

