import { Empty, Skeleton, Table, TablePaginationConfig } from 'antd';
// import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { Dispatch, SetStateAction } from 'react';

import { SubscribersFilterFormPaginated } from '../filter-form/suscribers-list-filter/types';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: SubscribersFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
    isFetching: boolean
};

export const SubscribersListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
    isFetching
}: UsersTableProps) => {


    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
            // order_by: Array.isArray(sorter)
            //   ? undefined
            //   : sorter.order === 'ascend'
            //     ? 'name'
            //     : '-name',
        });
    };


    return (
        <div className='order_rtn'>

            <StyledTableWrapper>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Id'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    // scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isFetching ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isFetching}
                    dataSource={isFetching ? [] : itemList?.Items}


                >

                    <Column<any>
                        title={'Email Id'}
                        dataIndex={'EmailId'}
                        sorter={(a, b) => {
                            if (!a.EmailId) return 1; // Sort null or undefined values to the end
                            if (!b.EmailId) return -1;

                            return a.EmailId.localeCompare(b.EmailId);
                        }}
                    />
                    <Column<any>
                        title={'Created Date'}
                        dataIndex={'CreatedDate'}
                        sorter={(a, b) => {
                            const dateA = new Date(a.CreatedDate).getTime(); // Convert string to timestamp
                            const dateB = new Date(b.CreatedDate).getTime();

                            return dateA - dateB; // Sort by timestamp
                        }}
                        render={(text) => {
                            const date = new Date(text); // Convert to date object

                            return date.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            }); // Format as dd, mo, year (e.g., 17 Oct 2024)
                        }}
                    />


                </Table>
            </StyledTableWrapper >
        </div>
    );
};
