import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { AxiosResponse } from 'axios';
import { CONFIG } from 'config';
import { Gender, User, Volunteer } from 'types';

import { AddRoleDTO } from '../components/modals/edit-role/types';

type userMenu = {
  id: string;
  menu: string;
  isPermission: boolean;
}

export interface GetUserListResponse extends User {
  "Menu": userMenu[]
}
export interface UserListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}

export interface VolunteerListParams extends PaginatedRequestParams {
  Name?: string;
  PhoneNo?: string;
  Email?: string;
}

export type VolunteerUserDTO = {
  Name: string;
  EventId: string;
  Email: string;
  PhoneNo: string;
  Password: string;
  IsRefundAllowed: boolean;
  Status: boolean;
};



export type AddUserDTO = {
  MemberId: any;
  Flag: string;
  MemFirstName: string;
  MemLastName: string;
  MemUserID: string;
  MemPassword: string;
  MemEmailId: string;
  MemMobileNo: string;
  MemStatus_FK_Id: string;
};


export type createNewMenuPermissionDTO = {
  Flag: string;
  PrivilagesString: string;
  Role_FK_Id: number;
};

export type ChangePassDTO = {
  MemberId: any;
  Password: string;
};

export type getUserDataByIdDTO = {
  MemberId: string;
};

export type updateUserMenuPermissionDTO = {
  Flag: string;
  PeopleId: number;
  Privileges: string;
  UserId: number;
};



export type CreateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name: string;
  gender?: Gender | null;
  last_name: string;
  password: string;
  phone_number: string;
  role: string;
  is_active: boolean;
  address: string;

};
export type UpdateUserRoleDTO = {
  user_id: string | undefined;
  MenuIds: string[];
}

export type UpdateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name?: string;
  gender?: Gender | null;
  id: string;
  last_name?: string;
  password?: string;
  phone_number?: string;
  is_active?: boolean;
  role?: string;
  address?: string;
};

export type UserResponse = PaginatedResponse<User>;

export type EventList = {
  Id: string;
  Text: string;
  Value: string;
}

export type CraeteTitleCmsrDTO = {
  id?: string,
  title: string,
  is_active: boolean,
}

export type EventListResponse = PaginatedResponse<EventList>;

export type VolunteerResponse = PaginatedResponse<Volunteer>;

interface ApiResponse<T> {
  data: T;
  status: number;
}


export const UserApiService = () => {

  const createUser = async (
    payload: CreateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };




  const getUser = async (lang: string, id: string, callConfig?: Partial<ApiClientConfig>): Promise<ApiResponse<User>> => {
    const response: AxiosResponse<User> = await apiClient<GetUserListResponse>({
      ...callConfig,
      endpoint: `/dashboard/getparentmenu/?LanguageId=${lang}&UserId=${id}`,
    });

    return {
      data: response.data,
      status: response.status,
    };
  };

  const getUsers = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UserResponse>({
      ...callConfig,
      endpoint: `/admin/users`,
      params,
    });

    return response.data;
  };

  const getUserManagement = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/user/getmemberdatalist`,
      params,
    });

    return response.data;
  };

  const updateUser = async (
    payload: UpdateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };


  const uploadUserProfileImage = async (formData: FormData) => {
    const response = await axiosInstance.post(
      `${CONFIG.EFASHION_API_BASE_URL}/v1/users/profile_picture`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const updateStatus = async (payload: any) => {
    const response = await apiClient({
      endpoint: `common/commondelete`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateUserStatus = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/users/updateuserstatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getLogsUsers = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<VolunteerResponse>({
      ...callConfig,
      endpoint: `/admin/userlogs`,
      params,
    });

    return response.data;
  };

  const addUser = async (
    payload: AddUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/account/userregistration`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const changePassword = async (
    payload: ChangePassDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/account/changepassword`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };



  const getUserDataById = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/user/getmemberdatabyid`,
      params,
    });

    return response.data;
  };


  const getAllRole = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/dashboard/getallrole?Flag=GetRolesForDropdowns`,
      params,
    });

    return response.data;
  };



  const getUserRoleById = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/user/getuserrole`,
      params,
    });

    return response.data;
  };

  const getMenutree = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/user/privilegesbyapplicationusers`,
      params,
    });

    return response.data;
  };


  const updateUserRole = async (
    payload: UpdateUserRoleDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/account/changepassword`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateUserMenuPermission = async (
    payload: updateUserMenuPermissionDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/user/setapplicationusersprivileges`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getNewMenuTreePermission = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/user/getmenutree`,
      params,
    });

    return response.data;
  };

  const createRole = async (
    payload: AddRoleDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/user/SaveAndUpdateRole`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };



  const createNewMenuPermission = async (
    payload: createNewMenuPermissionDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/user/setroleprivileges`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getAllRoleManagement = async (
    params: VolunteerListParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/user/getroles?Flag=GetRolesForDropdowns&SearchRole=null`,
      params,
    });

    return response.data;
  };

  const resetRoleMenuPermission = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/user/resetpermission`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const assignRoleToUser = async (
    payload: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/user/assignsingleroletouser`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  return {
    getUserManagement,
    createUser,
    getUser,
    getUsers,
    updateUser,
    uploadUserProfileImage,
    updateStatus,
    updateUserStatus,
    getLogsUsers,
    addUser,
    changePassword,
    getUserDataById,
    getAllRole,
    getUserRoleById,
    getMenutree,
    updateUserRole,
    updateUserMenuPermission,
    getNewMenuTreePermission,
    createRole,
    createNewMenuPermission,
    getAllRoleManagement,
    resetRoleMenuPermission,
    assignRoleToUser
  };
};
