import { PaginatedRequestParams } from "api";

export enum BenefitsFilterFormField {
    BenefitsType = 'benefitsType',
    Name = 'title',
    IsActive = 'statusId',
}

export type BenefitsFilterFormValues = {
    [BenefitsFilterFormField.BenefitsType]: string | undefined;
    [BenefitsFilterFormField.Name]: string | undefined;
    [BenefitsFilterFormField.IsActive]: string | undefined;
};

export type BenefitsFilterFormPaginated = BenefitsFilterFormValues &
    PaginatedRequestParams;