/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const itemsQueryKeys = {
  all: ['reports'] as const,
  lists: () => [...itemsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...itemsQueryKeys.lists(), { filters }] as const,
  details: () => [...itemsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...itemsQueryKeys.details(), id] as const,
};
