import { RightOutlined } from '@ant-design/icons';
import { Empty, Skeleton, Table, TablePaginationConfig } from 'antd';
import { Column, RecordCountBadge } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { OrderFilterFormPaginated } from '../filter-form/order-list-filter/types';

type UsersTableProps = {
    isLoading: boolean;
    itemList: any;
    itemsCount: number;
    tableParams: OrderFilterFormPaginated;
    updateParams: Dispatch<SetStateAction<any>>;
};

export const OrderListTable = ({
    itemList,
    isLoading,
    itemsCount,
    tableParams,
    updateParams,
}: UsersTableProps) => {

    const navigate = useNavigate();

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // filters: Record<string, FilterValue | null>,
        // sorter: SorterResult<Volunteer> | SorterResult<Volunteer>[],
    ) => {
        updateParams({
            ...tableParams,
            CurrentPage: pagination.current,
            RecordsPerPage: pagination.pageSize,
        });
    };


    // const { confirm } = Modal;

    // const showDeleteConfirm = (record: any) => {
    //     //console.log(record,"record");
    //     confirm({
    //         title: 'Are you sure you want to delete this Volunteer?',
    //         icon: <ExclamationCircleOutlined />,
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         onOk() {
    //             handleDelete(record.Id);
    //         },
    //     });
    // };

    // const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdate } =
    //     useUpdateStatus();

    // const handleDelete = async (id: any) => {

    //     try {
    //         // Simulate the loading state
    //         mutateUpdateStatus({
    //             Id: id,
    //             flag: "VOLUNTEER_DELETE",
    //             status: true
    //         });

    //     } catch (error) {
    //         // Handle any errors here
    //         message.error("")
    //     }
    // };






    const navigateDetails = (record: any) => {
        navigate(getRoute(ROUTES.ORDER_DETAILS, record.Id));
    }


    return (
        <div className='order_rtn'>

            <StyledTableWrapper className='tab_cmn'>
                <StyledTableHeader>
                    <RecordCountBadge
                        text={'Total'}
                        count={itemsCount}
                    />
                </StyledTableHeader>
                <Table<any>
                    rowKey='Rownumber'
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.CurrentPage,
                        defaultPageSize: tableParams.RecordsPerPage,
                        total: itemsCount,
                    }}
                    scroll={{ x: 1440 }}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty />
                    }}
                    loading={isLoading}
                    dataSource={isLoading ? [] : itemList?.getOrderLists}
                // className='cstm_table'

                >
                    <Column<any>
                        title={'Customer Name'}
                        className='pointer'
                        sorter={(a, b) => {
                            if (!a.Name) return 1; // Sort null or undefined values to the end
                            if (!b.Name) return -1;

                            return a.Name.localeCompare(b.Name);
                        }}
                        render={(record: any) => {
                            return (
                                <span className='arow_tab' onClick={() => navigateDetails(record)}>
                                    <RightOutlined />
                                    <h1>{record.Name}</h1>
                                </span>

                            )
                        }}
                    />
                    <Column<any>
                        title={'TansactionId'}
                        dataIndex={'TransactionId'}
                        sorter={(a, b) => a.TotalAllSellingPrice && a.TotalAllSellingPrice - b.TotalAllSellingPrice}
                    />

                    <Column<any>
                        title={'Order Date'}
                        // dataIndex={'OrderDate'}
                        render={(_, record: any) => moment(record?.OrderDate).format("YYYY-MM-DD")}
                        sorter={(a, b) => moment(a.OrderDate).unix() - moment(b.OrderDate).unix()}
                    />

                    <Column<any>
                        title={'Mobile No'}
                        dataIndex={'MobileNo'}
                        sorter={(a, b) => {
                            if (!a.MobileNo) return 1; // Sort null or undefined values to the end
                            if (!b.MobileNo) return -1;

                            return a.MobileNo - b.MobileNo;
                        }}
                    />



                    <Column<any>
                        title={'Selling Price'}
                        dataIndex={'TotalSalesPrice'}
                        // sorter={(a, b) => a.TotalAllSellingPrice && a.TotalAllSellingPrice - b.TotalAllSellingPrice}
                        sorter={(a, b) => {
                            if (!a.TotalSalesPrice) return 1; // Sort null or undefined values to the end
                            if (!b.TotalSalesPrice) return -1;

                            return a.TotalSalesPrice - b.TotalSalesPrice;
                        }}
                    />

                    <Column<any>
                        title={'Delivery'}
                        dataIndex={'DeliveryCharge'}
                        // sorter={(a, b) => a.DeliveryCharge - b.DeliveryCharge}
                        sorter={(a, b) => {
                            if (!a.DeliveryCharge) return 1; // Sort null or undefined values to the end
                            if (!b.DeliveryCharge) return -1;

                            return a.DeliveryCharge - b.DeliveryCharge;
                        }}
                    />

                    <Column<any>
                        title={'Amount'}
                        dataIndex={'AmountPaid'}
                        sorter={(a, b) => a.AmountPaid && a.AmountPaid - b.AmountPaid}

                    />



                    <Column<any>
                        title={'Invoice Type'}
                        dataIndex={'InvoiceType'}
                        sorter={(a, b) => a.InvoiceType && a.Title_En.localeCompare(b.InvoiceType)}
                    />


                    <Column<any>
                        title={'Payment Type'}
                        dataIndex={'Title_En'}
                        sorter={(a, b) => a.Title_En && a.Title_En.localeCompare(b.Title_En)}
                    />

                    <Column<any>
                        title={'Status'}
                        dataIndex={'StatusText'}
                        sorter={(a, b) => a.Title_En.localeCompare(b.StatusText)}
                    />

                    <Column<any>
                        title={'Payment Status'}
                        // dataIndex={'PaymentStatus'}
                        sorter={(a, b) => a.PaymentStatus && a.Title_En.localeCompare(b.PaymentStatus)}
                        render={(_, record: any) => (
                            <h2 className={`${record?.PaymentStatus === "NOT CAPTURED" ? "grey_clg" : record?.PaymentStatus === "CAPTURED" ? "green_clg" : ""}`} >
                                {record?.PaymentStatus}
                            </h2>
                        )}
                    />

                </Table>
            </StyledTableWrapper >
        </div>
    );
};
