import { Button, Form } from 'antd';
import { ChangePassDTO, } from 'features/users/api/user-api.service';
import { useChangePassword } from 'features/users/use-cases/edit-change-password';
import { useState } from 'react';

import { PasswordFormItems } from './password-form-items';

export const ChangePasswordModal = ({
    onClose,
    onSuccess,
    id,
}: {
    onClose: () => void;
    onSuccess: () => void;
    id: string;
}) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const formName = 'edit-password-form';
    const [form] = Form.useForm<any>();

    const { mutate, isLoading } = useChangePassword();

    const handleClose = () => {
        onClose();
        form.resetFields();
    }


    const handleUpdate = (formData: { password: string }) => {
        const payload: ChangePassDTO = {
            MemberId: id,
            Password: formData?.password
        };
        mutate(payload, { onSuccess });
    };



    return (

        <div className={'popup'}>
            <div className='modal_head'>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>
                <h4>Change Password</h4>
            </div>
            <div className='modal_body'>
                <Form
                    id={formName}
                    name={formName}
                    form={form}
                    layout='vertical'
                    // initialValues={getInitialValues}
                    onFinish={handleUpdate}
                    requiredMark={'optional'}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                    disabled={isLoading}
                >


                    <PasswordFormItems form={form} />
                    <div className='form-button'>
                        <Button type="primary" htmlType='submit' className="btn" >Update</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
