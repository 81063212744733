export enum BrandsFormField {
  BrandNameAr = 'BrandName_Ar',
  BrandNameEn = 'BrandName_En',
  status = "IsActive",
  BrandLogo = 'File'
}
export type BrandsFormValues = {
  [BrandsFormField.BrandNameAr]: string;
  [BrandsFormField.BrandNameEn]: string;
  [BrandsFormField.status]: number;
  [BrandsFormField.BrandLogo]: any;

};