export enum AddContactTypeFormField {
  Flag = 'Flag',
  Id = 'Id',
  QuestionEn = 'QuestionEn',
  QuestionAr = 'QuestionAr',
  AnswerEn = 'AnswerEn',
  AnswerAr = 'AnswerAr',
}

export type AddContactTypeFormValues = {
  [AddContactTypeFormField.Flag]: string;
  [AddContactTypeFormField.Id]: number;
  [AddContactTypeFormField.QuestionAr]: string;
  [AddContactTypeFormField.QuestionEn]: string;
  [AddContactTypeFormField.AnswerAr]: string;
  [AddContactTypeFormField.AnswerEn]: string;
};

