/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const DeliveryQueryKeys = {
  all: ['user'] as const,
  lists: () => [...DeliveryQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...DeliveryQueryKeys.lists(), { filters }] as const,
  details: () => [...DeliveryQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...DeliveryQueryKeys.details(), id] as const,
};
