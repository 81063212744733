import { PaginatedRequestParams } from "api";

export enum ItemsSizeFilterFormField {
    SizeType = 'SizeTypeId',
    Size = 'Size',
    Status = 'StatusId',
}

export type ItemsSizeFilterFormValues = {
    [ItemsSizeFilterFormField.SizeType]: number | undefined;
    [ItemsSizeFilterFormField.Size]: number | undefined;
    [ItemsSizeFilterFormField.Status]: number | undefined;

};

export type ItemsSizeFilterFormPaginated = ItemsSizeFilterFormValues &
    PaginatedRequestParams;