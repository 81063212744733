import { Outlet, useParams } from 'react-router-dom'

import OrderReturnListDetailsPage from '../components/order-return-details/order-return-details';

export const OrderReturnDetailsView = () => {

    const { id } = useParams();
    if (!id) return null;

    return (
        <div>
            <OrderReturnListDetailsPage id={id} />
            <Outlet />
        </div>
    )
}
