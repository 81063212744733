import {
    UserOutlined,
} from '@ant-design/icons';
import { Checkbox, Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';

import { AddRoleFormField } from './types';




export const RoleInfoFormItems = () => {


    const { t } = useTranslation();


    return (
        <FormSectionsSeparator title={""} >
            <Form.Item<AddRoleFormField>
                label={"Assigned Role"}
                name={AddRoleFormField.RoleName}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Assigned Role"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            <Form.Item<AddRoleFormField>
                // label={"CategoryId"}
                name={AddRoleFormField.IsActive}
                valuePropName="checked"

            >
                <Checkbox>Is Active</Checkbox>
            </Form.Item>


        </FormSectionsSeparator >
    );
};
