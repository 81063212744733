import { Form, Input } from 'antd';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useTranslation } from 'react-i18next';
import { handleKeyDownThree } from 'utils/key-press';

import { EditDeliveryDaysFormField } from './types';



export const EditDeliveryDaysForm = () => {

    const { t } = useTranslation();


    return (
        <FormSectionsSeparator title={""} >


            <Form.Item<EditDeliveryDaysFormField>
                label={"Delivery Days"}
                name={EditDeliveryDaysFormField.DelieveryDays}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    }
                ]}
            >
                <Input
                    placeholder={"Delivery Days"}
                    onKeyDown={handleKeyDownThree}
                />
            </Form.Item>


        </FormSectionsSeparator >
    );
};
